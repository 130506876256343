import React from 'react';
import './tenant_and_room.scss'
import ListPropertyControl from "../../../../../assets/list_property_control/list_property_control";


function TenantAndRoom(props) {
    return (
        <div className={'TenantAndRoom'}>
            <ListPropertyControl {...props} model={'TenantAndRoom'}/>
        </div>
    );
}

export default TenantAndRoom;