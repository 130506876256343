import React, {useState} from 'react';
import './Help.scss'

function Help(props) {
    const [help, setHelp] =  useState(false)
    return (
        <div
            className={`Help fr ${props.menu === 'help' ? 'activated' : ''}`}
            onClick={() => !help && props.setMenu(p =>  p === 'help' ? null : 'help')}
        >

            <div className={'contact'}>
                <div>Notfallkontakt</div>
                <div>&nbsp;</div>
                <div>pmedien GmbH</div>
                <div>mail@pmedien.com</div>
                <div>Telefon: 089 5999 1981</div>
                <div
                    className={'button_hilfe'}
                    onClick={() => setHelp(true)}
                >
                    Hilfe
                </div>
            </div>
            {
                help &&
                    <div className={'InlineHelp'}  onClick={() => console.log(12)}>
                        <iframe src={'https://leitsystem-help.pmedien-content.de/de/'} title={'Hilfe'}/>
                        <div className={'close_help'} onClick={() => setHelp(false)}>
                            <div className={'f_head'}>Hilfe</div>
                        </div>
                    </div>
            }
        </div>
    )
}


export default Help;