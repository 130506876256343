import React, {useContext} from "react";
import './test_sms.scss'
import {AppContext} from "../../../App";

function TestSms() {
    const g_state = useContext(AppContext)
    console.log(g_state)

    function send_sms() {
        const number_field = document.getElementById('f_telefon_0')
        const number = number_field ? number_field.value : null

        if (!number) {
            return
        }

        new Promise(
            (resolve) => {
                g_state.connector.run_action(
                    {
                        action: 'send_sms',
                        class: '',
                        data: JSON.stringify({number: number, test: 'Dies ist eine Test SMS.'})
                    },
                    res => resolve(res)
                )
            }
        ).then(
            res => {
                console.log(res.data)
            }
        )

    }

    return (
        <div className={'TestSms button'} onClick={() => send_sms()}>Test SMS senden</div>
    )
}

export default TestSms;