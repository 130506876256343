/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react';
import './content_pool.scss'
import {AppContext} from "../../../../../../App";
import './content_pool.scss'
import {ReactSortable} from "react-sortablejs";
import {handle_content, load_categories} from "../program_planning/loader_program_planning";
import ContentEditor, {SpecialEditor} from "../../../../../assets/content_editor/content_editor";
import SearchProgramPlanning from "../../../../../assets/search_program_planning/search_program_planning";
import {createPortal} from "react-dom";

function ContentPoolFilterPool(props) {
    return createPortal(
        <div className={'ContentPoolFilterPool'}>
            <div className={`b0 ${props.pool === 0 ? 'selected' : ''}`} onClick={() => props.setPool(0)}>CP</div>
            <div className={`b1 ${props.pool === 1 ? 'selected' : ''}`} onClick={() => props.setPool(1)}>SI</div>
        </div>,
        document.querySelector('.top_left')
    )
}

function ContentPool() {
    const g_state = useContext(AppContext)
    const [items, setItems] = useState([])
    const [count, setCount] = useState(0)
    const [confirmDelete, setConfirmDelete] = useState(null)
    const [addContent, setAddContent] = useState(null)
    const [addSpecialContent, setAddSpecialContent] = useState(null)
    const [categories, setCategories] = useState([])
    const [pool, setPool] = useState(0)

    useEffect(() => load_categories(g_state.connector)
        .then(res => {
            setCategories(res)
        }), [])


    useEffect(() => {
        g_state.connector.run_action(
            {
                action: 'get_contents_for_schedule_pool',
                data: JSON.stringify({extended: true})
            },
            res => {
                setItems(res.data)
            }
        )
    }, [count])

    function handle_content_content(ev) {
        let point = null
        if (ev.originalEvent.changedTouches) {
            if (ev.originalEvent.touches.length === 0) {
                point = {
                    x: ev.originalEvent.changedTouches[0].clientX,
                    y: ev.originalEvent.changedTouches[0].clientY
                }
            }
        } else {
            point = {x: ev.originalEvent.clientX, y: ev.originalEvent.clientY}
        }

        if (point) {
            const content = document
                .elementFromPoint(point.x, point.y)
                .closest('[data-drop_target]')
            if (content) {
                const action = content.dataset.drop_target
                if (action === 'add') {
                    handle_content(g_state.connector, ev.item.dataset.uid, 'duplicate')
                        .then(res => setCount(p => p + 1))
                } else if (action === 'delete') {
                    setConfirmDelete({uid: ev.item.dataset.uid})
                }
            }

        }
    }

    function open_editor(content_id, templateset_id) {
        pool === 0
            ? setAddContent({content_id, templateset_id})
            : setAddSpecialContent(content_id)
    }

    function add_new_content() {
        if (pool === 0) {
            setAddContent(true)
        } else {
            setAddSpecialContent(true)
        }
    }

    return (
        <div className={'ContentPool fr'}>
            {
                confirmDelete &&
                <div className={'confirm_delete fr'}>
                    <div className={'message bo'}>
                        <div className={'title'}>Achtung</div>
                        <div className={'info'}>Soll dieser Content wirklich gelöscht werden?</div>
                        <div className={'info2'}>Achtung: Es wird der Content in der ganzen Planung gelöscht!</div>
                        <div className={'actions fr'}>
                            <div className={'cancel bo'} onClick={() => setConfirmDelete(null)}>Abbrechen</div>
                            <div className={'delete bo'} onClick={() => {
                                handle_content(g_state.connector, confirmDelete.uid, 'remove')
                                    .then(() => setCount(p => p + 1))
                                setConfirmDelete(null)
                            }
                            }>Löschen
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                addContent !== null &&
                <ContentEditor
                    addContent={addContent} g_state={g_state} miniPool={false}
                    refresh_data={() => setCount(p => p + 1)}
                    setAddContent={setAddContent} categories={categories}
                />
            }
            {
                addSpecialContent !== null &&
                <SpecialEditor
                    setAddSpecialContent={setAddSpecialContent} uid={addSpecialContent}
                    refresh_data={() => setCount(p => p + 1)}
                />
            }
            <div className={'left items fr'}>
                <ReactSortable
                    className={'content_pool_items fr'} list={items} setList={el => null}
                    onEnd={ev => handle_content_content(ev)}
                >
                    {
                        items
                            .filter(el => pool === (el.special ? 1 : 0))
                            .map(
                                (el, i) =>
                                    <div
                                        className={`item fc _content_id_${el.id}`}
                                        key={i} data-uid={el.id}
                                        onClick={() => open_editor(el.id, el.template_set_id)}
                                    >
                                        <div
                                            className={`image bo ${el.has_video ? 'has_video' : ''}`}
                                            style={{
                                                backgroundImage: (addContent === null)
                                                    ? `url(${
                                                        el.content_image
                                                            ? `${
                                                                window.settings.server_protocol
                                                            }://${
                                                                window.settings.server_hostname
                                                            }:${
                                                                window.settings.server_port
                                                            }${el.content_image}`
                                                            : el.image})`
                                                    : null,
                                                border: `3px solid ${el.category_color}`,
                                                boxSizing: 'border-box'
                                            }}
                                        />
                                        <div className={'title'} style={{color: el.category_color}}>{el.name}</div>
                                    </div>
                            )
                    }
                </ReactSortable>
            </div>
            <div className={'right fc'}>
                <div
                    className={'add button bo fr'}
                    data-drop_target={'add'} onClick={() => add_new_content()}
                />
                <div
                    className={'delete button bo fr'}
                    data-drop_target={'delete'}
                />
            </div>
            <SearchProgramPlanning g_state={g_state} content_pool={true}/>
            <ContentPoolFilterPool pool={pool} setPool={setPool}/>
        </div>
    );
}

export default ContentPool;
