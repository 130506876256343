/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import './form.scss'
import useSimpleState from "../../../libraries/simple_reducer";
import {MediumInfo} from "../content_editor/medium_info";
import {createPortal} from "react-dom";
import SpecialOpening from "../special_opening/special_opening";
import TestSms from "../test_sms/test_sms";


function Tag(props, handle_input, set_medium_info, save, delete_listcontrol_image) {
    let act_props = {...props}
    const value = props.value ? props.value : ''

    function zoom(el) {
        const zoom_image = el.cloneNode(true)
        zoom_image.className = 'zomm_image'
        const holder = document.createElement('div')
        holder.className = 'zoom_holder'
        holder.addEventListener('click', ev => ev.currentTarget.parentNode.removeChild(ev.currentTarget))
        holder.appendChild(zoom_image)
        document.body.appendChild(holder)
    }

    delete act_props.tag
    delete act_props.value_list

    if (!(props.type === 'checkbox')) {
        delete act_props.checked
    }
    if (props.tag === 'select') {
        delete act_props.type
    }

    let tag = <></>
    if (props.tag === 'textarea') {
        tag = <textarea {...act_props} value={value} onChange={ev => handle_input(ev)}/>
    } else if (props.tag === 'select') {
        if (props.is_hidden === '1' && props.value) {
            tag = <select {...{...act_props, value: props.value}} // props.parent_id
                          onChange={ev => handle_input(ev)}>
                <option/>
                {
                    props.value_list && props.value_list.map(
                        (el, i) => <option key={i} value={el[0]}>{el[1]}</option>
                    )
                }
            </select>
        } else {
            tag = <select
                {...{...act_props, value: act_props.value ? act_props.value : ''}}
                style={{pointerEvents: act_props.disabled ? 'none' : ''}}
                onClick={ev => ev.stopPropagation()}
                onChange={ev => handle_input(ev)}
            >
                <option/>
                {
                    props.value_list && props.value_list.map(
                        (el, i) => <option key={i} value={el[0]}>{el[1]}</option>
                    )
                }
            </select>
        }
    } else {
        if (props.type === 'checkbox') {
            tag = <input {...act_props} value={value} onChange={ev => handle_input(ev)}/>
        } else if (props.name === 'id') {
            tag = <input {...act_props} value={value} onChange={(ev) => handle_input(ev)}/>
        } else {
            if (act_props.type === 'file') {
                tag = <div
                    style={{backgroundImage: `url(${value})`}}
                    className={'form_image'} data-droptarget={props.name}
                >
                    <div
                        className={`manual_select ${save ? 'plus' : 'zoom'}`}
                        onClick={
                            ev => save ? set_medium_info(props.name) : zoom(ev.currentTarget.parentElement)
                        }
                    />
                    {
                        (save && value) &&
                        <div
                            className={'manual_delete'}
                            onClick={ev => delete_listcontrol_image(ev)}
                        />
                    }
                </div>
            } else {
                tag = <input
                    {...act_props} value={value} onChange={ev => handle_input(ev)}
                    {...(act_props.type === 'time' ? {step: 60} : {})}
                />
            }
        }
    }
    return tag
}

function Form(props) {
    const types = {
        CharField: {tag: 'input', type: 'text'},
        GenericIPAddressField: {tag: 'input', type: 'text'},
        TextField: {tag: 'textarea', type: 'text'},
        FileField: {tag: 'input', type: 'file'},
        ForeignKey: {tag: 'select', type: ''},
        BooleanField: {tag: 'input', type: 'checkbox'},
        FloatField: {tag: 'input', type: 'number'},
        IntegerField: {tag: 'input', type: 'number'},
        PositiveSmallIntegerField: {tag: 'input', type: 'number', style: {display: 'none'}},
        DateField: {tag: 'input', type: 'date'},
        TimeField: {tag: 'input', type: 'time'},
        EmailField: {tag: 'input', type: 'email'},
        AutoField: {tag: 'input', type: 'hidden', style: {display: 'none'}},
    }
    const state = useSimpleState({})
    const [mediumInfo, setMediumInfo] = useState(false)
    const [showPlugin, setShowPlugin] = useState(null)

    const [, level, id_parent] = props.rootline.slice(-1)[0]
        ? props.rootline.slice(-1)[0].split('_').map(el => parseInt(el, 10))
        : [null, null, null]

    state.formdata = true

    useEffect(
        () => {
            if (props.form && props.content) {
                props.form.fields.map(
                    el => {
                        if (el.name) {
                            if (props.content[el.name]) {
                                state(
                                    {
                                        key: el.name,
                                        value: {
                                            v: props.content[el.name].v,
                                            vals: props.content[el.name].vals
                                        }
                                    }
                                )
                            }
                        }
                        return null
                    }
                )
                setFormData()
            }
            return () => {
                setFormData({})
                state.empty()
            }
        },
        [props.form, props.content])

    useEffect(() => setFormData(),
        [state.value])

    function handle_input(ev) {
        const cn = ev.currentTarget.name
        switch (ev.currentTarget.type) {
            case 'checkbox':
                state({key: cn, value: {v: ev.currentTarget.checked, vals: undefined}})
                break
            case 'select-one':
                state({key: cn, value: {v: ev.currentTarget.value, vals: state.value[cn].vals}})
                break
            default:
                state({key: cn, value: {v: ev.currentTarget.value}})
        }
    }

    function handle_file(key, name, data) {
        state({key: key, value: {v: data, name: name}})
    }

    function setFormData(data = null) {
        data === null
            ? props.setFormData({...state.value})
            : props.setFormData(data)
    }

    function set_medium_info(info) {
        setMediumInfo(info)
    }

    function handle_file_upload(ev, typ = null) {
        let my_file = null
        if (typ === 'upload') {
            my_file = ev.target.files[0]
        } else if (typ === 'drag') {
            my_file = ev.dataTransfer.files[0]
        } else {
            my_file = null
        }

        const reader = new FileReader()
        reader.name = my_file?.name

        reader.target = typ === null
            ? document.elementFromPoint(ev.clientX, ev.clientY)
            : document.querySelector(`[data-droptarget="${mediumInfo}"]`)

        if (!(my_file === null)) {
            reader.readAsDataURL(my_file)
            reader.onloadend = ev => {
                const result = ev.currentTarget.result
                const name = ev.currentTarget.name

                const image_field = ev.currentTarget.target.closest('.form_image[data-droptarget]')

                if (image_field) {
                    handle_file(image_field.dataset.droptarget, name, result)
                    image_field.style.backgroundImage = `url(${result})`
                }
            }
        } else {
            const target = (document.elementFromPoint(ev.clientX, ev.clientY))
            const image_field = target.closest('.form_image[data-droptarget]')
            if (image_field) {
                handle_file(image_field.dataset.droptarget, '', 'DELETE')
                image_field.style.backgroundImage = ``
            }
        }
    }

    function delete_listcontrol_image(ev) {
        handle_file_upload(ev, null)
    }

    // TODO handle plugins, remove previous pane (fk)
    return (
        (props.content && props.form)
            ? <div
                className={`Form ${props.save ? 'editable' : 'locked'}`}
                onClick={(ev) => ev.currentTarget.classList.add('winni')}
                onDragOver={ev => ev.preventDefault()}
                onDragEnd={ev => ev.preventDefault()}
                onDrop={
                    ev => {
                        ev.preventDefault()
                        props.save && handle_file_upload(ev)
                    }
                }
            >
                {
                    mediumInfo &&
                    <MediumInfo
                        setMediumInfo={setMediumInfo} mediumInfo={mediumInfo}
                        insert_medium={handle_file_upload} info={{name: 'asdf'}}
                    />

                }
                {
                    Object.keys(props.errors).length > 0 &&
                    <div className={'error_message title'}>
                        Bitte alle Felder ausfüllen!
                        {
                            console.log('Errors:', props.errors)
                        }
                    </div>
                }
                {
                    props.form.fields.map(
                        (el, i) => {
                            const is_hidden = props.addMode !== null
                                ? props.panes[level] ? props.panes[level].parent_fk_link === `${el.name}_id` : false
                                : props.panes[level - 1] ? props.panes[level - 1].parent_fk_link === `${el.name}_id` : false
                            if (el.group) {
                                return <div key={i} className={'group form-row'}>{el.group}</div>
                            } else {
                                return (
                                    <div
                                        key={i}
                                        className={
                                            `${
                                                el.class
                                                    ? [...el.class, el.name, 'form-row'].join(' ')
                                                    : `${el.name} form-row`
                                            } ${is_hidden ? 'hide_row' : ''} ${
                                                props.save ? 'editable' : 'locked'
                                            }`
                                        }
                                        data-blank={el.blank}
                                        style={{
                                            ...types[el.type] && types[el.type].style,
                                        }}
                                    >
                                        <div className={'entry'}
                                             datatype={types[el.type] && types[el.type].type}>
                                            <label
                                                className={el.class ? el.class.join(' ') : ''}
                                                htmlFor={`f_${el.name}`}
                                            >
                                                {el.label ? el.label : ''}
                                            </label>

                                            {
                                                el.name && Tag(
                                                    {
                                                        id: `f_${el.name}`,
                                                        name: el.name,
                                                        type: types[el.type] && types[el.type].type,
                                                        tag: types[el.type] && types[el.type].tag,
                                                        value: (state.value[el.name] && state.value[el.name].v) ||
                                                            (el.default !== null ? `${el.default}` : null),
                                                        value_list: state.value[el.name] && state.value[el.name].vals,
                                                        checked: (state.value[el.name] && state.value[el.name].v) ||
                                                            (el.default !== null ? el.default : null),
                                                        step: (el.type && el.type.toLowerCase().indexOf('float')) >= 0 ? .00001 : null,
                                                        style: types[el.type] && types[el.type].style,
                                                        disabled: !props.save,
                                                        'data-error': props.errors[el.name] !== undefined,
                                                        parent_level: level,
                                                        parent_id: id_parent,
                                                        is_hidden: is_hidden ? '1' : '0',
                                                    },
                                                    handle_input, set_medium_info, props.save, delete_listcontrol_image
                                                )
                                            }
                                        </div>
                                        <div className={'after_entry'}/>
                                    </div>
                                )
                            }
                        }
                    )
                }

                {/*Special insertions*/
                }
                {
                    (
                        props.form.table === 'administration.models.BuildingPart' &&
                        document.querySelector('.start_pre_minutes .after_entry')
                    ) &&
                    createPortal(
                        <div
                            className={'bo plugin special_openings'}
                            style={{
                                opacity: props.addMode !== null ? .5 : '',
                                pointerEvents: props.addMode !== null ? 'none' : ''
                            }}
                        >
                            <div>Abweichende Öffnungszeiten</div>
                            <div className={'button'}
                                 style={{opacity: props.save === false ? .5 : ''}}
                                 onClick={() => setShowPlugin('special_opening')}>Öffnungszeiten
                            </div>
                        </div>,
                        document.querySelector('.time_to_6 .after_entry')
                    )
                }
                {
                    (
                        props.form.table === 'administration.models.User' &&
                        document.querySelector('.notify .after_entry')
                    ) &&
                    createPortal(
                        <div className={'bo plugin test-sms'}>
                            <TestSms/>
                        </div>,
                        document.querySelector('.notify .after_entry')
                    )
                }
                {
                    showPlugin === 'special_opening' &&
                    <SpecialOpening
                        date={new Date()} close={() => setShowPlugin(null)} partId={props.content.id.v}
                    />
                }
            </div>
            :
            null
    )
}

Form.defaultProps =
    {
        save: true
    }

export default Form;