import React, {useEffect, useState, useRef} from 'react';
import './App.scss';
import './portrait.scss';

import './css/scrollbars/scrollbars.scss';
import './css/icons/icon_links.css';

import {context} from './context'
import Dashboard from "./components/intro/login/dashboard/dashboard";
import Connector from "./libraries/connector";

context.settings = {...context.settings, ...window.settings}
console.log(context)
export const AppContext = React.createContext();

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

function App() {
    const [errors, setErrors] = useState([])
    const [errorLevel, setErrorLevel] = useState(0)
    const [lastUpdate, setLastUpdate] = useState('')

    useEffect(() => {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.go(1);
        };
        get_last_update()

    }, [])

    useEffect(() => {
        if (errors.length === 0) {
            setErrorLevel(0)
        } else {
            setErrorLevel(
                errors.filter(
                    el => el.level === '3'
                ).length > 0 ? 1 : 0
            )
        }
    }, [errors])

    useInterval(check_errors, 1000 * 5)
    useInterval(get_last_update, 1000 * 60)

    const resolve_action = (json) => {
        console.log('Server Response --------------------')
        const initial_call = json.initial_call
        delete json.initial_call
        console.log('Initial call', initial_call)
        console.log('Response from server:', json)
        console.log('------------------------------------')

        if (state.settings.login !== json.login_ok) {
            state.settings.login = json.login_ok
            setState({...state})
        }
    }

    function insert_special_break(ev) {
        if (ev.target.closest('[contenteditable]')) {
            if (ev.keyCode === 13) {
                // FIXME handle linebreaks at the end
                document.execCommand('insertHTML', false, '\n');
                ev.preventDefault()
                return false;
            }
        }
    }

    function get_last_update() {
        const req = new XMLHttpRequest()

        req.addEventListener(
            'load',
            ev => {
                const res = ev.currentTarget.response
                if (res) {
                    setLastUpdate(res)
                }
            }
        )
        req.open(
            'GET',
            `${context.settings.server_protocol}://${context.settings.server_hostname}:${context.settings.server_port}/getLastUpdate/`
        )
        req.send()
    }

    function check_errors() {
        const req = new XMLHttpRequest()

        req.addEventListener(
            'load',
            ev => {
                const res = ev.currentTarget.response
                if (res) {
                    setErrors(JSON.parse(res))
                }
            }
        )
        req.open(
            'GET',
            `${context.settings.server_protocol}://${context.settings.server_hostname}:${context.settings.server_port}/protocols/`
        )
        req.send()
    }

    useEffect(
        () => {
            document.body.addEventListener('keydown', insert_special_break)
            return () => {
                document.body.removeEventListener('keydown', insert_special_break)
            }
        },
        []
    )

    const connector = new Connector(
        `${context.settings.server_protocol}://${context.settings.server_hostname}:${context.settings.server_port}`,
        resolve_action
    )

    const [state, setState] = useState(
        {
            ...context,
            connector,
            origin: `${context.settings.server_protocol}://${context.settings.server_hostname}:${context.settings.server_port}`
        }
    )

    return (
        <AppContext.Provider value={state}>
            <div className={`App ${context.settings.login ? 'is_logged_in' : 'is_logged_out'}`}>
                <Dashboard lastUpdate={lastUpdate} errors={errors} errorLevel={errorLevel}/>
            </div>
            <div className={'PrintContent'}/>
        </AppContext.Provider>
    );
}

export default App;
