import {useReducer} from "react";

function useSimpleState(object={}) {
    const [state, dispatchState] = useReducer(
        simple_reduce, object
    )
    dispatchState.getValue = () => state
    dispatchState.setValue = object => dispatchState(object)
    dispatchState.empty = () => dispatchState('empty')
    dispatchState.value = state

    return dispatchState
}

function simple_reduce(state, action=null) {
    if (action) {
        if (action === 'empty') {
            return {}
        } else {
            return {...state, [action.key]:action.value}
        }
    } else {
        return state
    }
}

export default useSimpleState