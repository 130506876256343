import React from 'react';
import './restaurants.scss'
import ListPropertyControl from "../../../../../assets/list_property_control/list_property_control";


function Restaurants(props) {
    return (
        <div className={'Restaurants'}>
            <ListPropertyControl {...props} model={'Restaurants'}/>
        </div>
    );
}

export default Restaurants;