import './list_property_control.scss'
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import ListControl from "../list_control/list_control";
import useSimpleState from "../../../libraries/simple_reducer";
import PropertyControl from "../property_control/property_control";
import {editItemFromListcontrol} from "./loader_list_property_control";


function ConfirmDeleteEntry(props) {
    return (
        <div className={'ConfirmDeleteEntry fr'}>
            <div className={'container'}>
                <div className={'title'}>Eintrag {
                    props.itemName && props.itemName.name
                } löschen?
                </div>
                <div className={'buttons fr'}>
                    <div className={'delete bo simple_button'} onClick={() => props.deleteItem()}>löschen</div>
                    <div className={'cancel bo simple_button'} onClick={() => props.setConfirmDelete(null)}>abbrechen
                    </div>
                </div>
            </div>
        </div>
    )
}


function ListPropertyControl(props) {
    const g_state = useContext(AppContext)
    const [addMode, setAddMode] = useState(null)
    const [conirmDelete, setConfirmDelete] = useState(null)
    /**
     * NOTE
     * should get initial data
     * run all db actions on data (crud) at this level
     * structure is stored in panes
     * panes can have different sets of displayed data (filter like group. building parts etc.)
     */

    const state = useSimpleState(
        {
            item: null,
            levelRootline: null, // ??
            rootline: [], // ??
            formLevel: null,
            selected_filter: null,
            panes: [],
            filters: [],
            model: props.model,
            tree: {},
        }
    )

    useEffect(() => initialize(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    function deleteItem() {
        let [, level, uid] = state.value.rootline.slice(-1)[0].split('_')
        level = parseInt(level)
        const my_pane = state.value.panes[level]

        editItemFromListcontrol(
            g_state.connector,
            JSON.stringify({
                table: my_pane.table,
                parent: my_pane.parent_fk_link,
                uid: uid,
                action: 'delete'
            })
        ).then(
            res => {
                const old_tree = JSON.parse(JSON.stringify(state.value.tree))
                delete old_tree[state.value.rootline.slice(-1)[0]]
                Object.keys(old_tree).forEach(
                    el => {
                        let [, act_level,] = el.split('_')
                        act_level = parseInt(act_level, 10)
                        if (act_level > level) {
                            delete old_tree[el]
                        }
                    }
                )
                state.setValue({key: 'tree', value: old_tree})
                state.setValue({key: 'item', value: undefined})
                state.setValue({key: 'rootline', value: state.value.rootline.slice(0, -1)})
            }
        )
        setConfirmDelete(null)
    }

    function duplicateItem(item_id) {
        let [, level, uid] = item_id.split('_')
        level = parseInt(level)
        const my_pane = state.value.panes[level]

        editItemFromListcontrol(
            g_state.connector,
            JSON.stringify({
                table: my_pane.table,
                parent: my_pane.parent_fk_link,
                uid: uid,
                action: 'duplicate'
            })
        ).then(
            res => {
                if (res.data.uid) {
                    const duplicate_key = `lv_${level}_${res.data.uid}`
                    const new_entry = JSON.parse(JSON.stringify(state.value.tree))
                    new_entry[duplicate_key] = {
                        ...new_entry[item_id],
                        name: `${new_entry[item_id].name} (Kopie)`,
                        db_id: res.data.uid,
                        id: duplicate_key,
                    }

                    state.setValue({key: 'tree', value: new_entry})
                }
            }
        )
    }

    function saveItem(item_id, values) {
        const found_errors = {}
        const level = addMode !== null ? addMode : state.value.rootline.length - 1
        const my_pane = state.value.panes[level]

        my_pane.fields.forEach(
            el => {
                if (!el.blank && el.hasOwnProperty('blank')) {
                    if (document.querySelector(`.Form .entry [name="${el.name}"][is_hidden="0"]`)) {
                        if (`${values[el.name].v}`.trim() === '' || values[el.name].v === null) {
                            found_errors[el.name] = true
                        }
                    } else {
                        values[el.name].v = document.querySelector(`.Form .entry [name="${el.name}"][is_hidden="1"]`).value
                    }
                }
            }
        )

        const values_to_send = {uid: null, fields: {}}

        if (Object.keys(found_errors).length === 0) {
            Object.keys(values).forEach(
                el => {
                    if (el !== 'id') {
                        values_to_send.fields[el] = values[el].v
                    } else {
                        values_to_send.uid = values[el].v
                    }
                }
            )
            editItemFromListcontrol(
                g_state.connector,
                JSON.stringify({
                    table: my_pane.table,
                    data: values_to_send,
                    action: 'update'
                })
            ).then(
                res => {
                    const new_item = `lv_${level}_${res.data.id}`
                    if (values_to_send.uid === null) {
                        build_lists(new_item)
                    } else {
                        const old_tree = JSON.parse(JSON.stringify(state.value.tree))
                        old_tree[new_item].name = res.data.name
                        state.setValue({key: 'tree', value: old_tree})
                    }
                }
            )
        }

        return found_errors
    }

    function handle_item(item_id = null, action, values = {}) {
        switch (action) {
            case "add":
                // console.log(`add item at pane #${item_id}`)
                // console.log(state.value.rootline)
                const old_tree = JSON.parse(JSON.stringify(state.value.tree))

                Object.keys(old_tree).forEach(
                    el => {
                        let [, act_level,] = el.split('_')
                        act_level = parseInt(act_level, 10)
                        if (act_level > item_id) {
                            delete old_tree[el]
                        }
                    }
                )

                state.setValue({key: 'rootline', value: state.value.rootline.slice(0, item_id)})
                state.setValue({key: 'tree', value: old_tree})
                state.setValue({key: 'item', value: null})

                setAddMode(item_id)
                break
            case "move":
                move_listitem(item_id, values.new_node)
                break
            case "update":
                // console.log(`update or create item #${item_id}`)
                return saveItem(item_id, values)
            case "remove":
                setConfirmDelete(item_id)
                break
            case "duplicate":
                duplicateItem(item_id)
                break
            case "select":
                // console.log(`set item #${item_id} as current`)
                build_lists(item_id)
                setAddMode(null)
                break
            default:
                break
        }
    }

    function move_listitem(src_uid, target) {
        let [, level, uid] = src_uid.split('_')
        level = parseInt(level)
        const my_pane = state.value.panes[level]
        let [, target_level, target_uid] = target.split('_')
        level = parseInt(target_level)
        const target_pane = state.value.panes[target_level]

        editItemFromListcontrol(
            g_state.connector,
            JSON.stringify({
                action: 'move',
                table: my_pane.table,
                target_table: target_pane.table,
                target_uid, uid, target
            })
        )
            .then(
                () => {
                    build_lists(target)
                    setAddMode(null)
                }
            )
    }

    function return_content(table, id_in_table, callback) {
        g_state.connector.run_action(
            {action: 'readform', class: table, crud: true, data: `{"id": ${id_in_table}}`},
            result =>
                (callback ? callback(result) : console.warn('no callback to handle form data!'))
        )
    }

    function handle_filter(value) {
        initialize(value)
    }

    function build_lists(item = null) {
        let tree = state.value.tree
        let rootline = state.value.rootline

        // Cut levels that are not needed anymore
        if (item !== null) {
            Object.keys(tree).filter(
                el => {
                    if (tree[el] && tree[item] && (tree[el].level > tree[item].level)) {
                        delete tree[el]
                    }
                    return null
                }
            )
        }

        // calculate all necessary values
        const parent = tree[item]
        const level = parent ? parent.level + 1 : 0

        // evaluate the rootline
        rootline = rootline.slice(0, level - 1)
        item !== null && rootline.push(item)
        state({key: 'rootline', value: rootline})
        state({key: 'item', value: rootline.slice(-1)[0]})

        // dont evaluate on levels beyond the pane length -> break
        if (level >= state.value.panes.length) {
            return
        }
        const table = state.value.panes[level].table

        const fk_link = parent ? state.value.panes[parent.level].parent_fk_link : ''
        const data = JSON.stringify(parent ? {[fk_link]: parent.db_id} : {})

        console.log(
            {action: 'readall', class: table, crud: true, data: data}
        )
        g_state.connector.run_action(
            {action: 'readall', class: table, crud: true, data: data},
            result => {
                result.data.map(
                    el => {
                        const uid = `lv_${level}_${el.id}`
                        tree[uid] = {
                            name: el.name,
                            level: level,
                            id: uid,
                            db_id: el.id,
                        }
                        return null
                    }
                )
                state({key: 'tree', value: tree})
            }
        )
    }

    function initialize(filter = null) {
        state.setValue({key: 'tree', value: {}})

        g_state.connector.run_action(
            {action: 'get_form_fields', model: state.value.model},
            result => {
                const selected_filter = filter || result.data.filters.filter(el => el[1])[0][0]

                state.setValue({key: 'selected_filter', value: selected_filter})
                state.setValue({key: 'filters', value: result.data.filters})
                state.setValue({
                    key: 'panes',
                    value: result.data.panes.filter(el => el.set === selected_filter)
                })


                if (window.col_1_pre) {
                    build_lists()
                    setTimeout(handle_item, 100, `lv_0_${window.col_1_pre}`, 'select')
                    window.col_1_pre = undefined
                } else {
                    build_lists()
                }
            }
        )
    }

    return (
        <div className={'ListPropertyControl'}>
            {
                conirmDelete !== null &&
                <ConfirmDeleteEntry
                    setConfirmDelete={setConfirmDelete} deleteItem={deleteItem}
                    itemName={state.value.tree && state.value.tree[conirmDelete]}
                />
            }
            <ListControl
                {...props}

                panes={state.value.panes}
                tree={state.value.tree}
                handle_item={handle_item}
                rootline={state.value.rootline}

                filters={state.value.filters}
                selected_filter={state.value.selected_filter}
                handle_filter={handle_filter}

            />
            <PropertyControl
                {...props}
                g_state={g_state}
                panes={state.value.panes}
                tree={state.value.tree}
                item={state.value.item}
                rootline={state.value.rootline}
                handle_item={handle_item}

                addMode={addMode}
                setAddMode={setAddMode}

                return_content={return_content}
            />
        </div>
    )
}

export default ListPropertyControl;

// NOTE 4 MODULES: Mieter + Räume, Benutzer, Geräte + Gebäude, Resturants