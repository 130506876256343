import React, {useContext, useState, useEffect} from 'react';
import './signal_source.scss'
import {AppContext} from "../../../../../../App";

function SignalSource() {
    const g_state = useContext(AppContext)
    const [stations, setStations] = useState([])

    function toggle_source(el, input_name) {
        g_state.connector.run_action(
            {
                action: 'update',
                class: 'administration.models.MediaStation',
                crud: true,
                key: JSON.stringify({id: el.id}),
                data: JSON.stringify({
                    second_input_active: input_name === 'second',
                    third_input_active: input_name === 'third',
                    fourth_input_active: input_name === 'fourth',
                })
            },
            initialize
        )
    }

    function initialize() {
        g_state.connector.run_action({action: 'get_all_2_signals'}, result => {
            setStations(result.data)
        })
    }

    useEffect(initialize, [g_state.connector])

    return (<div className={'SignalSource'}>
        <div className={'signals'}>
            {stations.map((el, i) => {
                let first_active = !el.second_input_active && !el.third_input_active && !el.fourth_input_active
                return <div className={'signal'} key={i}>
                    <div className={'title'}>{el.name}</div>
                    <div className={'spacer'}/>
                    <div className={'selector'}>
                        <div className={`${first_active ? 'activated' : ''}`}>
                            {el?.[`name_${'first'}_input`]}
                        </div>
                        <div
                            className={`toggle_button ${first_active ? 'active' : ''}`}
                            onClick={() => toggle_source(el, 'first')}
                        >
                            <div className={'knob'}/>
                        </div>
                        {['second', 'third', 'fourth',].filter((input_name, i) =>
                            el[`name_${input_name}_input`]).map(input_name => {
                            return <div style={{display: 'flex', alignItems:'center'}} key={i}>
                                <div className={`${el?.[`${input_name}_input_active`] ? 'activated' : ''}`}>
                                    {el?.[`name_${input_name}_input`]}
                                </div>
                                <div
                                    className={`toggle_button ${el?.[`${input_name}_input_active`] ? 'active' : ''}`}
                                    onClick={() => toggle_source(el, input_name)}
                                >
                                    <div className={'knob'}></div>
                                </div>
                            </div>
                        })}


                        {/*<div className={`${el.second_input_active ? 'activated' : ''}`}>*/}
                        {/*    {el.name_second_input}*/}
                        {/*</div>*/}
                    </div>
                </div>
            })}
        </div>
    </div>);
}

export default SignalSource;