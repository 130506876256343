function load_building_parts(connector, load_special) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'readall',
                    class: 'administration.models.DeviceGroup',
                    crud: true,
                    data: load_special ? '{}' : `{"mediastation__show_in_schedule":true}`,
                    distinct: true
                },
                res => resolve(res.data)
            )
        }
    )
}

function load_categories(connector) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'load_categories',
                    class: '',
                    data: '{}'
                },
                res => resolve(res.data)
            )
        }
    )
}

function load_stations_for_part(connector, part, entries, load_speccial) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'load_stations_for_part',
                    class: '',
                    data: `{"ref_device_group__id":${part} ${!load_speccial ? ', "show_in_schedule": true' : ''}}`
                },
                res => resolve(
                    {
                        ...entries,
                        [part]: res.data.map(
                            el => ({
                                name: el.real_name,
                                id: el.id,
                                resolution: el.resolution,
                                partId: el.part_id,
                                short: el.short
                            })
                        )
                    }
                )
            )
        }
    )
}

function set_station_order_for_parts(connector, new_order) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'set_station_order_for_parts',
                    class: '',
                    data: JSON.stringify(new_order)
                },
                res => resolve(res
                )
            )
        }
    )
}

function load_day_entries_by_ms(connector, entries, setDayEntriesByMs, dayEntries, date) {
    return new Promise(
        (resolve) => {
            const current_mediastations = []
            const day_entry_keys = Object.keys(dayEntries).map(el => parseInt(el, 10))
            Object.keys(entries).map(
                el => {
                    return entries[el].map(
                        x => day_entry_keys.indexOf(x.id) < 0 && current_mediastations.push(x.id)
                    )
                }
            )
            if (current_mediastations.length > 0) {
                const act_day_entries = {...dayEntries}
                current_mediastations.map(
                    el => act_day_entries[el] = {}
                )
                setDayEntriesByMs(prev_vals => ({...prev_vals, ...act_day_entries}))
                // now get the data about the day
                connector.run_action(
                    {
                        action: 'get_day_entries',
                        class: 'content.models.DayEntry',
                        data: `{"ref_media_station__in":[${current_mediastations.join(',')}], "datum__month": ${date.getMonth() + 1}, "datum__year": ${date.getFullYear()}}`
                    },
                    res => {
                        res.data = Object.keys(res.data).map(
                            el => {
                                act_day_entries[el] = res.data[el]
                                return null
                            }
                        )
                        resolve({...act_day_entries})
                    }
                )
            }
        }
    )
}

function set_time_item_exclusive(connector, state, time_item_id) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'update',
                    class: 'content.models.DayElement',
                    crud: true,
                    key: `{"id":${time_item_id}}`,
                    data: `{"is_exclusive":${state}}`
                },
                res => resolve(res)
            )
        }
    )
}

function change_time_item_muted(connector, state, time_item_id) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'update',
                    class: 'content.models.DayElement',
                    crud: true,
                    key: `{"id":${time_item_id}}`,
                    data: `{"sound":${state}}`
                },
                res => resolve(res)
            )
        }
    )
}

function change_time_item_time(connector, value, time_item_id, key) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'update',
                    class: 'content.models.DayElement',
                    crud: true,
                    key: `{"id":${time_item_id}}`,
                    data: `{"time_${key === 'from' ? 'start' : 'end'}":"${value}"}`
                },
                res => resolve(res)
            )
        }
    )
}

function add_content_to_day(connector, insert_date, insert_ms, content_id, show_id) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'add_content_to_day',
                    class: '',
                    data: JSON.stringify(
                        {insert_date: insert_date, ms_id: insert_ms, content_id: content_id, show_id: show_id}
                    )
                },
                res => resolve(res)
            )
        }
    )
}

function handle_content(connector, content_id, action = null) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'handle_content',
                    class: '',
                    data: JSON.stringify({content_id: content_id, action: action})
                },
                res => resolve(res)
            )
        }
    )
}

function update_dayelement_order(connector, data) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'update_dayelement_order',
                    class: '',
                    data: JSON.stringify(data)
                },
                res => resolve(res)
            )
        }
    )
}

function add_content_from_day_to_day(connector, insert_date, insert_ms, content_id, action, show_id) {
    return new Promise(
        (resolve) => {
            const my_action = JSON.stringify({insert_date, insert_ms, content_id, action, show_id})
            connector.run_action(
                {
                    action: 'add_content_from_day_to_day',
                    class: '',
                    data: my_action
                },
                res => resolve(res)
            )
        }
    )
}

function add_to_dayentry(connector, uid) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'add_to_dayentry',
                    class: '',
                    data: JSON.stringify({uid: uid})
                },
                res => resolve(res)
            )
        }
    )
}

function delete_from_dayentry(connector, uid) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'delete_from_dayentry',
                    class: '',
                    data: JSON.stringify({uid: uid})
                },
                res => resolve(res)
            )
        }
    )
}

function get_info_for_mediastation(connector, uid) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'get_info_for_mediastation',
                    class: '',
                    data: JSON.stringify({uid: uid})
                },
                res => resolve(res)
            )
        }
    )
}

function duplicate_day_entry(connector, insert_date, insert_ms, content_id, action, show_id) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'duplicate_day_entry',
                    class: '',
                    data: JSON.stringify({insert_date, insert_ms, content_id, action, show_id})
                },
                res => resolve(res)
            )
        }
    )
}

function handle_tags(connector, tag, add) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'handle_tags',
                    class: '',
                    [tag ? 'data' : '-']: tag ? JSON.stringify({tag, add}) : null
                },
                res => resolve(res)
            )
        }
    )
}

function return_template_content(connector, uid = null) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'return_template_content',
                    class: '',
                    [uid !== null ? 'data' : '-']: uid,
                },
                res => resolve(res)
            )
        }
    )
}

function insert_content(connector, data) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'insert_content',
                    class: '',
                    data: data,
                },
                res => resolve(res)
            )
        }
    )
}

function find_contents(connector, data) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'find_contents',
                    class: '',
                    data: data,
                },
                res => resolve(res)
            )
        }
    )
}

function edit_show_categories(connector, data = {action: 'list'}) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'edit_show_categories',
                    class: '',
                    data: data,
                },
                res => resolve(res)
            )
        }
    )
}

export {
    load_building_parts,
    load_categories,
    load_stations_for_part,
    set_station_order_for_parts,
    load_day_entries_by_ms,
    set_time_item_exclusive,
    change_time_item_muted,
    change_time_item_time,
    add_content_to_day,
    handle_content,
    update_dayelement_order,
    add_content_from_day_to_day,
    add_to_dayentry,
    delete_from_dayentry,
    get_info_for_mediastation,
    duplicate_day_entry,
    handle_tags,
    return_template_content,
    insert_content,
    find_contents,
    edit_show_categories,
}