/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useContext, useState} from 'react';
import './machine_watch.scss'
import {AppContext} from "../../../../../../App";
import {get_info_for_mediastation} from "../../content/program_planning/loader_program_planning";
import {createPortal} from "react-dom";
import {context} from "../../../../../../context";


function InfoPortal(props) {
    const [data, setData] = useState(null)
    useEffect(() => {
        if (props.show) {
            get_info_for_mediastation(
                props.g_state.connector,
                props.uid
            ).then(
                res => setData(res.data)
            )
        }
    }, [props.show])

    if (props.show) {
        return createPortal(
            <div className={'panel_show_mediainfo fr'}>
                <div className={'mediainfo fc'}>
                    <div className={'head fr'}>
                        <div className={'title'}>
                            Informationen {data?.name} {'//'} {data?.part}
                        </div>
                        <div className={'close'} onClick={(ev) => {
                            ev.stopPropagation()
                            props.setShow(false)
                        }}/>
                    </div>
                    <div className={'body fr'}>
                        <div className={'left'}>
                            <div className={'header_infos'}>Standortinfos</div>
                            <div className={'einbau'}>{data?.info}</div>
                            <div className={'header_infos'}>Einbauinfos</div>
                            <div className={'infos'} dangerouslySetInnerHTML={{__html: data?.einbau_info}}/>
                            <div className={'opening'}>
                                <div className={'header_opening'}>Regelöffnungszeiten</div>
                                {
                                    [...Array(7)].map(
                                        (el, i) =>
                                            <div key={i} className={'time fr'}>
                                                <div>{props.g_state.settings.day_names[(i + 8) % 7].long}</div>
                                                <div className={'fr'}>
                                                    {
                                                        (data?.opening[i][0] && data?.opening[i][1])
                                                            ? <>
                                                                <div>{data?.opening[i][0]}</div>
                                                                <div>-</div>
                                                                <div>{data?.opening[i][1]}</div>
                                                            </>
                                                            : ''
                                                    }
                                                </div>
                                            </div>
                                    )
                                }
                            </div>
                            {
                                data?.machines?.length > 0 &&
                                <>
                                    <div>&nbsp;</div>
                                    <div className={'header_machines'}>Geräte</div>
                                    <div className={'machines'}>
                                        {
                                            data.machines.map(
                                                el => `${el.typ_name} (${el.ip})`
                                            ).join(', ')
                                        }
                                    </div>
                                </>
                            }
                            <div className={'header_key'}>&nbsp;<br/>Key Medienstation</div>
                            <div className={'key'}>{data?.key}</div>
                        </div>
                        <div className={'right fr'}>
                            <div className={'lage'}>
                                <div className={'title'}>Lageplan</div>
                                <div
                                    className={'img_lage'}
                                    style={{
                                        backgroundImage: `url(${
                                            context.settings.server_protocol
                                        }://${
                                            context.settings.server_hostname
                                        }:${
                                            context.settings.server_port
                                        }${
                                            data?.plan
                                        })`,
                                    }}
                                />
                            </div>
                            <div className={'station'}>
                                <div className={'title'}>Medienstation</div>
                                <div
                                    className={'img_station'}
                                    style={{
                                        backgroundImage: `url(${
                                            context.settings.server_protocol
                                        }://${
                                            context.settings.server_hostname
                                        }:${
                                            context.settings.server_port
                                        }${
                                            data?.image
                                        })`,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            , document.body
        )
    } else {
        return null
    }
}

function InfoMediastation(props) {
    const [show, setShow] = useState(false)
    return (
        <div className={'InfoMediastation bo'} onClick={() => setShow(true)}>
            <InfoPortal {...props} show={show} setShow={setShow}/>
        </div>)
}

function SettingsStation(props) {
    const g_state = useContext(AppContext)
    const [station, setStation] = useState(null)
    const [protocol, setProtocol] = useState([])
    const [opening, setOpening] = useState('')

    const server_url = `${window.settings.server_protocol}://${window.settings.server_hostname}:${window.settings.server_port}`

    useEffect(
        () => {
            get_media_station()

            if (props.uid !== undefined) {
                const req = new XMLHttpRequest()
                req.addEventListener(
                    'load',
                    ev => {
                        setProtocol(JSON.parse(ev.currentTarget.response))
                    }
                )
                req.open('GET', `${server_url}/protocolsByUid/${props.uid}/`)
                req.send()
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.uid]
    )

    useEffect(
        () => {
            const req = new XMLHttpRequest()
            req.addEventListener(
                'load',
                ev => {
                    setOpening(ev.currentTarget.response)
                }
            )
            req.open('GET', `${server_url}/getDayOpeningMs/${props.uid}/`)
            req.send()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.uid]
    )

    function get_media_station() {
        g_state.connector.run_action(
            {action: 'read', class: 'administration.models.MediaStation', crud: true, data: `{"id": ${props.uid}}`},
            result => {
                setStation(result.result.length > 0 ? result.result[0].fields : null)
            }
        )
    }

    return (
        <div className={'SettingStation'}>
            {
                station &&
                <div className={'props_station'}>
                    <div className={'title headline'}>
                        <div className={'back_media_station'} onClick={props.reset_station}/>
                        <div className={'station_name'}>{station.name}</div>
                        <InfoMediastation {...props} uid={props.uid} g_state={g_state}/>
                        <div className={'power'}>
                            {
                                ((props.power !== null) && !station.hardware_on_off) &&
                                <div
                                    className={`power_button state_${
                                        props.power === null ? 'undefined' :
                                            (props.power ? 'on' : 'off')
                                    }`}
                                    onClick={() => props.set_power(props.uid)}
                                />
                            }
                        </div>
                    </div>
                    {
                        props.lastUpdate !== '' &&
                        <div className={'status'}>
                            <b>Letzes Content-Update:</b><br/><br/>{props.lastUpdate}
                        </div>
                    }
                    {
                        opening !== '' &&
                        <div className={'status'}>
                            <b>Betrieb heute:</b><br/><br/>{opening}<br/>
                            <div
                                className={'change_times'}
                                onClick={
                                    () => {
                                        window.col_1_pre = station.ref_building_part
                                        props.setModuleByName('MachineAndRoom')
                                    }
                                }
                            >
                                Zu diesem Gebäudebereich wechseln um Betriebszeit zu ändern
                            </div>
                        </div>
                    }
                    <div className={'status'}>
                        <b>
                            {
                                protocol.length === 0
                                    ? 'Keine Fehlermeldungen'
                                    : 'Letzte Fehlermeldungen'
                            }
                        </b>
                    </div>
                    <div className={'show_logs'}>
                        {
                            protocol.map(
                                (el, i) =>
                                    <div key={i} className={'log_entry'}>
                                        <div className={'time'}>
                                            {new Date(el.time).toLocaleDateString()} {new Date(el.time).toLocaleTimeString()} - {el.ip}
                                        </div>
                                        <div className={'level'}>
                                            {{'3': 'FEHLER', '2': 'WARNUNG'}[el.level]} - {el.message} ({el.typ})
                                        </div>
                                    </div>
                            )
                        }
                    </div>
                </div>
            }
        </div>
    )
}


function MachineWatch(props) {
    const g_state = useContext(AppContext)

    const url = `${g_state.settings.server_protocol}://${g_state.settings.server_hostname}:${g_state.settings.server_port}`

    const [stations, setStations] = useState(null)
    const [part, setPart] = useState(null)
    const [station, setStation] = useState(null)
    const [activeId, setActiveId] = useState(null)
    const [powerLoaded, setPowerLoaded] = useState(false)
    const [power, setPower] = useState(null)

    const [allpower, setAllPower] = useState(null)

    useEffect(initialize, [g_state.connector])

    useEffect(() => (!powerLoaded && load_power_states()), [stations, powerLoaded])

    function load_power_states() {
        if (stations && stations.stations) {
            const req = new XMLHttpRequest()
            const fd = new FormData()
            fd.append('stations', stations.stations.map(el => el.id))

            req.addEventListener(
                'load',
                ev => {
                    const response = ev.target.responseText
                    if (response) {
                        const tmp_stations = stations.stations
                        const my_json = JSON.parse(response)
                        tmp_stations.map(
                            el => {
                                if (my_json[el.id] !== undefined) {
                                    el.power = my_json[el.id]
                                    const detail = my_json.details[el.id]
                                    if (detail) {
                                        if (detail.state && detail.desired) {
                                            el.desired = true
                                        } else if ((!detail.state && !detail.desired)) {
                                            el.desired = null
                                        } else {
                                            el.desired = false
                                        }

                                    } else {
                                        el.desired = null
                                    }
                                }
                                return null
                            }
                        )
                        setPowerLoaded(true)
                        setStations(
                            p => ({...stations, stations: tmp_stations})
                        )
                    }
                }
            )

            req.open('POST', `${url}/returnPowerStates/`)
            req.send(fd)
        }
    }

    function initialize() {
        g_state.connector.run_action(
            {action: 'get_watch'},
            result => setStations(result.data)
        )
    }

    function set_selection(p, k) {
        setPart(p)
        setActiveId(k)
    }

    function set_power(uid) {
        let found_machine = stations.stations.filter(el => el.id === uid)
        if (found_machine.length) {
            found_machine = found_machine[0]

            console.log('Set power of station UID:', uid, 'set power to:', !found_machine.power)

            g_state.connector.run_action(
                {
                    action: 'update',
                    class: 'administration.models.MediaStation',
                    crud: true,
                    key: `{"id": ${uid}}`,
                    data: `{"powerstate": ${!found_machine.power}}`
                },
                () => {
                    load_power_states()
                }
            )
        }
    }

    function set_all_power(selection) {
        if (selection) {
            g_state.connector.run_action(
                {
                    action: 'allpower',
                    class: 'content.models.DayEntry',
                    data: `{"power":${allpower}}`
                },
                () => null
            )
        }
        setAllPower(null)
    }

    return (
        <div className={`MachineWatch ${station !== null ? 'station_selected' : 'no_station_selected'}`}>
            {
                allpower !== null &&
                <div className={'message_power'}>
                    <div className={'button_holder'}>
                        <div className={'question'}>Wirklich alle
                            Geräte {allpower === 1 ? 'anschalten' : 'ausschalten'}?
                        </div>
                        <div className={'button'} onClick={() => set_all_power(false)}>Abbrechen</div>
                        <div className={'button'} onClick={() => set_all_power(true)}>Ausführen</div>
                    </div>
                </div>
            }
            <div className={'lists'}>
                <div className={'col_area'}>
                    <div className={'title'}>Gebäudebereich</div>
                    <div className={'list_building'}>
                        {
                            stations && stations.building && stations.building.map(
                                (el, i) =>
                                    <div
                                        className={`item_building ${activeId === i ? 'selected' : ''} ${station !== null ? 'no_bg' : ''}`}
                                        key={i}
                                        onClick={() => {
                                            el.stations === part
                                                ? set_selection(null, null)
                                                : set_selection(el.stations, i)
                                            setStation(null)
                                        }}
                                    >
                                        {el.name} (ID:{el.id})
                                    </div>
                            )
                        }
                    </div>
                </div>
                <div className={'col_stations'}>
                    <div className={'title'}>
                        Medienstationen
                        <span className={'title allpower'}>Alles&nbsp;
                            <span
                                className={'title'}
                                onClick={() => setAllPower(1)}
                            >an</span>&nbsp;
                            / <span
                                className={'title'}
                                onClick={() => setAllPower(0)}
                            >aus</span></span>
                    </div>
                    {
                        stations &&
                        <div className={'stations'}>
                            {
                                stations.stations
                                    .map(
                                        (el, i) =>
                                            <div
                                                className={`station ${station === el.id ? 'selected' : ''}`}
                                                key={i} onClick={() => {
                                                setStation(el.id)
                                                setPower(el.power)
                                            }}
                                                style={{
                                                    display: part === null
                                                        ? ''
                                                        : (part.indexOf(el.id) >= 0 ? '' : 'none')
                                                }}
                                            >
                                                <div className={`state ${
                                                    el.desired ? 'active' : (el.desired === null ? 'inactive' : '')
                                                }`}/>
                                                <div className={'name'}>{el.name}</div>
                                            </div>
                                    )
                            }
                        </div>
                    }
                </div>
            </div>
            <div className={'settings'}>
                {
                    station &&
                    <SettingsStation
                        power={power} set_power={set_power}
                        uid={station} reload={initialize} lastUpdate={props.lastUpdate}
                        setModuleByName={props.setModuleByName} reset_station={() => setStation(null)}
                    />
                }
            </div>
            {
                createPortal(
                    <div
                        className={'inner_export'} style={{display: 'block'}}
                        onClick={() => window.open(`${url}/export-protocol/`, '_blank')}
                    >
                    </div>,
                    document.querySelector('.export_holder')
                )
            }
        </div>
    );
}

export default MachineWatch;