/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext, useRef} from 'react';
import {createPortal} from 'react-dom';
import {ReactSortable} from "react-sortablejs";


import './program_planning.scss'
import SearchProgramPlanning from '../../../../../assets/search_program_planning/search_program_planning'
import {AppContext} from "../../../../../../App";
import {
    add_content_from_day_to_day,
    add_content_to_day,
    add_to_dayentry,
    change_time_item_muted,
    change_time_item_time,
    delete_from_dayentry,
    duplicate_day_entry,
    edit_show_categories,
    get_info_for_mediastation,
    handle_content,
    load_building_parts,
    load_categories,
    load_day_entries_by_ms,
    load_stations_for_part, set_station_order_for_parts,
    set_time_item_exclusive,
    update_dayelement_order
} from "./loader_program_planning";
import {context} from "../../../../../../context";
import SpecialOpening from "../../../../../assets/special_opening/special_opening";
import ContentEditor, {SpecialEditor} from "../../../../../assets/content_editor/content_editor";

function SettingsShows(props) {
    const [shows, setShows] = useState([])
    const [confirmDelete, setConfirmDelete] = useState(null)

    const new_text = useRef(null)
    const new_default = useRef(null)

    useEffect(() => {
        edit_show_categories(props.g_state.connector, JSON.stringify({action: 'list'}))
            .then(
                res => setShows(res.data)
            )
    }, [])

    function change_values(ev, num, key) {
        let old_list = shows

        if (key === 'name') {
            old_list[num] = {
                ...old_list[num],
                ...{[key]: ev.currentTarget.value}
            }
        } else {
            old_list.forEach(
                el => {
                    el.default = false
                }
            )
            old_list[num] = {
                ...old_list[num],
                ...{[key]: ev.currentTarget.checked}
            }
        }
        setShows([...old_list])
    }

    function saveShow() {
        let act_shows = shows
        if (new_text.current.value.trim()) {
            new_text.current.value = new_text.current.value.trim()

            if (new_default.current.checked) {
                act_shows.forEach(
                    el => {
                        el.default = false
                    }
                )
            }

            act_shows = [
                ...act_shows,
                {name: new_text.current.value.trim(), default: new_default.current.checked, uid: null}
            ]


            new_text.current.value = ''
            new_default.current.checked = false
        }

        act_shows = act_shows.filter(
            el => el.name.trim() !== ''
        )

        setShows(act_shows)

        edit_show_categories(props.g_state.connector, JSON.stringify({action: 'change', data: act_shows}))
            .then(
                res => {
                    setShows(res.data)
                    props.refresh_data(['schedule'], 'SettingsShow')
                }
            )
    }

    function deleteShow() {
        props.setDayProgram(false)
        props.setSelectedShow(null)

        edit_show_categories(props.g_state.connector, JSON.stringify({action: 'delete', uid: confirmDelete}))
            .then(
                res => {

                    props.refresh_data(['schedule'], 'SettingsShow')
                    setConfirmDelete(null)
                    setShows(shows.filter(el => el.uid !== confirmDelete))
                }
            )
    }

    return (
        createPortal(
            <div className={'SettingsShows fr'}>
                {
                    confirmDelete !== null &&
                    <div className={'confirm_delete_show fc'}>
                        <div className={'title'}>Dieses Szenarium wirklich löschen?</div>
                        <div className={'description'}><b>Achtung!</b><br/> Es
                            werden <b>alle</b> Tagespläne <b>und</b> Tageseinträge dieses Szenariums <b>gelöscht</b>!
                        </div>
                        <div className={'choices fr'}>
                            <div className={'simple_button del bo'} onClick={() => deleteShow()}>löschen</div>
                            <div className={'simple_button abort bo'} onClick={() => setConfirmDelete(null)}>abbrechen
                            </div>
                        </div>
                    </div>
                }
                {
                    confirmDelete === null &&
                    <div className={'settings fc'}>
                        <div className={'head fr'}>
                            <div className={'title'}>Szenarien</div>
                            {/*<div className={'close'} onClick={() => props.setShowShow(false)}>[CL]</div>*/}
                        </div>
                        <div className={'show_list fc'}>
                            <ReactSortable
                                className={'sortable_media_station fc'} list={shows}
                                setList={list => setShows(list)}
                            >
                                {
                                    shows.map(
                                        (el, i) =>
                                            <div className={'element fr'} key={el.uid}>
                                                <input
                                                    onChange={ev => change_values(ev, i, 'name')}
                                                    type={'text'} value={el.name} className={'simple_input'}
                                                />
                                                <div className={'fr'}>
                                                    <input
                                                        type={'checkbox'}
                                                        onChange={ev => change_values(ev, i, 'default')}
                                                        checked={el.default}
                                                    />
                                                    <div className={'default'}>Default Content</div>
                                                </div>
                                                <div className={'delete'} onClick={() => setConfirmDelete(el.uid)}/>
                                            </div>
                                    )
                                }
                            </ReactSortable>
                            <div className={'element fr'}>
                                <input
                                    ref={new_text} type={'text'} data-new={true}
                                    className={'simple_input'}
                                    placeholder={'Neues Szenario eingeben.'}/>
                                <div className={'fr'}>
                                    <input ref={new_default} type={'checkbox'} data-new={true} defaultValue={1}/>
                                    <div className={'default'}>Default Content</div>
                                </div>
                                <div className={'delete'}>&nbsp;</div>
                            </div>
                        </div>
                        <div className={'save fr'}>
                            <div className={'simple_button button_save bo'} onClick={() => saveShow()}>speichern</div>
                            <div className={'simple_button button_save bo'}
                                 onClick={() => props.setShowShow(false)}>abbrechen
                            </div>
                        </div>
                    </div>
                }
            </div>
            , document.body
        )
    )
}

function Calendar(props) {
    const [showShow, setShowShow] = useState(false)

    function set_active_scroll(item) {
        if (!props.activeScroll || (item.join() !== props.activeScroll.join())) {
            props.setActiveScroll(item)
        }
    }

    function change_date(dir) {
        if (dir === undefined) {
            props.change_date()
            set_active_scroll([".scroll_days", ".ScheduleBox"])
            setTimeout(() => scroll_today(), 100)
        } else {
            props.change_date(dir)
            props.setDaySelection([])
        }
    }

    function scroll_today() {
        try {
            const today = document.querySelector('.today')
            const top = today.getBoundingClientRect().top
            const middle = document.body.offsetHeight / 2
            const diff_y = (top - middle)
            document.querySelector('.scroll_days').scrollBy(0, diff_y)
            props.setDaySelection([])
            props.setDayProgram(false)
        } catch (e) {
            console.log('Could not scroll to today.')
        }

    }

    return (
        <div
            className={'Calendar'}
            onMouseOver={() => set_active_scroll(['.scroll_days', '.ScheduleBox'])}
            onTouchStart={() => set_active_scroll(['.scroll_days', '.ScheduleBox'])}
        >
            {
                showShow && <SettingsShows {...props} setShowShow={setShowShow}/>
            }
            <div className={'head_calendar'} onClick={() => {
                props.moduleSettings.special && setShowShow(true)
            }}>
                <div className={'buttons'}>
                    {
                        !props.moduleSettings.special &&
                        <>
                            <div className={'prev schalter'} onClick={() => change_date(-1)}/>
                            <div className={'next schalter'} onClick={() => change_date(1)}/>
                        </>
                    }
                </div>
                <div className={'date'}>
                    {
                        props.moduleSettings.special
                            ? <div className={'edit_settings'}>
                                Szenario bearbeiten
                            </div>
                            : <>{props.g_state.settings.month_names[props.date.getMonth()].short} {props.date.getFullYear()}</>
                    }
                </div>
            </div>
            <div className={'scroll_days'} onDoubleClick={() => change_date()}>
                <div id={'days'} className={`days ${props.addBottom === true ? 'pool_open' : ''}`}>
                    <div className={'day_blank'}/>
                    {
                        props.moduleSettings.special
                            ? props.shows.map(
                                // Shows
                                (el, i) =>
                                    <div className={
                                        `day ${props.selectedShow === el.uid ? 'active' : ''} ${el.default ? 'default_show' : ''}`
                                    } key={i}>
                                        {el.name}
                                    </div>
                            )
                            : props.days.map(
                                // Tage
                                (el, i) => {
                                    const day_active = props.daySelection[0] === i
                                    return (
                                        <div
                                            className={
                                                `day ${day_active ? 'active' : ''} ${props.today === i ? 'today' : ''}`
                                            }
                                            key={i}
                                        >
                                            {props.g_state.settings.day_names[el.getDay()].short}. {props.g_state.f.zfill(i + 1)}.
                                        </div>
                                    )
                                }
                            )
                    }
                </div>
            </div>
        </div>
    )
}

function Day(props) {
    const act_id = [props.day_num, props.station_num, props.part_num, props.uid]

    let my_class = ['day_entry']
    my_class.push(props.daySelection.slice(0, 3).join('-') === act_id.slice(0, 3).join('-') ? 'selected' : '')
    my_class.push(act_id[0] === props.today ? 'today' : '')
    my_class = my_class.filter(el => el !== '')

    function within_week() {
        if (
            props.today !== null) {
            if (
                props.date.getMonth() === new Date().getMonth() &&
                props.date.getFullYear() === new Date().getFullYear()
            ) {
                return props.date.getDate() > props.today && props.date.getDate() < props.today + 8
            }
        }
    }

    const actual_day = props.dayEntriesByMs[props.uid] && props.dayEntriesByMs[props.uid][props.day_num]
    if (actual_day?.filter(el => el.not_valid).length > 0) {
        my_class.push('time_invalid')
    }

    return (
        <div
            className={`${my_class.join(' ')}`} key={props.day_num}
            data-drop_target={`add_to_day|${
                props.moduleSettings.special
                    ? props.day_num + 1
                    : props.date.getDate()
            }|${props.station_num}|${props.part_num}|${props.uid}`}
            data-show-id={props.moduleSettings ? props.showId : null}
            onClick={() => {
                props.moduleSettings.special && props.setSelectedShow(props.showId)
                props.setDaySelection(act_id)

                props.setPartId(props.partId)
                props.setActiveMs(props.uid)
                props.setPool(null)
            }}
        >
            <div className={`categories ${within_week() ? 'red' : ''}`}>
                {
                    within_week()
                }
                {
                    actual_day?.map(
                        (el, i) =>
                            <div
                                style={{backgroundColor: el.category.color}} data-cat_num={el.category.id} key={i}
                                className={`category _content_id_${el.content_id}`} data-content-id={el.content_id}
                            />
                    )
                }
            </div>
        </div>
    )
}

function InfoPortal(props) {
    const [data, setData] = useState(null)
    useEffect(() => {
        if (props.show) {
            get_info_for_mediastation(
                props.g_state.connector,
                props.uid
            ).then(
                res => setData(res.data)
            )
        }
    }, [props.show])

    if (props.show) {
        return createPortal(
            <div className={'panel_show_mediainfo fr'}>
                <div className={'mediainfo fc'}>
                    <div className={'head fr'}>
                        <div className={'title'}>
                            Informationen {data?.name} {'//'} {data?.part}
                        </div>
                        <div className={'close'} onClick={(ev) => {
                            ev.stopPropagation()
                            props.setShow(false)
                        }}/>
                    </div>
                    <div className={'body fr'}>
                        <div className={'left'}>
                            <div className={'header_infos'}>Standortinfos</div>
                            <div className={'einbau'}>{data?.info}</div>
                            <div className={'header_infos'}>Einbauinfos</div>
                            <div className={'infos'} dangerouslySetInnerHTML={{__html: data?.einbau_info}}/>
                            <div className={'opening'}>
                                <div className={'header_opening'}>Regelöffnungszeiten</div>
                                {
                                    [...Array(7)].map(
                                        (el, i) =>
                                            <div key={i} className={'time fr'}>
                                                <div>{props.g_state.settings.day_names[(i + 8) % 7].long}</div>
                                                <div className={'fr'}>
                                                    {
                                                        (data?.opening[i][0] && data?.opening[i][1])
                                                            ? <>
                                                                <div>{data?.opening[i][0]}</div>
                                                                <div>-</div>
                                                                <div>{data?.opening[i][1]}</div>
                                                            </>
                                                            : ''
                                                    }
                                                </div>
                                            </div>
                                    )
                                }
                            </div>
                            {
                                data?.machines?.length > 0 &&
                                <>
                                    <div>&nbsp;</div>
                                    <div className={'header_machines'}>Geräte</div>
                                    <div className={'machines'}>
                                        {
                                            data.machines.map(
                                                el => `${el.typ_name} (${el.ip})`
                                            ).join(', ')
                                        }
                                    </div>
                                </>
                            }
                            <div className={'header_key'}>&nbsp;<br/>Key Medienstation</div>
                            <div className={'key'}>{data?.key}</div>
                        </div>
                        <div className={'right fr'}>
                            <div className={'lage'}>
                                <div className={'title'}>Lageplan</div>
                                <div
                                    className={'img_lage'}
                                    style={{
                                        backgroundImage: `url(${
                                            context.settings.server_protocol
                                        }://${
                                            context.settings.server_hostname
                                        }:${
                                            context.settings.server_port
                                        }${
                                            data?.plan
                                        })`,
                                    }}
                                />
                            </div>
                            <div className={'station'}>
                                <div className={'title'}>Medienstation</div>
                                <div
                                    className={'img_station'}
                                    style={{
                                        backgroundImage: `url(${
                                            context.settings.server_protocol
                                        }://${
                                            context.settings.server_hostname
                                        }:${
                                            context.settings.server_port
                                        }${
                                            data?.image
                                        })`,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            , document.body
        )
    } else {
        return null
    }
}

function InfoMediastation(props) {
    const [show, setShow] = useState(false)
    return (
        <div className={'InfoMediastation bo'} onClick={() => setShow(true)}>
            <InfoPortal {...props} show={show} setShow={setShow}/>
        </div>)
}

function ScheduleBox(props) {
    const [selected, setSelected] = useState([])
    const [entries, setEntries] = useState({})
    const [dayEntriesByMs, setDayEntriesByMs] = useState({})

    function change_entries(list, index) {
        if (index !== undefined) {
            const new_list = JSON.parse(JSON.stringify(entries))
            new_list[index] = list

            const old_order = entries[index].map(el => el.id).join('-')
            const new_order = list.map(el => el.id).join('-')

            if (!(old_order === new_order)) {
                setEntries(new_list)

                set_station_order_for_parts(
                    props.g_state.connector,
                    {media_station: index, order: new_list[index].map(el => el.id)}
                )
                    .then(
                        () => {
                            props.setDaySelection([])
                            props.setPool(null)
                            props.setDayProgram(false)
                        }
                    )
            }
        }
    }

    function set_selected(uid) {
        if (selected.indexOf(uid) < 0) {
            load_stations_for_part(props.g_state.connector, uid, entries, props.moduleSettings.special)
                .then(res => setEntries(res))
            setSelected([...selected, uid])
        } else {
            delete entries[uid]
            setSelected(selected.filter(el => el !== uid))
        }
    }

    useEffect(
        () => {
            load_day_entries_by_ms(props.g_state.connector, entries, setDayEntriesByMs, {}, props.date)
                .then(
                    res => setDayEntriesByMs(res)
                )
        }, [props.date, props.refresh])

    useEffect(
        () => {
            load_day_entries_by_ms(props.g_state.connector, entries, setDayEntriesByMs, dayEntriesByMs, props.date)
                .then(
                    res => setDayEntriesByMs(res)
                )
        }, [entries])

    function set_active_scroll(item) {
        if (!props.activeScroll || (item.join() !== props.activeScroll.join())) {
            if (selected.length > 0) {
                props.setActiveScroll(item)
            }
        }
    }

    return (
        <div
            className={'ScheduleBox'}
            onMouseOver={() => set_active_scroll(['.ScheduleBox', '.scroll_days'])}
            onTouchStart={() => set_active_scroll(['.ScheduleBox', '.scroll_days'])}
        >
            {
                props.buildingParts.map(
                    // Gebäudebereiche
                    (el, part_num) => {
                        const is_selected = selected.indexOf(el.id) >= 0
                        return (
                            <div
                                className={`building_part ${is_selected ? 'selected' : ''}  ${props.addBottom === true ? 'pool_open' : ''}`}
                                key={el.id}
                                onClick={() => (!is_selected && set_selected(el.id))}
                            >
                                <div className={'head_schedule'}>
                                    <div className={'toggle_title'}>
                                        <div
                                            className={`toggle ${is_selected ? 'selected' : ''}`}
                                            onClick={() => set_selected(el.id)}
                                        />
                                        <div className={`title ${is_selected ? 'selected' : ''}`}>
                                            <span>{`${el.name}`}</span>
                                        </div>
                                    </div>
                                    {
                                        (is_selected && entries[el.id]) &&
                                        <div className={'media_stations'}>
                                            <ReactSortable
                                                className={'sortable_media_station fr'} list={entries[el.id]}
                                                setList={list => change_entries(list, el.id)}
                                            >
                                                {
                                                    entries[el.id]
                                                        ? entries[el.id].map(
                                                            // Medienstationen
                                                            (el, station_num) =>
                                                                <div className={`title_ms ${
                                                                    props.daySelection.slice(1, 3).join('-') === `${station_num}-${part_num}`
                                                                        ? 'active' : ''
                                                                }`} key={station_num}>
                                                                    {el.short}
                                                                    <InfoMediastation {...props} uid={el.id}/>
                                                                </div>
                                                        )
                                                        : null
                                                }
                                            </ReactSortable>
                                        </div>
                                    }
                                </div>
                                {
                                    is_selected &&
                                    <div className={'bottom_schedule'}>
                                        {
                                            props.moduleSettings.special
                                                ? (
                                                    entries[el.id]
                                                        ? (
                                                            entries[el.id].map(
                                                                // Medienstationen
                                                                (el, station_num) =>
                                                                    <div
                                                                        // NOTE set class for errors here e.g.
                                                                        //  content does not fit...
                                                                        className={'entry_ms'}
                                                                        data-resolution={el.resolution}
                                                                        key={station_num}>
                                                                        {
                                                                            props.shows.map(
                                                                                // Anzahl shows
                                                                                (el_show, day_num) =>
                                                                                    <Day
                                                                                        {...props}
                                                                                        uid={el.id}
                                                                                        date={props.days[day_num]}
                                                                                        day_num={el_show.uid}
                                                                                        station_num={station_num}
                                                                                        part_num={part_num}
                                                                                        today={props.today}
                                                                                        dayEntriesByMs={dayEntriesByMs}
                                                                                        key={day_num}
                                                                                        partId={el.partId}
                                                                                        setPartId={props.setPartId}
                                                                                        showId={el_show.uid}
                                                                                    />
                                                                            )
                                                                        }
                                                                    </div>
                                                            )
                                                        )
                                                        : null
                                                )


                                                : (
                                                    entries[el.id]
                                                        ? (
                                                            entries[el.id].map(
                                                                // Medienstationen
                                                                (el, station_num) =>
                                                                    <div
                                                                        // NOTE set class for errors here e.g.
                                                                        //  content does not fit...
                                                                        className={'entry_ms'}
                                                                        data-resolution={el.resolution}
                                                                        key={station_num}>
                                                                        {
                                                                            props.days.map(
                                                                                // Tage
                                                                                (el_day, day_num) =>
                                                                                    <Day
                                                                                        {...props}
                                                                                        uid={el.id}
                                                                                        date={props.days[day_num]}
                                                                                        day_num={day_num}
                                                                                        station_num={station_num}
                                                                                        part_num={part_num}
                                                                                        today={props.today}
                                                                                        dayEntriesByMs={dayEntriesByMs}
                                                                                        key={day_num}
                                                                                        partId={el.partId}
                                                                                        setPartId={props.setPartId}
                                                                                    />
                                                                            )
                                                                        }
                                                                    </div>
                                                            )
                                                        )
                                                        : null
                                                )
                                        }
                                    </div>
                                }
                            </div>
                        )
                    }
                )
            }
        </div>
    )
}

function ContentPoolFilter(props) {
    return createPortal(
        <div className={'ContentPoolFilter'}>
            <div className={`b0 ${props.pool === 0 ? 'selected' : ''}`}
                 onClick={() => {
                     props.setPool(props.pool !== 0 ? 0 : null)
                     props.setDayProgram(false)
                 }}>CP
            </div>
            <div className={`b1 ${props.pool === 1 ? 'selected' : ''}`}
                 onClick={() => {
                     props.setPool(props.pool !== 1 ? 1 : null)
                     props.setDayProgram(false)
                 }}>SI
            </div>
        </div>,
        document.querySelector('.top_left')
    )
}

function DragItem(props) {

    let dragElement = null

    function handle_drag(ev) {
        switch (ev.type) {
            case 'touchmove':
            case 'mousemove':
                if (dragElement) {
                    dragElement.style.left = `${ev.type === 'mousemove' ? ev.clientX : ev.touches[0].clientX}px`
                    dragElement.style.top = `${ev.type === 'mousemove' ? ev.clientY : ev.touches[0].clientY}px`
                }
                break

            case 'mouseup':
            case 'touchend':
                if (ev.type === 'touchend' && ev.touches.length > 0) {
                    break
                }
                let drop_target = ev.target.closest('[data-drop_target]')

                const src_uid = dragElement.dataset.uid
                const src_action = dragElement.dataset.action

                props.callbackDrag && props.callbackDrag(false)
                document.body.removeChild(dragElement)

                let element_under_mouse
                if (ev.type === 'mouseup') {
                    element_under_mouse = document.elementFromPoint(ev.clientX, ev.clientY)
                } else {
                    element_under_mouse = document.elementFromPoint(
                        ev.changedTouches[0].clientX, ev.changedTouches[0].clientY
                    ).closest('[data-drop_target]')
                }

                if (element_under_mouse && element_under_mouse.dataset.drop_target) {
                    drop_target = drop_target || element_under_mouse
                }

                if (drop_target && src_action && src_uid) {
                    // NOTE can be other errors to.
                    if (drop_target.closest('.error')) {
                        console.log('Can\'t add content: its incompatible.')
                    } else if (drop_target.closest('.invalid_time')) {
                        console.log('Can\'t add content: its incompatible.')
                    } else {
                        props.callback &&
                        props.callback({
                            src_uid, src_action, action: drop_target.dataset.drop_target,
                            showId: drop_target.dataset.showId
                        })
                    }
                }

                dragElement = null
                document.removeEventListener('mousemove', handle_drag)
                document.removeEventListener('mouseup', handle_drag)
                document.removeEventListener('touchmove', handle_drag)
                document.removeEventListener('touchend', handle_drag)

                set_available_columns()

                document.querySelectorAll(`[data-drop_target]`).forEach(
                    obj => obj.classList.remove('invalid_time')
                )

                break
            default:
                break
        }
    }

    function set_available_columns(resolutions) {
        const rows = document.querySelectorAll('[data-resolution]')

        Object.keys(rows).map(
            el => {
                if (resolutions && resolutions.indexOf(rows[el].dataset.resolution) < 0) {
                    rows[el].classList.add('error')
                } else {
                    rows[el].classList.remove('error')
                }
                return null
            }
        )

    }

    function set_invalid_days(valid_from, valid_to) {
        let invalid_from = [], invalid_to = []
        if (valid_from) {
            valid_from = new Date(`${valid_from}T00:00`)
            invalid_from = [...Array(31)].map(
                (el, i) => {
                    const check_date = new Date(`${props.date.getFullYear()}-${props.date.getMonth() + 1}-${i + 1}`)
                    return valid_from > check_date ? (i + 1) : null
                }
            ).filter(el => el !== null)
        }
        if (valid_to) {
            valid_to = new Date(`${valid_to}T00:00`)
            valid_to.setDate(valid_to.getDate() + 1)
            invalid_to = [...Array(31)].map(
                (el, i) => {
                    const check_date = new Date(`${props.date.getFullYear()}-${props.date.getMonth() + 1}-${i + 1}`)
                    return valid_to < check_date ? (i + 1) : null
                }
            ).filter(el => el !== null)
        }
        const all_invalid = [...invalid_from, ...invalid_to]
        all_invalid.forEach(
            daynum => {
                document.querySelectorAll(`[data-drop_target^="add_to_day|${daynum}|"]`).forEach(
                    obj => {
                        obj.classList.add('invalid_time')
                    }
                )
            }
        )
    }

    function start_drag(ev) {
        props.callbackDrag && props.callbackDrag(true)
        dragElement = ev.currentTarget.cloneNode(true)
        dragElement.classList.add('drag_clone')
        dragElement.style.pointerEvents = 'none'
        document.body.append(dragElement)

        set_available_columns(props.resolutions.split(','))
        set_invalid_days(props.element.valid_from, props.element.valid_to)

        if (ev.type === 'mousedown') {
            document.addEventListener('mousemove', handle_drag)
            document.addEventListener('mouseup', handle_drag)
        } else if (ev.type === 'touchstart') {
            document.addEventListener('touchmove', handle_drag)
            document.addEventListener('touchend', handle_drag)
        }
    }

    return (
        <div
            style={{width: 'min-content', height: 'min-content'}}
            className={`dragger _content_id_${props.uid}`} draggable={false}
            // TODO check resolutions with column resolution
            data-resolutions={props.resolutions}
            data-uid={props.uid} data-action={props.action}
            onMouseDown={ev => start_drag(ev)} onTouchStart={ev => start_drag(ev)}
        >
            {props.children}
        </div>
    )
}

function ContentPoolHolder(props) {
    const selected_pool = ['Content Pool', 'Sonderinhalte'][props.pool]
    const [items, setItems] = useState([])
    const [filter, setFilter] = useState(0)

    useEffect(() => {
        props.g_state.connector.run_action(
            {
                action: 'get_contents_for_schedule_pool',
                data: ''
            },
            res => {
                setItems(res.data)
            }
        )
    }, [props.refresh])

    return (
        <div className={`ContentPoolHolder bo`}>
            <div className={'header fr'}>
                <div className={'left fr'}>
                    <div className={'title'}>{selected_pool}</div>
                    <div className={'toggle_filter fr'}>
                        <div
                            className={`button bo ${filter === 0 ? 'selected' : ''}`}
                            onClick={() => setFilter(0)}
                        >
                            fertig
                        </div>
                        <div
                            className={`button bo ${filter === 1 ? 'selected' : ''}`}
                            onClick={() => setFilter(1)}
                        >
                            im Programm
                        </div>
                    </div>
                </div>
                <div className={'right fr'}>
                    <div className={'close_pool'} onClick={() => props.setPool(null)}/>
                </div>
            </div>
            <div className={'content fr'}>
                <div className={'left fr categories'}>
                    {
                        items && items
                            .filter(el => (props.pool === 1) === el.special)
                            .filter(el => filter === 0 ? !el.planned : el.planned)
                            .map(
                                (el, i) =>
                                    <DragItem
                                        key={i} uid={el.id} action={'ContentPool'}
                                        resolutions={el.resolutions.join(',')}
                                        element={el} date={props.date}
                                        callback={props.item_dropped_on_target}
                                    >
                                        <div
                                            className={`item fc`} data-uid={el.id}
                                            style={{borderColor: el.category_color}}
                                        >
                                            <div
                                                className={`symbol bo ${el.prev ? 'preview' : ''}`}
                                                style={{
                                                    backgroundImage: `url(${
                                                        context.settings.server_protocol
                                                    }://${
                                                        context.settings.server_hostname
                                                    }:${
                                                        context.settings.server_port
                                                    }${
                                                        el.content_image || el.prev
                                                    })`,
                                                }}
                                            />
                                            <div className={'title'}>{el.name}</div>
                                        </div>
                                    </DragItem>
                            )
                    }
                </div>
                <div className={'right fc'}>
                    <div
                        data-drop_target={`duplicate_to_pool`}
                        className={'button add_content bo'} onClick={() => {
                        props.pool === 0 ? props.setAddContent(true) : props.setAddSpecialContent(true)
                        props.setMiniPool({mini: true})
                    }}/>
                    <div
                        data-drop_target={`delete_from_pool`}
                        className={'button fr delete_content bo'}>
                        <div style={{pointerEvents: 'none', userSelect: 'none'}}/>
                    </div>
                </div>
            </div>
            {
                props.addContent &&
                <ContentEditor {...props}/>
            }
            {
                props.addSpecialContent &&
                <SpecialEditor {...props} uid={true}/>
            }
        </div>
    )
}

function PopUpContentAction(props) {
    let my_choice

    function run_choice(choice) {
        switch (choice) {
            case 1:
                my_choice = 1
                break
            case 2:
                my_choice = 2
                break
            case 3:
                my_choice = 3
                break
            case 4:
                my_choice = 4
                break
            default:
                my_choice = null
                break
        }

        if (my_choice) {
            add_content_from_day_to_day(
                props.g_state.connector,
                props.popupDuplicateEntryDay.insert_date,
                props.popupDuplicateEntryDay.insert_ms,
                props.popupDuplicateEntryDay.content_id,
                {1: 'add', 2: 'replace', 3: 'add_to_row', 4: 'add_and_replace_row'}[my_choice],
                props.popupDuplicateEntryDay.show_id,
            ).then(
                setTimeout(() => props.refresh_data(['schedule'], 'PopUpContentAction'), 100)
            )
        }

        props.setPopupDuplicateEntryDay(false)
    }

    return (
        <div className={'PopUpContentAction'}>
            <div className={'message'}>
                <div className={'title'}>Aktion wählen</div>
                <div className={'header'}>Einzelaktion</div>
                <div onClick={() => run_choice(1)} className={'simple_button option bo'}>Content hinzufügen</div>
                <div onClick={() => run_choice(2)} className={'simple_button option bo'}>Content ersetzen</div>
                <div className={'header'}>Gruppenaktion</div>
                <div onClick={() => run_choice(3)} className={'simple_button option bo'}>Content in ganzer Gruppe
                    hinzufügen
                </div>
                <div onClick={() => run_choice(4)} className={'simple_button option bo'}>Content in ganzer Gruppe
                    ersetzen
                </div>
                <div className={'spacer'}/>
                <div onClick={() => run_choice(0)} className={'simple_button option bo'}>abbrechen</div>
            </div>
        </div>
    )
}

function PopUpDayElementAction(props) {
    let my_choice

    function run_choice(choice) {
        switch (choice) {
            case 1:
                my_choice = 1
                break
            case 2:
                my_choice = 2
                break
            case 3:
                my_choice = 3
                break
            case 4:
                my_choice = 4
                break
            default:
                my_choice = null
                break
        }

        if (my_choice) {
            duplicate_day_entry(
                props.g_state.connector,
                props.popupDuplicateDayProgram.insert_date,
                props.popupDuplicateDayProgram.insert_ms,
                props.popupDuplicateDayProgram.content_id,
                {1: 'add', 2: 'replace', 3: 'add_to_row', 4: 'add_and_replace_row'}[my_choice],
                props.popupDuplicateDayProgram.show_id,
            ).then(
                setTimeout(() => props.refresh_data(['schedule'], 'PopUpContentAction'), 100)
            )
        }

        props.setPopupDuplicateDayProgram(false)
    }

    return (
        <div className={'PopUpDayElementAction'}>
            <div className={'message'}>
                <div className={'title'}>Aktion wählen</div>
                <div className={'header'}>Einzelaktion</div>
                <div onClick={() => run_choice(1)} className={'simple_button option bo'}>Tagesprogramm hinzufügen</div>
                <div onClick={() => run_choice(2)} className={'simple_button option bo'}>Tagesprogramm ersetzen</div>
                <div className={'header'}>Gruppenaktion</div>
                <div onClick={() => run_choice(3)} className={'simple_button option bo'}>Tagesprogramm in ganzer Gruppe
                    hinzufügen
                </div>
                <div onClick={() => run_choice(4)} className={'simple_button option bo'}>Tagesprogramm in ganzer Gruppe
                    ersetzen
                </div>
                <div className={'spacer'}/>
                <div onClick={() => run_choice(0)} className={'simple_button option bo'}>abbrechen</div>
            </div>
        </div>
    )
}

const DayProgram = React.memo(
    (props) => {
        const [program, setProgram] = useState(null)
        const [choiceValid, setChoiceValid] = useState({})
        const [exclusiv, setExclusiv] = useState(null)
        const [mute, setMute] = useState({})
        const [duration, setDuration] = useState({})
        const [itemList, setItemList] = useState([])
        const [popupDuplicateEntryDay, setPopupDuplicateEntryDay] = useState(null)
        const [popupDuplicateDayProgram, setPopupDuplicateDayProgram] = useState(null)
        const [confirmDelete, setConfirmDelete] = useState(null)
        const [showOpening, setShowOpening] = useState(null)

        const [specialContentUid, setSpecialContentUid] = useState(null)

        useEffect(() => {
            if (program) {
                const all_muted = {}
                const all_durations = {}

                const my_item_list = program.day_entries.map(
                    de => de.entries.map(
                        (ti) => {
                            ti.is_exclusive && setExclusiv(ti.id);
                            if (!ti.sound) {
                                all_muted[ti.id] = true
                            }
                            all_durations[ti.id] = {
                                from: `${props.g_state.f.zfill(ti.time_start.hour)}:${props.g_state.f.zfill(ti.time_start.minute)}`,
                                ini_from: `${props.g_state.f.zfill(ti.time_start.hour)}:${props.g_state.f.zfill(ti.time_start.minute)}`,
                                to: `${props.g_state.f.zfill(ti.time_end.hour)}:${props.g_state.f.zfill(ti.time_end.minute)}`,
                                ini_to: `${props.g_state.f.zfill(ti.time_end.hour)}:${props.g_state.f.zfill(ti.time_end.minute)}`,
                                error_from: false,
                                error_to: false,
                                duration: ti.duration,
                            }
                            return ti
                        }
                    )
                )

                const tmp_active_date_list = {}
                my_item_list[0] && my_item_list[0].forEach(
                    el => {
                        tmp_active_date_list[el.id] = true
                    }
                )
                setChoiceValid(tmp_active_date_list)

                setItemList(my_item_list[0])
                // console.log(my_item_list)

                setDuration(all_durations)
                setMute(all_muted)
            }

        }, [program])

        useEffect(() => {
            if (props.daySelection.length > 0) {
                setChoiceValid({})
                setExclusiv(null)
                setMute({})
                props.g_state.connector.run_action(
                    {
                        action: 'getday_entry_by_ms_and_day',
                        class: '',
                        data: `{"month": ${props.date.getMonth() + 1}, "year": ${props.date.getFullYear()}, "day": ${props.daySelection[0] + 1}, "ms_id":${props.daySelection[3]}}`
                    },
                    // Iterate over the days and set contents (max 4)
                    res => {
                        setProgram(res.data)
                    }
                )
            }
        }, [props.daySelection, props.activeMs, props.refresh, showOpening])

        function set_exclusive(uid) {
            set_time_item_exclusive(props.g_state.connector, (exclusiv === null), uid)

            setExclusiv(exclusiv === null ? uid : null)
        }

        function set_mute(uid) {
            change_time_item_muted(props.g_state.connector, !(mute[uid] === undefined), uid)
            if (mute[uid]) {
                const tmp_prev = {...mute}
                delete tmp_prev[uid]
                setMute(() => tmp_prev)
            } else {
                setMute(prev => ({...prev, [uid]: true}))
            }
        }

        function change_input(ev, uid) {
            const typ = ev.currentTarget.dataset.typ
            const entries = {...duration}
            const value = ev.currentTarget.value
            let error = !/^\d{1}\d{1}:\d{1}\d{1}$/.test(value)
            if (!error) {
                const [hour, minute] = value.split(':')
                error = parseInt(hour, 10) > 23 || parseInt(minute, 10) > 59
            }

            entries[uid][typ] = value
            entries[uid][`error_${typ}`] = error

            if (!error) {
                entries[uid][`ini_${typ}`] = value
                change_time_item_time(props.g_state.connector, value, uid, typ)
            }

            setDuration(entries)
        }

        function change_duration_input(ev, uid) {
            const entries = {...duration}
            let val = parseInt(ev.currentTarget.value, 10)
            val = isNaN(val) ? null : val
            ev.currentTarget.value = val

            setDuration(entries)
            console.log(uid, entries)
            props.g_state.connector.run_action(
                {
                    action: 'set_day_entry_duration',
                    class: '',
                    data: `{"entry_uid":${uid}, "duration":${val}}`
                },
                // Iterate over the days and set contents (max 4)
                res => {
                    console.log(res)
                }
            )
            entries[uid].duration = val || ''
        }

        function check_blur(ev, uid) {
            if (ev.type === 'keyup' && ev.keyCode !== 27) {
                return
            }
            const typ = ev.currentTarget.dataset.typ
            const entries = {...duration}
            if (entries[uid][`error_${typ}`]) {
                entries[uid][`${typ}`] = entries[uid][`ini_${typ}`]
                entries[uid][`error_${typ}`] = false
            }
            setDuration(entries)
        }

        function write_list_to_db(ev) {
            if (!ev.target) {
                return
            }

            if (ev.oldIndex !== ev.newIndex) {
                const ordered_items = {}
                itemList.map(
                    (el, i) =>
                        ordered_items[el.id] = i
                )
                update_dayelement_order(props.g_state.connector, ordered_items)
            }

            ev.target.style.pointerEvents = 'none'
            window.getComputedStyle(ev.target)

            let point = null
            if (ev.originalEvent.changedTouches) {
                if (ev.originalEvent.touches.length === 0) {
                    point = {
                        x: ev.originalEvent.changedTouches[0].clientX,
                        y: ev.originalEvent.changedTouches[0].clientY
                    }
                }
            } else {
                point = {x: ev.originalEvent.clientX, y: ev.originalEvent.clientY}
            }

            if (point) {
                const element = document.elementFromPoint(point.x, point.y)
                if (element) {
                    const content = element
                        .closest('[data-drop_target]')
                    ev.target.style.pointerEvents = ''
                    content && handle_content_from_dayentry(
                        itemList[ev.newIndex], content.dataset.drop_target,
                        props.moduleSettings.special ? content.dataset.showId : null
                    )

                } else {
                    console.log(element, point)
                    console.log('Error! Could not delete for whatever reason.')
                }
            }
        }

        function handle_content_from_dayentry(source, target, show_id) {
            const action = target.split('|')[0]
            if (action === 'add_to_day') {
                const insert_date = `${
                    props.date.getFullYear()
                }-${
                    props.date.getMonth() + 1
                }-${
                    target.split('|')[1]
                }`
                const insert_ms = target.split('|')[4]
                setPopupDuplicateEntryDay({insert_date, insert_ms, content_id: source.id, show_id})
            } else if (action === 'add_to_dayentry') {
                add_to_dayentry(props.g_state.connector, source.id).then(
                    props.refresh_data(['schedule', 'dayprogram'], 'DayProgram')
                )
            } else if (action === 'delete_from_dayentry') {
                setConfirmDelete({uid: source.id})
            }
        }

        function delete_content_after_confirm() {
            delete_from_dayentry(props.g_state.connector, confirmDelete.uid)
                .then(() => {
                    props.refresh_data(['schedule', 'dayprogram'], 'DayProgram')
                })
            setConfirmDelete(null)
        }

        function handle_content_from_dayprogram(ev) {
            ev.target.style.pointerEvents = 'none'
            window.getComputedStyle(ev.target)

            let point = null
            if (ev.originalEvent.changedTouches) {
                if (ev.originalEvent.touches.length === 0) {
                    point = {
                        x: ev.originalEvent.changedTouches[0].clientX,
                        y: ev.originalEvent.changedTouches[0].clientY
                    }
                }
            } else {
                point = {x: ev.originalEvent.clientX, y: ev.originalEvent.clientY}
            }

            if (point) {
                const content = document
                    .elementFromPoint(point.x, point.y)
                    .closest('[data-drop_target]')
                ev.target.style.pointerEvents = ''
                if (content && content.classList.contains('day_entry')) {
                    const insert_date = `${
                        props.date.getFullYear()
                    }-${
                        props.date.getMonth() + 1
                    }-${
                        content.dataset.drop_target.split('|')[1]
                    }`
                    const insert_ms = content.dataset.drop_target.split('|')[4]
                    setPopupDuplicateDayProgram(
                        {
                            insert_date, insert_ms, content_id: ev.item.dataset['uid'],
                            show_id: content.dataset.showId
                        }
                    )
                }
            }
        }

        function edit_content_with_id(content_id, templateset_id) {
            console.log('Edit content with ID:', content_id, 'and templateset id:', templateset_id)
            props.setAddContent({content_id, templateset_id})
        }

        return (
            <div className={'DayProgram bo'}>
                {
                    confirmDelete &&
                    <div className={'confirm_delete fr'}>
                        <div className={'message_box bo'}>
                            <div className={'title'}>Achtung</div>
                            <div className={'info'}>Soll dieser Content wirklich gelöscht werden?</div>
                            <div className={'actions fr'}>
                                <div className={'cancel bo'} onClick={() => setConfirmDelete(null)}>abbrechen</div>
                                <div className={'delete bo'} onClick={() => delete_content_after_confirm()}>löschen
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    showOpening &&
                    <SpecialOpening
                        singleDay={true}
                        date={
                            new Date(props.date.getFullYear(), props.date.getMonth(), props.daySelection[0] + 1,)
                        }
                        partId={props.partId}
                        close={() => setShowOpening(null)}
                    />
                }
                {
                    popupDuplicateEntryDay &&
                    <PopUpContentAction
                        {...props}
                        popupDuplicateEntryDay={popupDuplicateEntryDay}
                        setPopupDuplicateEntryDay={setPopupDuplicateEntryDay}
                    />
                }
                {
                    popupDuplicateDayProgram &&
                    <PopUpDayElementAction
                        {...props}
                        popupDuplicateDayProgram={popupDuplicateDayProgram}
                        setPopupDuplicateDayProgram={setPopupDuplicateDayProgram}
                    />
                }
                {
                    program &&
                    <>
                        <div className={'header fr'}>
                            <div className={'left fr'}>
                                <ReactSortable
                                    clone={true}
                                    list={[1]} setList={(res) => null}
                                    onEnd={ev => handle_content_from_dayprogram(ev)}
                                    dataset-day_entry={program.id_dayentry}
                                >
                                    {
                                        <div className={'title'} data-uid={program.id_dayentry}>
                                            {
                                                props.moduleSettings.special
                                                    ? <>
                                                        {props.shows.find(el => el.uid === props.selectedShow).name} {'//'} {program.part} {'//'} {program.name}
                                                    </>
                                                    : <>
                                                        Tagesprogramm&nbsp;
                                                        {
                                                            props.daySelection.length > 0 &&
                                                            <>
                                                                {props.g_state.f.zfill(props.daySelection[0] + 1)}.{
                                                                props.g_state.settings.month_names[props.date.getMonth()].long
                                                            }.{props.date.getFullYear()} {'//'} {program.part} {'//'} {program.name}
                                                            </>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    }
                                </ReactSortable>
                                <div className={'opening fr'}>
                                    {
                                        (!props.moduleSettings.special && props.daySelection.length > 0) &&
                                        <>
                                            <div
                                                className={`watch ${program.has_special_opening ? 'special' : ''}`}
                                                onClick={() => setShowOpening(true)}
                                            />
                                            {program.opening}
                                            {
                                                program.has_special_opening &&
                                                <div className={'warning_watch'}/>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <div className={'right fr'}>
                                <div
                                    className={'close_pool'}
                                    onClick={() => {
                                        props.setDayProgram(false)
                                        props.setDaySelection([])
                                    }}/>
                            </div>
                        </div>
                        {
                            specialContentUid &&
                            <SpecialEditor
                                uid={specialContentUid} setAddSpecialContent={setSpecialContentUid}
                                refresh_data={props.refresh_data}
                            />
                        }
                        <div className={'content fr'}>
                            {
                                itemList &&
                                <ReactSortable
                                    list={itemList} setList={setItemList} className={'left fr'}
                                    onEnd={ev => write_list_to_db(ev)}
                                >
                                    {
                                        itemList.map(
                                            (timed_item, i) =>
                                                <div className={`timed_item fc ${
                                                    exclusiv !== null
                                                        ? (
                                                            exclusiv === timed_item.id ? '' : 'disabled'
                                                        )
                                                        : ''
                                                }`} key={i} data-show-uid={timed_item.id}>
                                                    {/*{console.log(timed_item)}*/}
                                                    <div className={'title'}>{timed_item.name}</div>
                                                    <div
                                                        className={'preview_holder'}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundImage: `url(${
                                                                    context.settings.server_protocol
                                                                }://${
                                                                    context.settings.server_hostname
                                                                }:${
                                                                    context.settings.server_port
                                                                }${
                                                                    timed_item.content_image || timed_item.prev
                                                                })`,
                                                                border: `2px solid ${timed_item.color}`,
                                                                boxSizing: 'border-box',
                                                                backgroundPosition: 'center',
                                                            }}
                                                            className={'preview bo'}
                                                        />
                                                        <div className={'options-1 fr'}>
                                                            <div className={'edit bo'}
                                                                 onClick={() => {
                                                                     if (timed_item.is_special) {
                                                                         setSpecialContentUid(timed_item.content_id)
                                                                         console.log('Edit Special Content with ID:', timed_item.content_id)
                                                                     } else {
                                                                         edit_content_with_id(timed_item.content_id, timed_item.templateset_id)
                                                                     }
                                                                 }}/>
                                                            {
                                                                program.has_audio &&
                                                                <div
                                                                    onClick={() => set_mute(timed_item.id)}
                                                                    className={`${!mute[timed_item.id] ? 'selected' : ''} mute_audio bo fr`}/>
                                                            }
                                                            <div
                                                                onClick={() => set_exclusive(timed_item.id)}
                                                                className={
                                                                    `bo fr is_exclusive ${(exclusiv === timed_item.id) ? 'selected' : ''}`
                                                                }/>
                                                        </div>
                                                    </div>
                                                    <div className={'options-2 fr'}>
                                                        <div
                                                            className={`${timed_item.valid_to ? '' : 'disabled'} bo valid_date_time date ${!choiceValid[timed_item.id] ? 'selected' : ''}`}
                                                            onClick={() => {
                                                                const tmp_prev = {...choiceValid}
                                                                delete tmp_prev[timed_item.id]
                                                                setChoiceValid(tmp_prev)
                                                            }}/>
                                                        <div
                                                            className={`bo valid_date_time time ${choiceValid[timed_item.id] ? 'selected' : ''}`}
                                                            onClick={() => {
                                                                setChoiceValid(prev => ({
                                                                    ...prev,
                                                                    [timed_item.id]: true
                                                                }))
                                                            }}/>
                                                        {
                                                            !choiceValid[timed_item.id]
                                                                ? <div className={'valid_date'}>
                                                                    {
                                                                        timed_item.valid_to
                                                                            ? `${props.g_state.f.zfill(timed_item.valid_to.day)}.
                                                                            ${props.g_state.f.zfill(timed_item.valid_to.month)}.
                                                                            ${timed_item.valid_to.year}`
                                                                            : null
                                                                    }
                                                                </div>
                                                                : <div className={'valid_time'}>
                                                                    <input
                                                                        data-typ={'from'}
                                                                        name={'from'}
                                                                        value={duration[timed_item.id].from}
                                                                        onKeyUp={ev => check_blur(ev, timed_item.id)}
                                                                        data-ini={duration[timed_item.id].ini_from}
                                                                        className={`${duration[timed_item.id].error_from ? 'error' : ''}`}
                                                                        onChange={ev => change_input(ev, timed_item.id)}
                                                                        onBlur={ev => check_blur(ev, timed_item.id)}
                                                                    />-
                                                                    <input
                                                                        data-typ={'to'}
                                                                        name={'to'} value={duration[timed_item.id].to}
                                                                        onKeyUp={ev => check_blur(ev, timed_item.id)}
                                                                        data-ini={duration[timed_item.id].ini_to}
                                                                        className={`${duration[timed_item.id].error_to ? 'error' : ''}`}
                                                                        onChange={ev => change_input(ev, timed_item.id)}
                                                                        onBlur={ev => check_blur(ev, timed_item.id)}
                                                                    />
                                                                    <input
                                                                        name={'duration'}
                                                                        value={duration[timed_item.id].duration}
                                                                        className={`duration ${duration[timed_item.id].error_to ? 'error' : ''}`}
                                                                        onChange={ev => change_duration_input(ev, timed_item.id)}
                                                                    />
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                        )
                                    }
                                </ReactSortable>
                            }
                            {
                                !itemList &&
                                <div className={'time_item'}/>
                            }
                            <div className={`right fc ${props.daySelection.length > 0 ? '' : 'inactive'}`}>
                                <div
                                    className={'button add_content bo'} data-drop_target={`add_to_dayentry`}
                                    onClick={() => {
                                        props.setAddContent(true)
                                        props.setMiniPool({
                                            mini: true,
                                            date: `${
                                                props.date.getFullYear()
                                            }-${
                                                props.g_state.f.zfill(props.date.getMonth() + 1, 2)
                                            }-${
                                                props.g_state.f.zfill(props.daySelection[0] + 1, 2)
                                            }`,
                                            station: `${props.daySelection[3]}`
                                        })
                                    }}
                                />
                                <div
                                    data-drop_target={`delete_from_dayentry`}
                                    className={'button fr delete_content bo'}>
                                    <div style={{pointerEvents: 'none', userSelect: 'none'}}/>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {
                    props.addContent !== null &&
                    <ContentEditor {...props}/>
                }
            </div>
        )
    }
)

function ProgramPlanning(props) {
    const g_state = useContext(AppContext)
    const [today, setToday] = useState(null)
    const [date, setDate] = useState(
        props.moduleSettings.special
            ? new Date('1970-01-01')
            : new Date()
    )
    const [days, setDays] = useState(list_days_in_month(date.getFullYear(), date.getMonth()))
    const [activeScroll, setActiveScroll] = useState(null)
    const [daySelection, setDaySelection] = useState([]) // day, station, part
    const [buildingParts, setBuildingParts] = useState([])
    const [partId, setPartId] = useState(null)
    const [activeMs, setActiveMs] = useState(null)

    const [pool, setPool] = useState(null)
    const [dayProgram, setDayProgram] = useState(false)
    const [addContent, setAddContent] = useState(null)
    const [addSpecialContent, setAddSpecialContent] = useState(null)
    const [miniPool, setMiniPool] = useState(null)

    const [categories, setCategories] = useState([])
    const [refresh, setRefresh] = useState(0)

    const [confirmDelete, setConfirmDelete] = useState(null)

    const [shows, setShows] = useState([])
    const [selectedShow, setSelectedShow] = useState(null)

    useEffect(() => {
        // NOTE set top filter
        load_building_parts(g_state.connector, props.moduleSettings.special)
            .then(res => setBuildingParts(res))

        load_categories(g_state.connector)
            .then(res => {
                setCategories(res)
            })

        props.moduleSettings.special &&
        edit_show_categories(g_state.connector, JSON.stringify({action: 'list'}))
            .then(
                res => setShows(res.data)
            )

        const interval = setInterval(() => {
            setActiveScroll(tmp_activeScroll => {
                if (tmp_activeScroll) {
                    const target = document.querySelector(tmp_activeScroll[1])
                    const source = document.querySelector(tmp_activeScroll[0])
                    if (target && source) {
                        // TODO sync correct after first unfold !! (jumps to top at the moment)
                        target.scrollTo(target.scrollLeft, source.scrollTop)
                    }
                }
                return tmp_activeScroll
            })
        }, 10);
        return () => {
            clearInterval(interval)
        };
    }, [refresh]);

    useEffect(() => {
        const act_day = new Date()
        if (
            date.getFullYear() === act_day.getFullYear() &&
            date.getMonth() === act_day.getMonth()
        ) {
            setToday(act_day.getDate() - 1)
        } else {
            setToday(null)
        }
    }, [date])

    useEffect(() => {
        if (daySelection.length > 0) {
            setDayProgram(true)
        }
    }, [daySelection])

    useEffect(() => {
        if (pool !== null) {
            setDayProgram(null)
            setDaySelection([])
        }
    }, [pool])

    function refresh_data(part = [], source = '??') {
        console.log('Parts to refresh:', part.join(', '), 'source:', source)
        setRefresh(p => p + 1)
    }

    function change_date(add) {
        let year, month, day
        if (add === undefined) {
            year = new Date().getFullYear()
            month = new Date().getMonth()
            day = new Date().getDate()
        } else {
            year = date.getFullYear() + Math.floor((date.getMonth() + add) / 12)
            month = (date.getMonth() + 12 + add) % 12
            day = 1
        }

        setDate(new Date(year, month, day))
        setDays(list_days_in_month(year, month))
        // setDaySelection([])
    }

    function list_days_in_month(year, month) {
        return [...Array(31)]
            .map((el, i) => new Date(year, month, i + 1))
            .filter(el => el.getMonth() === month)
    }

    function item_dropped_on_target(data) {
        const action = data.action.split('|')
        const show_id = data.showId === undefined ? null : parseInt(data.showId, 10)
        switch (action[0]) {
            case 'add_to_day':
                setPool(null)
                setDayProgram(true)

                // console.log('::DATA::', data)

                const insert_date = `${date.getFullYear()}-${date.getMonth() + 1}-${data.action.split('|')[1]}`
                const insert_ms = data.action.split('|')[4]
                const content_id = data.src_uid
                // console.log('Add on Date:', insert_date, 'Link to MS:', insert_ms, 'Content with ID:', content_id)

                add_content_to_day(g_state.connector, insert_date, insert_ms, content_id, show_id)
                    .then(
                        res => {
                            setDaySelection([
                                    parseInt(action[1], 10) - 1,
                                    parseInt(action[2], 10),
                                    parseInt(action[3], 10),
                                    parseInt(action[4], 10)
                                ]
                            )
                            props.moduleSettings.special && setSelectedShow(show_id)
                            refresh_data(['schedule'], 'ProgramPlanning')
                        }
                    )
                break
            case 'duplicate_to_pool':
                handle_content(g_state.connector, data.src_uid, 'duplicate')
                    .then(refresh_data(['pool'], 'ProgramPlanning'))
                break
            case 'delete_from_pool':
                setConfirmDelete({uid: data.src_uid})
                break
            default:
                break
        }
    }

    return (
        <div className={'ProgramPlanning'}>
            {
                confirmDelete &&
                <div className={'confirm_delete fr'}>
                    <div className={'message_box bo'}>
                        <div className={'title'}>Achtung</div>
                        <div className={'info'}>Soll dieser Content wirklich gelöscht werden?</div>
                        <div className={'info2'}>Achtung: Es wird der Content in der ganzen Planung gelöscht!</div>
                        <div className={'actions fr'}>
                            <div className={'cancel bo'} onClick={() => setConfirmDelete(null)}>abbrechen</div>
                            <div className={'delete bo'} onClick={() => {
                                handle_content(g_state.connector, confirmDelete.uid, 'remove')
                                    .then(refresh_data(['pool'], 'ProgramPlanning'))
                                setConfirmDelete(null)
                            }
                            }>löschen
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Calendar
                date={date} change_date={change_date} g_state={g_state} days={days} setActiveScroll={setActiveScroll}
                daySelection={daySelection} activeScroll={activeScroll} today={today}
                addBottom={pool !== null || dayProgram} setDayProgram={setDayProgram}
                setDaySelection={setDaySelection} {...props}
                shows={shows} selectedShow={selectedShow} setSelectedShow={setSelectedShow}
                refresh_data={refresh_data} refresh={refresh}
            />
            <div className={'spacer_left'}/>
            <ScheduleBox
                g_state={g_state} days={days} setActiveScroll={setActiveScroll} date={date} today={today}
                daySelection={daySelection} setDaySelection={setDaySelection} activeScroll={activeScroll}
                buildingParts={buildingParts} addBottom={pool !== null || dayProgram} setActiveMs={setActiveMs}
                setPool={setPool} setPartId={setPartId} refresh={refresh} {...props}
                shows={shows} selectedShow={selectedShow} setSelectedShow={setSelectedShow}
                setDayProgram={setDayProgram}
            />
            <ContentPoolFilter pool={pool} setPool={setPool} setDayProgram={setDayProgram}/>
            {
                pool !== null &&
                <ContentPoolHolder
                    pool={pool} setPool={setPool} g_state={g_state} item_dropped_on_target={item_dropped_on_target}
                    addContent={addContent} setAddContent={setAddContent} setMiniPool={setMiniPool} miniPool={miniPool}
                    setAddSpecialContent={setAddSpecialContent} addSpecialContent={addSpecialContent}
                    categories={categories} refresh_data={refresh_data} refresh={refresh} date={date}
                />
            }
            {
                dayProgram &&
                <DayProgram
                    g_state={g_state} daySelection={daySelection} date={date} activeMs={activeMs}
                    setDayProgram={setDayProgram} setDaySelection={setDaySelection} partId={partId}
                    addContent={addContent} setAddContent={setAddContent} setMiniPool={setMiniPool} miniPool={miniPool}
                    setAddSpecialContent={setAddSpecialContent} addSpecialContent={addSpecialContent}
                    categories={categories} refresh_data={refresh_data} refresh={refresh}  {...props}
                    shows={shows} selectedShow={selectedShow}
                />
            }
            <SearchProgramPlanning g_state={g_state} hide_status={true}/>
        </div>
    );
}

export default ProgramPlanning;
