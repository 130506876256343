import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../../../App";

import './special_opening.scss';


function Calendar(props) {
    function change_opening(value, datum, action) {
        new Promise(
            (resolve) => {
                props.g_state.connector.run_action(
                    {
                        action: 'change_opening',
                        class: '',
                        data: JSON.stringify({
                            value, datum, action, part_id: props.partId
                        })
                    },
                    res => resolve(res)
                )
            }
        ).then(
            res => {
                props.get_month_data()
            }
        )
    }

    function change_input(ev, uid) {
        const typ = ev.currentTarget.dataset.typ
        const entries = [...props.days]
        const value = ev.currentTarget.value
        let error = !/^\d{1}\d{1}:\d{1}\d{1}$/.test(value)
        if (!error) {
            const [hour, minute] = value.split(':')
            error = parseInt(hour, 10) > 23 || parseInt(minute, 10) > 59
        }

        entries[uid][typ] = value
        entries[uid][`error_${typ}`] = error

        if (!error) {
            entries[uid][`ini_${typ}`] = value

            change_opening(
                {from: entries[uid].from, to: entries[uid].to},
                ev.currentTarget.parentElement.dataset.datum,
                'change'
            )
        }
        props.setDays(entries)
    }

    function check_blur(ev, uid) {
        if (ev.type === 'keyup' && ev.keyCode !== 27) {
            return
        }
        const typ = ev.currentTarget.dataset.typ
        const entries = [...props.days]
        if (entries[uid][`error_${typ}`]) {
            entries[uid][`${typ}`] = entries[uid][`ini_${typ}`]
            entries[uid][`error_${typ}`] = false
        }
        props.setDays(entries)
    }

    function unlock_day(ev, locked, special) {
        if (locked && special) {
            change_opening(null, ev.currentTarget.parentElement.dataset.datum, 'delete')
        } else if (!locked && special) {
            change_opening(null, ev.currentTarget.parentElement.dataset.datum, 'delete')
        } else if (!(locked && special)) {
            change_opening(null, ev.currentTarget.parentElement.dataset.datum, 'lock')
        }
    }


    return (
        <div className={'SpecialCalendar fc'}>
            <div className={'head_calendar'}>

                {
                    !props.singleDay &&
                    <div className={'buttons'}>
                        <div className={`prev schalter bo fr`}
                             onClick={() => props.change_date(-1)}/>
                        <div className={'date'}>
                            {props.g_state.settings.month_names[props.date.getMonth()].long} {props.date.getFullYear()}
                        </div>
                        <div className={`next schalter bo fr`}
                             onClick={() => props.change_date(1)}/>
                    </div>
                }

            </div>
            <div className={'scroll_days'}>
                <div id={'days'} className={`days`}>
                    <div className={'day_blank'}/>
                    {
                        props.days.map(
                            // Tage
                            (el, i) => {
                                const act_datum = `${
                                    props.date.getFullYear()
                                }-${
                                    props.date.getMonth() + 1
                                }-${
                                    i + 1
                                }`
                                return (
                                    <div
                                        className={`day fr ${
                                            act_datum === props.today ? 'today' : ''
                                        } ${
                                            (props.singleDay && props.date.getDate() !== (i + 1)) ? 'hide' : 'single'
                                        }`} key={i}
                                    >
                                        <div className={'title'}>
                                            {props.days[i].weekday}. {i + 1 < 10 ? `0${i + 1}` : i + 1}.
                                        </div>
                                        <div
                                            className={`time fr ${props.days[i].special ? 'special' : ''}`}
                                            onFocus={ev => ev.currentTarget.classList.add('focused')}
                                            onBlur={ev => ev.currentTarget.classList.remove('focused')}
                                            data-datum={act_datum}
                                        >
                                            <div
                                                className={`clock ${props.days[i].off ? 'off' : ''}`}
                                                onClick={
                                                    ev => unlock_day(ev, props.days[i].off, props.days[i].special)
                                                }
                                            >
                                                [CL]
                                            </div>
                                            <input
                                                data-typ={'from'}
                                                name={'from'}
                                                value={props.days[i].off ? '--:--' : (props.days[i].from?props.days[i].from:'')}
                                                onKeyUp={ev => check_blur(ev, i)}
                                                data-ini={props.days[i].ini_from}
                                                className={`${props.days[i].error_from ? 'error' : ''}`}
                                                onChange={ev => change_input(ev, i)}
                                                onBlur={ev => check_blur(ev, i)}
                                                style={{pointerEvents: props.days[i].off ? 'none' : ''}}
                                            />
                                            <div className={'minus'}>-</div>
                                            {
                                                console.log()
                                            }
                                            <input
                                                data-typ={'to'}
                                                name={'to'} value={props.days[i].off ? '--:--' : (props.days[i].to?props.days[i].to:'')}
                                                onKeyUp={ev => check_blur(ev, i)}
                                                data-ini={props.days[i].ini_to}
                                                className={`${props.days[i].error_to ? 'error' : ''}`}
                                                onChange={ev => change_input(ev, i)}
                                                onBlur={ev => check_blur(ev, i)}
                                                style={{pointerEvents: props.days[i].off ? 'none' : ''}}
                                            />
                                        </div>
                                        <div className={'error'}>[ER]{}</div>
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default function SpecialOpening(props) {
    const g_state = useContext(AppContext)
    const [singleDay,] = useState(!!props.singleDay)
    const [date, setDate] = useState(props.date ? props.date : new Date())
    const [days, setDays] = useState(null)
    const [info, setInfo] = useState({})
    const [partId, setPartId] = useState(props.partId ? props.partId : null)
    const [today,] = useState(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`)

    useEffect(() => {
        get_month_data()
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [date]
    )

    function build_day_array(data) {
        const my_days = list_days_in_month(date.getFullYear(), date.getMonth())
        setInfo(data.name)
        setPartId(data.part_id)
        setDays(my_days.map(el => get_day_info(el, data)))
    }

    function get_month_data() {
        new Promise(
            (resolve) => {
                g_state.connector.run_action(
                    {
                        action: 'return_buildingpart_info',
                        class: '',
                        data: JSON.stringify({id:partId, datum: today})
                    },
                    res => resolve(res)
                )
            }
        ).then(
            res => {
                build_day_array(res.data)
            }
        )
    }

    function change_date(add) {
        let year, month, day
        if (add === undefined) {
            year = new Date().getFullYear()
            month = new Date().getMonth()
        } else {
            year = date.getFullYear() + Math.floor((date.getMonth() + add) / 12)
            month = (date.getMonth() + 12 + add) % 12
        }

        day = new Date().getDate()
        setDate(new Date(year, month, day))
        setDays(list_days_in_month(year, month))
    }

    function get_day_info(day_element, partData) {
        // convert python to js weekday
        const weekday = day_element.getDay() % 7
        const iso_date = `${
            day_element.getFullYear()
        }-${
            g_state.f.zfill(day_element.getMonth() + 1)
        }-${
            g_state.f.zfill(day_element.getDate())
        }`
        const has_special = partData.openings[iso_date] !== undefined
        // console.log(weekday, day_element, props.partData.default, iso_date, has_special)
        return {
            // iso: iso_date,
            special: has_special,
            weekday: g_state.settings.day_names[weekday].short,
            from: has_special ? partData.openings[iso_date].from : partData.default[(weekday + 6) % 7].from,
            to: has_special ? partData.openings[iso_date].to : partData.default[(weekday + 6) % 7].to,
            ini_from: has_special ? partData.openings[iso_date].from : partData.default[(weekday + 6) % 7].from,
            ini_to: has_special ? partData.openings[iso_date].to : partData.default[(weekday + 6) % 7].to,
            error_from: false,
            error_to: false,
            off: has_special ? partData.openings[iso_date].off : false,
        }
    }

    function list_days_in_month(year, month) {
        return [...Array(31)]
            .map((el, i) => new Date(year, month, i + 1))
            .filter(el => el.getMonth() === month)
    }

    return (
        <div className={'SpecialOpening'}>
            <div className={`message bo fc ${singleDay ? 'singleday' : ''}`}>
                {
                    days &&
                    <>
                        {
                            props.close &&
                            <div className={'close'} onClick={() => props.close()}>[CLOSE]</div>
                        }
                        <div className={'title'}>{'Abweichende Öffnungszeiten\nfestlegen'}</div>
                        <div className={'part'}>{info}</div>
                        {
                            props.warning &&
                            <div className={'warning'} dangerouslySetInnerHTML={{__html: props.warning}}/>
                        }
                        <Calendar
                            g_state={g_state} change_date={change_date} partId={partId}
                            date={date} setDate={setDate} days={days} setDays={setDays}
                            get_month_data={get_month_data} today={today} singleDay={singleDay}
                        />
                    </>
                }
            </div>
        </div>
    )
}


// Props SpecialOpening
// date Date()
// close () => ()
// singleDay bool
// partId number
// E.g.: <SpecialOpening singleDay={true} date={new Date(2021, 1, 15)} close={true}/>