import React from 'react'

import './machine_and_room.scss'
import ListPropertyControl from "../../../../../assets/list_property_control/list_property_control";
import {createPortal} from "react-dom";
import {context as g_state} from "../../../../../../context";


function MachineAndRoom(props) {
    const url = `${g_state.settings.server_protocol}://${g_state.settings.server_hostname}:${g_state.settings.server_port}`

    return (
        <div className={'MachineAndRoom'}>
            <ListPropertyControl {...props} model={'MachineAndRoom'}/>
            {
                createPortal(
                    <div
                        className={'inner_export'} style={{display: 'block'}}
                        onClick={() => window.open(`${url}/export-machines/`, '_blank')}
                    >
                    </div>,
                    document.querySelector('.export_holder')
                )
            }
        </div>
    );
}

MachineAndRoom.defaultProps = {}

export default MachineAndRoom;