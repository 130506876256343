import User from "./components/intro/login/dashboard/admin/user/user";
import SetVolume from "./components/intro/login/dashboard/control/set_volume/set_volume";
import SignalSource from "./components/intro/login/dashboard/control/signal_source/signal_source";
import MachineWatch from "./components/intro/login/dashboard/control/machine_watch/machine_watch";
import RunInstantContent from "./components/intro/login/dashboard/control/run_instant_content/run_instant_content";
import ContentPoolHolder from "./components/intro/login/dashboard/content/content_pool/content_pool";
import ProgramPlanning from "./components/intro/login/dashboard/content/program_planning/program_planning";
// import WeekMenus from "./components/intro/login/dashboard/content/week_menus/week_menus";
import TenantAndRoom from "./components/intro/login/dashboard/content/tenant_and_room/tenant_and_room";
// import SetInstantContent from "./components/intro/login/dashboard/content/set_instant_content/set_instant_content";
import MachineAndRoom from "./components/intro/login/dashboard/admin/machine_and_room/machine_and_room";
import Restaurants from "./components/intro/login/dashboard/admin/restaurants/restaurants";

export const context = {
    resolver: null,
    connector: null,
    // Basic settings, some come from windows.settings
    settings: {
        login: false,
        sections: [
            {
                name: 'control',
                title: 'Steuerung'
            },
            {
                name: 'content',
                title: 'Content'
            },
            {
                name: 'admin',
                title: 'Administration'
            },
        ],
        month_names: {
            0: {short: 'Jan', long: 'Januar'},
            1: {short: 'Feb', long: 'Februar'},
            2: {short: 'Mär', long: 'März'},
            3: {short: 'Apr', long: 'April'},
            4: {short: 'Mai', long: 'Mai'},
            5: {short: 'Jun', long: 'Juni'},
            6: {short: 'Jul', long: 'Juli'},
            7: {short: 'Aug', long: 'August'},
            8: {short: 'Sep', long: 'September'},
            9: {short: 'Okt', long: 'Oktober'},
            10: {short: 'Nov', long: 'November'},
            11: {short: 'Dez', long: 'Dezember'},
        },
        day_names: {
            0: {short: 'So', long: 'Sonntag'},
            1: {short: 'Mo', long: 'Montag'},
            2: {short: 'Di', long: 'Dienstag'},
            3: {short: 'Mi', long: 'Mittwoch'},
            4: {short: 'Do', long: 'Donnerstag'},
            5: {short: 'Fr', long: 'Freitag'},
            6: {short: 'Sa', long: 'Samstag'},
        }
    },
    // all modules
    modules: {
        // control
        'SetVolume': {
            component: SetVolume,
            title: 'Lautstärke',
            category: 'control',
        },
        'SignalSource': {
            component: SignalSource,
            title: 'Bildsignal',
            category: 'control',
        },
        'MachineWatch': {
            component: MachineWatch,
            title: 'Geräte-\nüberwachung',
            title_long: 'Geräteüberwachung',
            category: 'control',
        },
        'RunInstantContent': {
            component: RunInstantContent,
            title: 'Sofort-Content\nschalten',
            category: 'control',
        },
        // content
        'ContentPool': {
            component: ContentPoolHolder,
            title: 'Content Pool',
            category: 'content',
        },
        'ProgramPlanning': {
            component: ProgramPlanning,
            title: 'Programmplanung',
            category: 'content',
            settings: {special: false}
        },
        // 'WeekMenus': {
        //     component: WeekMenus,
        //     title: 'Wochenmenüs',
        //     category: 'content',
        // },
        'TenantAndRoom': {
            component: TenantAndRoom,
            title: 'Mieter + Räume',
            category: 'content',
        },
        'SetInstantContent': {
            component: ProgramPlanning,
            title: 'Sofort-Content\nzuweisen',
            category: 'content',
            settings: {special: true}
        },
        // admin
        'User': {
            component: User,
            title: 'Benutzer',
            category: 'admin',
        },
        'MachineAndRoom': {
            component: MachineAndRoom,
            title: 'Geräte +\nGebäude',
            category: 'admin',
        },
        'Restaurants': {
            component: Restaurants,
            title: 'Restaurants',
            category: 'admin',
        },
        // status
        status: [],
        events: [],
    },
    // ...
    f: {
        zfill: (num, digits) => `${
            [...Array(Math.max((digits ? digits : 2) - `${num}`.length, 0))].map(el => 0).join('')
        }${num}`,
        strip: str => str.trim()
    }
}