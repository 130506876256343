/* eslint-disable react-hooks/exhaustive-deps */
import './porperty_control.scss'
import React, {useEffect, useState} from "react";
import Form from "../form/form";
import {editItemFromListcontrol} from "../list_property_control/loader_list_property_control";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

function PropertyControl(props) {
    const [mode, setMode] = useState({save: false})
    const [content, setContent] = useState(null);
    const [form, setForm] = useState(null);
    const [formData, setFormData] = useState('??')
    const [errors, setErrors] = useState({})

    useEffect(() => setMode({save: false}), [props.item])

    useEffect(() => (props.addMode !== null) && setMode({save: true}), [props.addMode])

    useEffect(() => {
            setErrors({})
            load_content()
        },
        [props.rootline.join(','), props.tree[props.item]]
    )

    useEffect(
        () => {
            if (!(props.addMode === null)) {
                setForm(props.panes[props.addMode])
                const form_content = {}
                props.panes[props.addMode].fields.filter(el => el.name).map(
                    el => form_content[el.name] = ''
                )
                setContent(form_content)
                set_defaults()
            }
        },
        [props.addMode]
    )

    useEffect(() => {
        if (!(props.addMode === null)) {
            set_defaults()
        }
    }, [form])

    function load_content() {
        setForm(props.panes[props.rootline.length - 1])
        if (props.item) {
            const [, level, id] = [...props.item.split('_').map(el => parseInt(el))]
            props.return_content(props.panes[level].table, id, res => setContent(res.data))
        }
        return null
    }

    function set_defaults() {
        editItemFromListcontrol(
            props.g_state.connector,
            JSON.stringify({
                table: props.panes[props.addMode].table,
                parent: null,
                uid: null,
                action: 'get_foreign_key_lists'
            })
        ).then(
            res => {
                const act_relations = res.data
                const field_list = props.panes[props.addMode].fields

                if (content === null) {
                    return
                }

                const act_content = JSON.parse(JSON.stringify(content))
                Object.keys(act_content).forEach(
                    field => {
                        const found = field_list.find(el => el.name === field)
                        if (found) {
                            let values = {v: found.default}
                            if (act_relations[field]) {
                                values = {...values, t: 'select', vals: act_relations[field]}
                            }
                            act_content[field] = values
                        }
                    }
                )
                const [, level, id] = props.rootline.length
                    ? props.rootline.slice(-1)[0].split('_').map(el => parseInt(el, 10))
                    : [null, null, null]
                if (level !== null) {
                    const parent_link = props.panes[level].parent_fk_link.split('_').slice(0, -1).join('_')
                    if (act_content[parent_link]) {
                        act_content[parent_link].v = id
                    }
                }
                setContent(act_content)
            }
        )
    }

    return (props.item || (props.addMode !== null))
        ? (
            <div className={`PropertyControl fc ${mode.save ? '' : 'locked'} table_${
                (form && form.table) && form.table.split('.').join('_') 
            }`}>
                <header>
                    <div className={'title'}>{props.tree[props.item]
                        ? props.tree[props.item].name
                        : (
                            props.panes[props.addMode] ?
                                `${props.panes[props.addMode].title} hinzufügen`
                                : ''
                        )
                    }</div>
                </header>
                <ErrorBoundary>
                    <Form
                        save={mode.save}
                        content={content}
                        form={form}
                        setFormData={setFormData}
                        g_state={props.g_state}
                        addMode={props.addMode}
                        panes={props.panes}
                        errors={errors}
                        rootline={props.rootline}
                    />
                </ErrorBoundary>
                <div className={'actions'}>
                    {
                        (props.addMode === null && !mode.save) &&
                        <>
                            <div className={'duplicate button_property'}
                                 onClick={() => props.handle_item(
                                     props.item, 'duplicate'
                                 )}
                            >duplizeren
                            </div>
                            <div className={'remove button_property'}
                                 onClick={() => props.handle_item(
                                     props.item, 'remove',
                                 )}
                            >löschen
                            </div>
                        </>
                    }
                    {
                        (props.addMode !== null) &&
                        <div className={'cancel button_property'} onClick={() => props.setAddMode(null)}>abbrechen</div>
                    }
                    {
                        (mode.save && props.addMode === null) &&
                        <div className={'cancel button_property'} onClick={() => {
                            setMode({save: false})
                            load_content()
                        }}>abbrechen</div>
                    }
                    <div className={'edit button_property'}
                         onClick={
                             (ev) => {
                                 let error_list = {}
                                 if (mode.save || props.addMode !== null) {
                                     error_list = props.handle_item(
                                         props.item, 'update', formData
                                     )
                                     setErrors(error_list)
                                     Object.keys(error_list).length === 0 && setMode({save: false})
                                 }
                                 // NOTE only change mode if "external" add is not active
                                 Object.keys(error_list).length === 0 && props.addMode === null && setMode({save: !mode.save})
                                 Object.keys(error_list).length === 0 && props.setAddMode(null)
                             }
                         }
                    >{(mode.save || (props.addMode !== null)) ? 'speichern' : 'bearbeiten'}</div>
                </div>
            </div>
        )
        : <div className={'PropertyControl'}/>
}

export default PropertyControl;

// TODO reload after add
// TODO Disable by database output: duplicate, delete, change