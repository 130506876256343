import React, {useContext, useState, useRef} from "react";
import './login.scss'
import {AppContext} from '../../../App';

function alterPassword(connector, data = '""') {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'alterPassword',
                    class: '',
                    data: data,
                },
                res => resolve(res)
            )
        }
    )
}

function Login(props) {
    const g_state = useContext(AppContext)
    const [user, setUser] = useState(null)
    const [passwd, setPasswd] = useState(null)
    const [changePassword, setChangePassword] = useState(false)
    const [error, setError] = useState(null)
    const [wrongLogin, setWrongLogin] = useState(false)

    const [forgotPassword, setForgotPassword] = useState(false)

    const old_pw = useRef(null)
    const new_pw = useRef(null)
    const new_pw_again = useRef(null)

    const do_login = () => {
        if (g_state.settings.login) {
            g_state.connector.add_credentials(null, null, null)
            g_state.connector.add_personal_info(null, null, null)
            g_state.connector.run_action({action: 'logout'})
        } else {
            g_state.connector.add_credentials(user.value, passwd.value)
            g_state.connector.run_action(
                {action: 'login'},
                null,
                res => {
                    if (res.login_ok) {
                        g_state.connector.add_personal_info(
                            res.credentials.name, res.credentials.surname, res.credentials.rights
                        )
                    }
                    setWrongLogin(!res.login_ok)
                }
            )
        }
    }

    const do_password_change = ev => {
        setChangePassword(true)
        ev.stopPropagation()
    }

    const check_password = () => {
        // check if old is correct
        if (g_state.connector.password !== old_pw.current.value) {
            setError('Das alte Passwort stimmt nicht.')
        }
        // new pw are equal??
        else if (!(new_pw.current.value.trim() === new_pw_again.current.value.trim())) {
            setError('Das neue Passwort stimmt nicht überein.')
        } else if (new_pw.current.value.trim().length < 8) {
            setError('Das neue Passwort hat weniger als 8 Zeichen.')
        } else {
            alterPassword(
                g_state.connector,
                JSON.stringify({
                    new_passwd: new_pw.current.value.trim(),
                    user: g_state.connector.username,
                    action: 'change_pw',
                })
            ).then(
                res => {
                    g_state.connector.add_credentials(res.data.username, res.data.password)
                    setError(null)
                    setChangePassword(null)
                    props.setMenu(null)
                }
            )
        }
        old_pw.current.value = ''
    }

    return (
        <div className={`Login fr ${g_state.settings.login ? 'logout' : ''}`}>
            {
                !g_state.settings.login &&
                <div className={'is_inactive'}>
                    {
                        (!forgotPassword && wrongLogin) &&
                        <div className={'wrong_login'}>
                            {'Das Passwort oder\n Benutzername ist falsch.'}
                        </div>
                    }
                    {
                        !forgotPassword &&
                        <>
                            <input className={'username'}
                                // defaultValue={'wk@pmedien.com'}
                                   placeholder={'E-Mail Adresse'}
                                   ref={setUser} autoComplete="username"/>
                            <input className={'password'}
                                // defaultValue={'#P.medien8'}
                                   placeholder={'Passwort'}
                                   ref={setPasswd} type={'password'} autoComplete="current-password"/>
                            <div className={'login'} onClick={() => do_login()}/>
                            <div className={'forgot_password'} onClick={() => setForgotPassword(true)}>
                                <span>Passwort vergessen</span>
                            </div>
                        </>
                    }
                    {
                        forgotPassword &&
                        <>
                            <div className={'forget_description'}>
                                {'Nach der Bestätigung, wird eine Email an die \nangegebene Adresse mit neuem Passwort gesendet.'}
                            </div>
                            <input
                                className={'forget_username'}
                                placeholder={'E-Mail-Adresse'}
                                ref={new_pw}
                            />
                            <div
                                className={'forget_send'}
                                onClick={
                                    () =>
                                        alterPassword(
                                            g_state.connector,
                                            JSON.stringify(
                                                {
                                                    user: new_pw.current.value,
                                                    action: 'new_passwd',
                                                }
                                            ),
                                        ).then(
                                            res => {
                                                console.log(res)
                                                setForgotPassword(false)
                                                setWrongLogin(false)
                                            }
                                        )
                                }
                            />
                            <div
                                className={'forget_close'} onClick={() => setForgotPassword(false)}
                            />
                        </>
                    }
                </div>
            }
            {
                g_state.settings.login &&
                <div
                    className={`is_active ${props.menu === 'login' ? 'activated' : ''}`}
                    onClick={() => props.setMenu(p => p === 'login' ? null : 'login')}
                >
                    {
                        props.menu === 'login' &&
                        <div className={'options'}>
                            <div className={'first'}>{g_state.connector.real_name}</div>
                            <div className={'last'}>{g_state.connector.real_surname}</div>
                            <div className={'change_pw'} onClick={ev => do_password_change(ev)}>
                                Passwort ändern
                            </div>
                            <div className={'logout'} onClick={() => do_login()}>abmelden</div>
                        </div>
                    }
                    {
                        changePassword &&
                        <div className={'change_password'} onClick={ev => ev.stopPropagation()}>
                            <div className={'change_form'}>
                                <div
                                    className={'close'}
                                    onClick={() => {
                                        props.setMenu(null)
                                        setChangePassword(false)
                                        setError(null)
                                    }}
                                />
                                <div className={'title'}>Passwort ändern</div>
                                <div className={'description'}>Altes Passwort</div>
                                <input
                                    name={'old_pw'} className={'grey_input password'} type={'password'}
                                    ref={old_pw}
                                />
                                <div className={'description'}>Neues Passwort</div>
                                <input
                                    name={'new_pw'} className={'grey_input password'} type={'password'}
                                    ref={new_pw}
                                />
                                <div className={'description small'}>Das Passwort muss mindestens 8 Zeichen beinhalten.
                                    Verwenden Sie kein Passwort, das Sie bereits für einen Sparkassen-Login benutzen.
                                </div>
                                <div className={'description'}>Neues Passwort wiederholen</div>
                                <input
                                    ref={new_pw_again} name={'new_pw_again'} className={'grey_input password'}
                                    type={'password'}
                                />
                                {
                                    error &&
                                    <div className={'check_error'}>
                                        <div className={'error_sign'}/>
                                        <div>{error}</div>
                                    </div>
                                }
                                <div className={'button_white'} onClick={() => check_password()}>
                                    speichern
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>

    );
}

export default Login;