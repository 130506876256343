function make_file_upload(ev, type) {
    let file = null
    switch (type) {
        case 'drag':
            file = ev.dataTransfer.files[0]
            break
        case 'upload':
            file = ev.target.files[0]
            break
        default:
            break
    }
    return file
}

export {make_file_upload}