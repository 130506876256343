import React, {useContext, useEffect, useState} from 'react';
import './dashboard.scss'
import Login from "../login";
import {AppContext} from "../../../../App";
import Help from "./help/Help";
import State from "./state/state";

function Dashboard(props) {
    const g_state = useContext(AppContext)
    const [LoadedModule, setLoadedModule] = useState(null)
    const [moduleName, setModuleName] = useState('Home')
    const [moduleSettings, setModuleSettings] = useState(null)
    const [bankett, setBankett] = useState([])

    const [menu, setMenu] = useState(null)

    function handle_click(ev, name_mod) {

        const module_target = name_mod ? name_mod : ev.target.closest('[data-module]')
        if (module_target) {
            const m_name = name_mod ? name_mod : module_target.dataset.module
            setModuleName(
                g_state.modules[m_name].title_long
                    ? g_state.modules[m_name].title_long
                    : g_state.modules[m_name].title)
            setLoadedModule(() => g_state.modules[m_name].component
            )
            setModuleSettings(g_state.modules[m_name].settings)
        }
    }

    function update_content() {
        const req = new XMLHttpRequest()
        const url = `${g_state.settings.server_protocol}://${g_state.settings.server_hostname}:${g_state.settings.server_port}`
        req.open('GET', `${url}/update-content/`)
        req.send()
    }

    function setModuleByName(name) {
        handle_click(null, name)
    }

    useEffect(
        () => {
            const req = new XMLHttpRequest()
            const url = `${g_state.settings.server_protocol}://${g_state.settings.server_hostname}:${g_state.settings.server_port}`

            req.addEventListener('load', ev => {
                try {
                    const my_json = JSON.parse(ev.target.response)
                    setBankett(my_json)
                } catch (e) {
                    console.log(e)
                }
            })
            console.log(`${url}/returnBankett/`)
            req.open('GET', `${url}/returnBankett/`)
            req.send()
        },
        []
    )

    return (
        <div
            className={'Dashboard'}
            onClick={(ev) => handle_click(ev)}
        >
            <div className={'section_top'}>
                <div className={'top_left'}>
                    {
                        g_state.settings.login &&
                        <>
                            <div
                                className={`homelink fr ${props.errors.length ? 'with_error' : 'without_error'}`}
                                onClick={() => {
                                    setLoadedModule(null)
                                    setModuleName('Home')
                                }}
                            />
                            <div
                                className={'update fr'} onClick={
                                (ev) => {
                                    ev.currentTarget.style.transition = '.5s'
                                    ev.currentTarget.classList.add('update_turn')

                                    update_content()
                                }
                            }
                                onTransitionEnd={ev => {
                                    ev.currentTarget.style.transition = '0s'
                                    ev.currentTarget.classList.remove('update_turn')
                                }}
                            />
                            <div className={'f_head f_white fr'}>{moduleName}</div>
                        </>
                    }
                </div>

                <div className={'top_right'}>
                    <div className={'export_holder'}/>
                    <div className={'Search fr'}/>
                    {g_state.settings.login && <Help menu={menu} setMenu={setMenu}/>}
                    <Login menu={menu} setMenu={setMenu}/>
                </div>
            </div>
            <div className={'section_body hide_scrollbar'}>
                {
                    (g_state.settings.login && g_state.connector?.rights)
                        ? <>
                            {
                                LoadedModule &&
                                <div className={'loaded_module'}>
                                    <LoadedModule
                                        {...props} moduleSettings={moduleSettings} setModuleByName={setModuleByName}
                                    />
                                </div>
                            }
                            {
                                g_state.settings.sections.map(
                                    (section, i) => {
                                        const active_modules = Object.keys(g_state.modules)
                                            .filter(el => g_state.modules[el].category === section.name)
                                            .filter(el => g_state.connector.rights.indexOf(String(el)) >= 0)
                                        return (
                                            active_modules.length > 0
                                                ? <div className={`section_${section.name}`} key={i}>
                                                    <header>{section.title}</header>
                                                    <div className={'modules'}>
                                                        {
                                                            active_modules
                                                                .map(
                                                                    (el, i) => {
                                                                        const obj = g_state.modules[el]
                                                                        return (
                                                                            <div
                                                                                key={i} data-module={el}
                                                                                className={`dash_image ${el}`}
                                                                            >
                                                                                <div className={'dash_title'}>
                                                                                    {obj.title}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                )
                                                        }
                                                    </div>
                                                </div>
                                                : null
                                        )
                                    }
                                )
                            }
                            <div className={'section_status'}>
                                <header>Betriebsstatus</header>
                                <div className={'modules'}>
                                    <State {...props}/>
                                </div>
                            </div>
                            <div className={'section_events'}>
                                <header>
                                    Veranstaltungen
                                </header>
                                <div className={'module_bankett'}>
                                    {
                                        bankett.map(
                                            (el, i) =>
                                                <div className={'event_entry'} key={i}>
                                                    <div className={'profi title_event'}>{el.name}</div>
                                                    {
                                                        el.room &&
                                                        <div className={'profi room'}>
                                                            Raum: {el.room} ({el.room_id})
                                                        </div>
                                                    }
                                                    <div className={'profi time'}>
                                                        {
                                                            new Intl.DateTimeFormat(
                                                                'de-DE',
                                                                {
                                                                    year: 'numeric',
                                                                    month: 'numeric',
                                                                    day: 'numeric',
                                                                    hour: 'numeric',
                                                                    minute: 'numeric',
                                                                    // second: 'numeric',
                                                                    // hour12: false,
                                                                    // timeZone: 'Europe/Berlin'
                                                                }
                                                            )
                                                                .format(
                                                                    new Date(el.start)
                                                                )
                                                        } - {
                                                        new Intl.DateTimeFormat(
                                                            'de-DE',
                                                            {
                                                                // year: 'numeric',
                                                                // month: 'numeric',
                                                                // day: 'numeric',
                                                                hour: 'numeric',
                                                                minute: 'numeric',
                                                                // second: 'numeric',
                                                                // hour12: false,
                                                                // timeZone: 'Europe/Berlin'
                                                            }
                                                        )
                                                            .format(
                                                                new Date(el.end)
                                                            )
                                                    }
                                                    </div>
                                                    <div
                                                        className={'profi descr_event'}
                                                        dangerouslySetInnerHTML={{__html: el.description}}
                                                    />

                                                </div>
                                        )
                                    }
                                </div>
                            </div>
                        </>
                        : g_state.settings.login && <div className={'loading'}>Loading...</div>
                }
                {
                    !g_state.settings.login &&
                    <div className={'intro_image'}/>
                }
            </div>
        </div>
    );
}

export default Dashboard;