import React from 'react'

import './user.scss'
import ListPropertyControl from "../../../../../assets/list_property_control/list_property_control";

function User(props) {
    return (
        <div className={'User'}>
            <ListPropertyControl {...props} model={'User'}/>
        </div>
    );
}

User.defaultProps = {}

export default User;