/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useContext, useState} from 'react';
import './run_instant_content.scss'
import {AppContext} from "../../../../../../App";

function edit_show_categories(connector, data = {action: 'list_instant_content'}) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'edit_show_categories',
                    class: '',
                    data: JSON.stringify(data),
                },
                res => resolve(res)
            )
        }
    )
}

function RunInstantContent(props) {
    const g_state = useContext(AppContext)
    const [shows, setShows] = useState([])
    const [confirm, setConfirm] = useState(null)

    useEffect(() => {
        edit_show_categories(g_state.connector)
            .then(
                res => {
                    console.log(res.data)
                    setShows(res.data)
                }
            )
    }, [])

    function do_confirrm() {
        const act_modes = shows.map(
            el => (
                {
                    uid: el.uid,
                    active: confirm === el.uid ? !el.active : el.active
                }
            )
        ).filter(el => el.active).map(el => el.uid).join(',')
        edit_show_categories(g_state.connector, {action: 'set_instant_content', uid: act_modes})
            .then(
                res => {
                    setShows(res.data)
                    setConfirm(null)
                }
            )
    }

    return (
        <div className={'RunInstantContent'}>
            <div className={'shows_instant'}>
                {
                    shows.map(
                        (el, i) =>
                            <div className={'show fr'} key={i}>
                                <div className={'title'}>{el.name}</div>
                                <div
                                    className={`toggle_button state ${el.active ? 'active' : ''}`}
                                    onClick={() => setConfirm(el.uid)}
                                >
                                    <div className={'knob'}/>
                                </div>
                                <div className={'confirm'}>
                                    <div
                                        className={'inner_confirm fr'}
                                        style={{display: confirm === el.uid ? 'flex' : ''}}
                                    >
                                        <div className={'title'}>
                                            {el.active ? 'Deaktivierung' : 'Aktivierung'} bestätigen
                                        </div>
                                        <div className={'yes'} onClick={() => do_confirrm()}/>
                                        <div className={'cancel'} onClick={() => setConfirm(null)}/>
                                    </div>
                                </div>
                            </div>
                    )
                }
            </div>
        </div>
    );
}

export default RunInstantContent;