/**
 * Handles the communication between the control interface and the server
 * This class also holds the password information which authenticate every request against the server
 *
 * @param {String} server            e.g. http://localhost:8080
 * @param {String} username
 * @param {String} password
 * @param {Function} callback          gets the response of the server as a json object
 */
class Connector {
    constructor(server, callback, username, password, rights) {
        const url_string = `${window.settings.server_protocol}://${window.settings.server_hostname}:${window.settings.server_port}`
        this.server = server ? server : url_string  // 'http://localhost:8080'
        this.callback = callback
        this.username = username// ? username : 'wk@pmedien.com'
        this.password = password// ? password : '#P.medien8'
        this.rights = rights // 
    }

    /**
     * convenient function for adding credentials to the connector
     * can also be done via setting the instance properties
     * @param {String} username
     * @param {String} password
     */
    add_credentials = (username, password) => {
        this.username = username
        this.password = password
    }

    /**
     * convenient function for adding world readable user information
     * @param {String} real_name
     * @param {String} real_surname
     * @param {Array} rights
     */
    add_personal_info(real_name, real_surname, rights) {
        this.real_name = real_name
        this.real_surname = real_surname
        this.rights = rights
    }

    /**
     * runs the actual server request
     * @param {Object} action               object holding the payload
     * @param {Function} callback           different callback from class initialization
     * @param {Function} helper_callback    other callback if needed
     * @return {Object}                     Response as object with initial action object
     */
    run_action = (action, callback, helper_callback) => {
        action = action ? action : {}
        callback = callback ? callback : this.callback
        const conn = new XMLHttpRequest()

        const form_data = new FormData();
        form_data.append('user', this.username)
        form_data.append('passwd', this.password)
        Object.keys(action).map(
            el => {
                form_data.append(el, action[el])
                // console.log(el, action[el])
                return null
            }
        )

        conn.open('POST', `${this.server}/general/`)
        conn.onload = (ev) => {
            if (this.callback) {
                let data
                try {
                    data = JSON.parse(ev.currentTarget.response)
                } catch (e) {
                    data = {error: true, description: e}
                }
                data['initial_call'] = action
                callback(data)
                if (helper_callback) {
                    helper_callback(data)
                }
            }
        }
        conn.send(form_data)
    }
}

export default Connector;