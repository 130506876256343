import React, {useState} from "react";
import {make_file_upload} from "./file_upload";
import './medium_info.scss'
import {PdfSelector} from "./content_editor";


function MediumInfo(props) {
    // has circular reference
    const [key, setKey] = useState(1)
    const my_content = props.localContent ? JSON.parse(JSON.stringify(props.localContent)) : null
    const my_part = my_content ? my_content.parts[props.mediumInfo.part_name] : null

    const [pdf, setPdf] = useState(null)
    const [, setPdfInfo] = useState(null)

    function change_input(ev) {
        my_content.parts[props.mediumInfo.part_name][ev.currentTarget.name] = ev.currentTarget.value
        props.setLocalContent({...my_content})
        props.setDataChanged(true)
    }

    function close_media_altering(ev, type) {
        props.insert_medium(ev, type)
        setTimeout(() => props.setMediumInfo(null), 100)
    }

    function select_pdf(my_file) {
        // insert_medium(my_file, pdfInfo, true)
        props.localContent
            ? props.insert_medium(
                my_file,
                props.mediumInfo,
                true
            )
            : console.log('NOT IMPLEMENTED FOR LISTCONTROL !!')
        setTimeout(() => setKey(p => p + 1), 10)

        setPdfInfo(null)
        setPdf(null)
    }

    function close_pdf() {
        setPdf(null)
        setPdfInfo(null)
    }

    return (
        <div className={'MediumInfo'} key={key}>
            {
                pdf &&
                <PdfSelector pdf={pdf} close={close_pdf} select_pdf={select_pdf}/>
            }
            <div className={'inner_medium_info fc'}>
                <div onClick={() => props.setMediumInfo(null)} className={'closer'}/>
                <div className={'title big'}>Dateien hochladen</div>

                <div className={'file-actions fr'}>
                    <div className={'file-left fc'}>
                        <input type="file" id="uploadFile" style={{display: 'none'}}
                               onChange={
                                   ev => {
                                       // localContent "undefined" means listcontrol
                                       if (ev.target.files.length === 1) {
                                           const file = ev.target.files[0]
                                           if (file.name.split('.').slice(-1)[0].toLowerCase() === 'pdf') {
                                               setPdfInfo(props.mediumInfo)
                                               setPdf(file)
                                               return;
                                           }
                                       }

                                       props.localContent
                                           ? props.insert_medium(
                                               make_file_upload(ev, 'upload'),
                                               {...props.mediumInfo}
                                           )
                                           : close_media_altering(ev, 'upload')
                                       setTimeout(() => setKey(p => p + 1), 10)
                                   }
                               }
                        />
                        <div
                            onClick={() => document.getElementById('uploadFile').click()}
                            className={'file-button bo fc'}
                        >
                            Datei auswählen
                        </div>
                    </div>
                    <div className={'file-middle title'}>oder</div>
                    <div
                        onDragOver={ev => ev.preventDefault()}
                        onDragEnd={ev => ev.preventDefault()}
                        onDrop={ev => {
                            ev.preventDefault()

                            if (ev.dataTransfer.files.length === 1) {
                                const file = ev.dataTransfer.files[0]
                                if (file.name.split('.').slice(-1)[0].toLowerCase() === 'pdf') {
                                    setPdfInfo(props.mediumInfo)
                                    setPdf(file)
                                    console.log('ALL set')
                                    return;
                                }
                            }

                            // localContent "undefined" means listcontrol
                            props.localContent
                                ? props.insert_medium(
                                    make_file_upload(ev, 'drag'),
                                    {...props.mediumInfo}
                                )
                                : close_media_altering(ev, 'drag')
                            setTimeout(() => setKey(p => p + 1), 10)
                        }}
                        className={'file-right bo fc'}
                    >
                        Datei hier hinziehen
                    </div>
                </div>
                <div className={'file-list'}>
                    {
                        props.localContent &&
                        <div className={'file-item fr'}>
                            <div className={'group-filenname'}>
                                <div className={'title fr'}>Geladene Datei</div>
                                <div className={'filename'}>
                                    <div className={'text'}
                                         dangerouslySetInnerHTML={{__html: my_part?.name || '&nbsp;'}}/>
                                    <div className={'closer'} onClick={
                                        () => {
                                            props.insert_medium(null, {...props.mediumInfo})
                                            props.setMediumInfo(null)
                                        }
                                    }/>
                                </div>
                            </div>
                            <>
                                <div className={'group-validity'} style={{display: ''}}>
                                    {
                                        false &&
                                        <>
                                            <div className={'title fr'}>Gültigkeit<sup>*</sup></div>
                                            <div className={'validity fr'}>
                                                <input
                                                    name={'valid_from'} type={'date'} className={'bo'}
                                                    value={my_part?.valid_from}
                                                    data-key="valid_from" onChange={ev => {
                                                    change_input(ev)
                                                    props.setValidityChanged(true)
                                                }}
                                                />
                                                <div className={'minus'}>-</div>
                                                <input
                                                    name={'valid_to'} type={'date'} className={'bo'}
                                                    value={my_part?.valid_to}
                                                    data-key="valid_to" onChange={ev => {
                                                    change_input(ev)
                                                    props.setValidityChanged(true)
                                                }}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className={'group-copyright'}>
                                    <div className={'title fr'}>Copyright<sup>*</sup></div>
                                    <input
                                        name={'copyright'} type={'text'} className={'bo'} value={my_part?.copyright}
                                        data-key="imp" onChange={ev => change_input(ev)}
                                    />
                                </div>
                            </>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export {MediumInfo};