/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';

import './search_program_planning.scss'
import {find_contents} from "../../intro/login/dashboard/content/program_planning/loader_program_planning";

function load_categories(connector) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'load_search_info',
                    class: '',
                    data: {}
                },
                res => resolve(res.data)
            )
        }
    )
}

function return_tags(connector, value, prev_values) {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'return_tags',
                    class: '',
                    data: JSON.stringify({value, prev_values})
                },
                res => resolve(res.data)
            )
        }
    )
}

function SearchProgramPlanning(props) {
    const [active, setActive] = useState(false)
    const [filters, setFilters] = useState({tag: {}, status: null, category: null, period: null})
    const [unfoldedFilter, setUnfoldedFilter] = useState({
        tag: true, status: false, category: false, period: false
    })
    const [filterBase, setFilterBase] = useState({})
    const [year, setYear] = useState(new Date().getFullYear())
    const [suggestion, setSuggestion] = useState([])

    const [visualFilter, setVisualFilter] = useState(null)

    const my_input = useRef()

    useEffect(
        () => {
            load_categories(props.g_state.connector)
                .then(
                    res => setFilterBase(res)
                )
        }, []
    )

    function set_filter(ev, category, uid) {
        ev.stopPropagation()
        if (category === 'tag') {
            if (uid.length > 1) {
                return_tags(props.g_state.connector, uid, Object.keys(filters.tag))
                    .then(
                        res => {
                            setSuggestion([...res.found, ...res.content_found])
                        }
                    )
            } else {
                setSuggestion([])
            }
        } else if (category === 'tag_selection') {
            setFilters(p => ({...p, tag: {...filters.tag, [uid.id]: uid.name + '--||--' + uid.title}}))
            setSuggestion([])
            my_input.current.value = ''
        } else if (category === 'period') {
            if (uid.year) {
                if (filters.period && (uid.year === filters.period.year)) {
                    setFilters(p => ({...p, period: null}))
                } else {
                    setFilters(p => ({...p, period: {year: uid.year}}))
                }
            } else if (uid.month !== undefined) {
                if (filters.period && (uid.month === filters.period.month)) {
                    setFilters(p => ({...p, period: {...filters.period, month: null}}))
                } else {
                    setFilters(p => ({...p, period: {...filters.period, month: uid.month}}))
                }
            }
        } else {
            if (category === 'status' && uid !== "2") {
                setFilters(p => ({...p, period: null}))
            }
            if (filters[category] === uid) {
                setFilters(p => ({...p, [category]: null}))
            } else {
                setFilters(p => ({...p, [category]: uid}))
            }
        }
    }

    function unset_filter(ev, category, uid, all) {
        ev.stopPropagation()
        if (all === true) {
            setFilters({tag: {}, status: null, category: null, period: null})
        } else {
            if (category === 'tag') {
                const my_tags = filters.tag
                delete my_tags[uid]
                setFilters(p => ({...p, [category]: my_tags}))
            } else {
                if (category === 'status') {
                    setFilters(p => ({...p, period: null}))
                }
                setFilters(p => ({...p, [category]: null}))
            }
        }
    }

    function set_year(ev, dir) {
        ev.stopPropagation()
        setYear(p => p + dir)
    }

    useEffect(() => {
        if (
            Object.keys(filters.tag).length === 0 &&
            filters.status === null &&
            filters.category === null &&
            filters.period === null
        ) {
            setVisualFilter(null)
        } else {
            find_contents(props.g_state.connector, JSON.stringify(filters))
                .then(res => setVisualFilter(res.data))
        }
    }, [filters])

    function return_filter_names() {
        const f0 = Object.keys(filters.tag).map(
            el => filters.tag[el].split('--||--')[0]
        )
        const f1 = (filters.status && filterBase.status) ? [filterBase.status[filters.status].name] : []
        const f2 = filters.category ? [filterBase.category[filters.category].name] : []
        return [...f0, ...f1, ...f2].join(', ')
    }

    return (
        createPortal(
            <div className={'SearchProgramPlanning'}>
                {
                    !active &&
                    <div className={'collapsed fr'} onClick={() => setActive(true)}>
                        <div className={'results fr'}>
                            <div className={'names_selected_filters'}>
                                {visualFilter && return_filter_names()}
                            </div>
                        </div>
                    </div>
                }
                {
                    active &&
                    <div className={'unfolded'}
                         onClick={ev => {
                             if (ev.target.classList.contains('close_filter')) {
                                 setActive(false)
                                 setSuggestion([])
                             }
                         }}>
                        <div className={'holder_search fc'}>
                            <div className={'input_search fc'}>
                                <input
                                    ref={my_input}
                                    onChange={ev => set_filter(ev, 'tag', ev.currentTarget.value)}
                                />
                                <div className={'arrow close_filter'}/>
                                <div className={'suggestions'}>
                                    {
                                        suggestion.map(
                                            (el, i) =>
                                                <div
                                                    key={i} className={'suggestion'}
                                                    onClick={ev => set_filter(ev, 'tag_selection', el)}
                                                >
                                                    {el.name}
                                                </div>
                                        )
                                    }
                                </div>
                                <div className={'spacer'}/>
                            </div>
                            {/*delete all filters*/}
                            <section className={'reset_filters'}>
                                <div
                                    onClick={ev => unset_filter(ev, null, null, true)}
                                >
                                    alle Filter löschen
                                </div>
                            </section>
                            {/*set tags*/}
                            <section>
                                <div className={'title fc'}>
                                    <div className={'head fr'}>
                                        <div
                                            className={'left'}
                                            onClick={ev => {
                                                ev.stopPropagation()
                                                setUnfoldedFilter(p => ({...p, tag: !p.tag}))
                                            }}
                                        >
                                            Tag Suche
                                        </div>
                                        <div className={'right fr'}>
                                            <div
                                                className={`filter_sign ${Object.keys(filters.tag).length === 0 && 'invisible'}`}
                                                onClick={ev => setFilters(p => ({...p, tag: {}}))}
                                            />
                                            <div className={`arrow ${unfoldedFilter.tag ? 'not_collapsed' : ''}`}
                                                 onClick={ev => {
                                                     ev.stopPropagation()
                                                     setUnfoldedFilter(p => ({...p, tag: !p.tag}))
                                                 }}
                                            />
                                        </div>
                                    </div>
                                    <div className={'item_list fr'} style={{display: unfoldedFilter.tag ? '' : 'none'}}>
                                        {
                                            filters?.tag && Object.keys(filters.tag).map(
                                                (el, i) => {
                                                    const obj = filters.tag[el]
                                                    return (
                                                        <div
                                                            className={'item fr'} key={i}
                                                        >
                                                            {obj.split('--||--')[0]}
                                                            <div
                                                                onClick={(ev) => unset_filter(ev, 'tag', el)}
                                                                className={'delete_tag'}/>
                                                        </div>
                                                    )
                                                }
                                            )
                                        }
                                    </div>
                                </div>
                            </section>
                            {/*set status*/}
                            {
                                !props.hide_status &&
                                <section>
                                    <div className={'title fc'}>
                                        <div className={'head fr'}>
                                            <div
                                                className={'left'}
                                                onClick={ev => {
                                                    ev.stopPropagation()
                                                    setUnfoldedFilter(p => ({...p, status: !p.status}))
                                                }}
                                            >
                                                Status
                                            </div>
                                            <div className={'right fr'}>
                                                <div
                                                    className={`filter_sign ${filters.status === null && 'invisible'}`}
                                                    onClick={ev => unset_filter(ev, 'status')}
                                                />
                                                <div className={`arrow ${unfoldedFilter.status ? 'not_collapsed' : ''}`}
                                                     onClick={ev => {
                                                         ev.stopPropagation()
                                                         setUnfoldedFilter(p => ({...p, status: !p.status}))
                                                     }}
                                                />
                                            </div>
                                        </div>
                                        <div className={'item_list fr'}
                                             style={{display: unfoldedFilter.status ? '' : 'none'}}>
                                            {
                                                filterBase?.status && Object.keys(filterBase.status).map(
                                                    (el, i) => {
                                                        const obj = filterBase.status[el]
                                                        return (
                                                            <div
                                                                className={`item status ${el === filters.status ? 'active' : ''}`}
                                                                key={i}
                                                                onClick={ev => set_filter(ev, 'status', el)}
                                                            >
                                                                {obj.name}
                                                            </div>
                                                        )
                                                    }
                                                )
                                            }
                                        </div>
                                    </div>
                                </section>
                            }
                            {/*set category*/}
                            <section>
                                <div className={'title fc'}>
                                    <div className={'head fr'}>
                                        <div
                                            className={'left'}
                                            onClick={ev => {
                                                ev.stopPropagation()
                                                setUnfoldedFilter(p => ({...p, category: !p.category}))
                                            }}
                                        >
                                            Kategorie
                                        </div>
                                        <div className={'right fr'}>
                                            <div
                                                className={`filter_sign ${filters.category === null && 'invisible'}`}
                                                onClick={ev => unset_filter(ev, 'category')}
                                            />
                                            <div className={`arrow ${unfoldedFilter.category ? 'not_collapsed' : ''}`}
                                                 onClick={ev => {
                                                     ev.stopPropagation()
                                                     setUnfoldedFilter(p => ({...p, category: !p.category}))
                                                 }}
                                            />
                                        </div>
                                    </div>
                                    <div className={'item_list fr'}
                                         style={{display: unfoldedFilter.category ? '' : 'none'}}>
                                        {
                                            filterBase?.category && Object.keys(filterBase.category).map(
                                                (el, i) => {
                                                    const obj = filterBase.category[el]
                                                    const is_active = el === filters.category
                                                    return (
                                                        <div
                                                            className={'item'}
                                                            key={i}
                                                            style={{
                                                                borderColor: obj.color,
                                                                backgroundColor: is_active ? '' : obj.color,
                                                            }}
                                                            onClick={ev => set_filter(ev, 'category', el)}
                                                        >
                                                            {obj.name}
                                                        </div>
                                                    )
                                                }
                                            )
                                        }
                                    </div>
                                </div>
                            </section>
                            {/*set period*/}
                            <section className={`period ${filters.status !== "2" ? 'inactive' : ''}`}>
                                <div className={'title fc'}>
                                    <div className={'head fr'}>
                                        <div
                                            className={'left'}
                                            onClick={ev => {
                                                ev.stopPropagation()
                                                setUnfoldedFilter(p => ({...p, period: !p.period}))
                                            }}
                                        >
                                            Zeitraum
                                        </div>
                                        <div className={'right fr'}>
                                            <div
                                                className={`filter_sign ${filters.period === null && 'invisible'}`}
                                                onClick={ev => unset_filter(ev, 'period')}
                                            />
                                            <div className={`arrow ${unfoldedFilter.period ? 'not_collapsed' : ''}`}
                                                 onClick={ev => {
                                                     ev.stopPropagation()
                                                     setUnfoldedFilter(p => ({...p, period: !p.period}))
                                                 }}
                                            />
                                        </div>
                                    </div>
                                    <div className={'item_list fr'}
                                         style={{display: unfoldedFilter.period ? '' : 'none'}}>
                                        <div className={'years fr'}>
                                            {
                                                [...Array(3)].map(
                                                    (el, i) =>
                                                        <div
                                                            key={i}
                                                            className={`year ${
                                                                filters.period?.year === (year + (i - 1)) ? 'active' : ''
                                                            }`}
                                                            onClick={
                                                                ev =>
                                                                    set_filter(ev, 'period', {year: year + (i - 1)})}
                                                        >
                                                            {year + (i - 1)}
                                                        </div>
                                                )
                                            }
                                            <div className={'prev'} onClick={ev => set_year(ev, -1)}>
                                                &lt;
                                            </div>
                                            <div className={'next'} onClick={ev => set_year(ev, 1)}>
                                                &gt;
                                            </div>
                                        </div>
                                        {

                                            <div className={`months fr ${
                                                (filters.period === null && filters.status === "2")
                                                    ? 'inactive' : ''}`}>
                                                {
                                                    [...Array(12)].map(
                                                        (el, i) =>
                                                            <div
                                                                key={i} className={`month ${
                                                                filters.period?.month === (i + 1) ? 'active' : ''
                                                            }`}
                                                                onClick={ev => set_filter(ev, 'period', {month: i + 1})}
                                                            >{
                                                                props.g_state.settings.month_names[i].short
                                                            }</div>
                                                    )
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </section>
                        </div>

                    </div>
                }
                {
                    visualFilter !== null &&
                    <style>
                        {`.categories div[class*="_content_id_"] { opacity: .1;}`}
                        {`.ContentPool div[class*="_content_id_"] { display: none;}`}
                        {`.ContentPoolHolder div[class*="_content_id_"] { display: none;}`}
                        {
                            visualFilter.map(
                                el => `
                                            .categories div._content_id_${el} { opacity: 1;}
                                            .ContentPoolHolder div._content_id_${el},
                                            .ContentPool div._content_id_${el} { display: ${props.content_pool ? 'flex' : 'unset'};}
                                        `
                            )
                        }
                    </style>
                }
            </div>
            , document.querySelector(props.search ? props.search : '.Search')
        )
    )
}

export default SearchProgramPlanning;