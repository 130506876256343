import React, {useContext, useEffect, useState} from 'react';
import './set_volume.scss'
import {AppContext} from "../../../../../../App";


function Regler(props) {
    const [vol, setVol] = useState(props.item.volume)
    const [drag, setDrag] = useState(false)


    function drag_knob(ev) {
        if (ev.target.classList.contains('speaker')) {
            return;
        }
        if (props.item.silenced) {
            return
        }
        const bb = ev.currentTarget.querySelector('.track').getBoundingClientRect()
        switch (ev.type) {
            case 'mousedown':
            case 'touchstart':
                setDrag(true)
                break
            case 'touchmove':
            case 'mousemove':
                if (drag) {
                    const perc = (
                        ev.type === 'mousemove'
                            ? ev.clientX - bb.left
                            : ev.touches[0].clientX - bb.left
                    ) / bb.width * 100
                    setVol(Math.max(Math.min(100, perc), 0))
                }
                break
            case 'mouseup':
            case 'touchend':
            case 'mouseout':
                if (drag) {
                    setDrag(false)
                    props.set_volume(props.item.id, vol)
                }
                break
            default:
                break
        }
    }

    return (
        <div
            className={'Regler'}
            onMouseDown={ev => drag_knob(ev)} onMouseMove={ev => drag_knob(ev)} onMouseUp={ev => drag_knob(ev)}
            onMouseOut={ev => drag_knob(ev)}
            onTouchStart={ev => drag_knob(ev)} onTouchMove={ev => drag_knob(ev)} onTouchEnd={ev => drag_knob(ev)}
        >
            <div
                className={`speaker ${props.item.silenced ? 'silenced' : ''}`} onClick={
                (ev) => {
                    props.toggle_silenced(props.item.id, props.item.silenced)
                    ev.stopPropagation()
                }
            }
            />
            <div
                className={`track ${props.item.silenced ? 'silenced' : ''}`} data-uid={props.item.id}

            >
                <div className={'bg'}/>
                <div className={'progress'} style={{width: `${vol}%`}}/>
                <div className={'knob'} style={{marginLeft: `${vol}%`}}/>
            </div>
            <div className={'percent'}>{Math.floor(vol)}%</div>
        </div>
    )
}

function SetVolume() {
    const g_state = useContext(AppContext)
    const [stations, setStations] = useState([])

    function toggle_silenced(uid, state) {
        g_state.connector.run_action(
            {
                action: 'update',
                class: 'administration.models.MediaStation',
                crud: true,
                key: JSON.stringify({id: uid}),
                data: JSON.stringify({silenced: !state})
            },
            initialize
        )
    }

    function set_volume(uid, vol) {
        g_state.connector.run_action(
            {
                action: 'update',
                class: 'administration.models.MediaStation',
                crud: true,
                key: JSON.stringify({id: uid}),
                data: JSON.stringify({audio_volume: vol})
            },
        )
    }

    function initialize() {
        g_state.connector.run_action(
            {action: 'get_all_volumes'},
            result => setStations(result.data)
        )
    }

    useEffect(initialize, [g_state.connector])

    return (
        <div className={'SetVolume'}>
            <div className={'volumes'}>
                {
                    stations.map(
                        (el, i) =>
                            <div className={'volume'} key={i}>
                                <div className={'title'}>{el.name}</div>
                                <Regler item={el} toggle_silenced={toggle_silenced} set_volume={set_volume}/>
                                <div className={'spacer'}/>
                            </div>
                    )
                }
            </div>
        </div>
    );
}

export default SetVolume;