import React, {Component} from 'react';
import './state.scss'


class State extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <>
                {
                    this.props.errors.length
                        ? this.props.errors.map(
                            (el, i) =>
                                <div className={
                                    {
                                        '1': 'info',
                                        '2': 'warning',
                                        '3': 'error',
                                    }[el.level]
                                } key={i}>
                                    <div className={'symbol'}/>
                                    <div className={'message'}>
                                        {{1: 'Rechner', 2: 'Projektor', 3: 'Monitor'}[el.m_typ_id]} in {el.ms_name}{
                                        el.ms_uid ? ` (${el.ms_uid})` : ''
                                    }:<br/>{el.message}
                                    </div>
                                </div>
                        )
                        : <div className={'info'}>
                            Keine Meldungen
                        </div>
                }
            </>
        );
    }
}

export default State;