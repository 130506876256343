/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useRef, useState} from 'react';

import './content_editor.scss'
import {createPortal} from "react-dom";
import {
    add_content_to_day,
    handle_tags,
    insert_content,
    return_template_content
} from "../../intro/login/dashboard/content/program_planning/loader_program_planning";
import {make_file_upload} from "./file_upload";
import {MediumInfo} from "./medium_info";
import Help from "../../intro/login/dashboard/help/Help";
import html2canvas from 'html2canvas';
import {AppContext} from "../../../App";
import {renderToStaticMarkup} from "react-dom/server";

function Tags(props) {
    const [tags, setTags] = useState({})
    const [tagUIDs, setTagUIDs] = useState(props.tags ? props.tags : [])

    useEffect(() => {
        handle_tags(props.g_state.connector)
            .then(res => setTags(res.data.list))
    }, [])

    useEffect(() => {
        props.callback && props.callback(tagUIDs)
    }, [tagUIDs])

    function add_tag(source) {
        if (source.value.trim().length > 1) {
            handle_tags(props.g_state.connector, source.value.toLowerCase().split(' ').join(''))
                .then(res => {
                    setTags(res.data.list)
                    setTagUIDs(p => [...p, res.data.added])
                })
        }
        source.value = ''
    }

    return (<div className={'Tags'}>
        <div className={'tagEnter'}>
            <input
                type={'text'} className={'new_tag'}
                onKeyDown={ev => {
                    if (ev.code.toLowerCase() === 'enter') {
                        add_tag(ev.currentTarget)
                    }
                }}
            />
            <div
                className={'add'}
                onClick={() => {
                    const source = document.querySelector('.new_tag')
                    add_tag(source)
                }}
            />
        </div>
        <div className={'taglist'}>
            {tagUIDs.map((el, i) => <div className={'tag'} key={i}>
                {tags[el]}
                <div
                    className={'remove'}
                    onClick={() => setTagUIDs(p => p.filter(o => o !== el))}
                />
            </div>)}
        </div>
    </div>)
}

const C_BILD = 10
const C_TEXT = 20
const C_TEXTSERIE = 21
const C_VIDEO = 30
const C_PFEIL = 40
const C_StOERER = 50
const C_HINTERGRUND = 60
const C_COUNTDOWN = 70

function deep_copy(obj) {
    return JSON.parse(JSON.stringify(obj))
}

function PdfSelector(props) {
    const [pdfDoc, setPdfDoc] = useState(null)
    const [currentPage, setCurrentPage] = useState(null)
    const [totalPages, setTotalPages] = useState(0)

    function showPage(page_no, pdf_doc) {
        page_no += 1
        // setRenderInProgress(true)

        // Fetch the page
        pdf_doc.getPage(page_no).then(page => {
            // As the canvas is of a fixed width we need to set the scale of the viewport accordingly
            const __CANVAS = document.getElementById('pdf-canvas')
            let __CANVAS_CTX = __CANVAS.getContext('2d')
            __CANVAS.width = 1920
            var scale_required = __CANVAS.width / page.getViewport(1).width;

            // Get viewport of the page at required scale
            var viewport = page.getViewport(scale_required);

            // Set canvas height
            __CANVAS.height = viewport.height;

            var renderContext = {
                canvasContext: __CANVAS_CTX, viewport: viewport
            };

            // Render the page contents in the canvas
            page.render(renderContext).then(() => {
                // setRenderInProgress(false)
            });
        });
    }

    useEffect(() => {
        if (currentPage !== null) {
            showPage(currentPage, pdfDoc)
        }
    }, [currentPage])

    useEffect(() => {
        const act_pdf = URL.createObjectURL(props.pdf)
        window.PDFJS.getDocument({url: act_pdf})
            .then(pdf_doc => {
                setPdfDoc(pdf_doc)
                setTotalPages(pdf_doc.numPages)
                setCurrentPage(0)
                // showPage(currentPage, pdf_doc);
            })
    }, [])

    return (<div className={'PdfSelector'}>
        <div className={'panel'}>
            <div className={'closer'} onClick={() => props.close()}/>
            <canvas id={'pdf-canvas'}/>
            <div className={'controls'}>
                <div className={'prev'}
                     style={{
                         opacity: totalPages < 2 ? 0 : '', pointerEvents: totalPages < 2 ? 'none' : '',
                     }}
                     onClick={() => {
                         setCurrentPage(p => (p + totalPages - 1) % totalPages)
                     }}
                />
                <div className={'select'}
                     onClick={() => props.select_pdf(document.querySelector('canvas').toDataURL())}
                >
                    Auswählen {totalPages > 1 ? `(${currentPage + 1} / ${totalPages})` : ''}
                </div>
                <div className={'next'}
                     style={{
                         opacity: totalPages < 2 ? 0 : '', pointerEvents: totalPages < 2 ? 'none' : '',
                     }}
                     onClick={() => {
                         setCurrentPage(p => (p + 1) % totalPages)
                     }}
                />
            </div>
        </div>
    </div>)
}

function TemplateHolder(props) {
    const [transformed, setTransfromed] = useState(null)
    const [parsed, setParsed] = useState(null)
    const [localContent, setLocalContent] = useState(null)

    const [mediumInfo, setMediumInfo] = useState(null)
    const [textTools, setTextTools] = useState(null)
    const [textToolsProps, setTextToolsProps] = useState({size: '0', color: 'white', text: '^123'})

    const [validityChanged, setValidityChanged] = useState(false)

    const [pdf, setPdf] = useState(null)
    const [pdfInfo, setPdfInfo] = useState(null)

    const containerTools = useRef(null)

    const act_template = props.act_template

    // helper functions

    useEffect(() => {
        if (props.sliceToRemove !== null) {
            const parts = localContent.parts[props.sliceToRemove.name]
            if (parts[props.act_template_uid]) {
                let tmp_parts = parts[props.act_template_uid]
                if (parts[props.act_template_uid].length > 1) {
                    let tmp_content = localContent
                    tmp_parts.splice(props.sliceToRemove.num, 1)
                    tmp_content.parts[props.sliceToRemove.name][props.act_template_uid] = tmp_parts
                    setLocalContent(tmp_content)
                    props.setNumTextslide(0)
                    props.setTotalTextslide(prev => prev - 1)
                    props.setSliceToRemove(null)
                }
            }
        }
    }, [props.sliceToRemove])

    function insert_medium(medium, medium_info, is_data_url) {
        set_data(medium_info.part_name, medium_info.part_type_id, {
            file: medium
        }, is_data_url)
    }

    function update_contents() {
        // this is reading - write is set_data via _get_content_and_initialize

        let content = localContent

        if (content) {
            if (!content.parts) {
                return null
            }

            Object.keys(content.parts).map(el => {
                Array.from(document.querySelectorAll(`[data-part-name="${el}"]`)).map(node => {
                    if (node) {
                        const my_type_id = parseInt(node.dataset.partTypeId, 10)
                        let act_value
                        switch (my_type_id) {
                            case C_TEXT:
                            case C_TEXTSERIE:
                                content = _get_content_and_initialize(node.dataset.partName, my_type_id)
                                act_value = content.parts[node.dataset.partName][props.act_template_uid][my_type_id === C_TEXTSERIE ? props.numTextslide : 0]
                                if (act_value) {
                                    node.innerHTML = act_value.text
                                    node.setAttribute('data-font-size', act_value.size === '1' ? 'size_1' : 'size_0')
                                    act_value.color ? node.style.color = act_value.color : node.style.color = 'white'
                                } else {
                                    node.innerHTML = ''
                                    node.setAttribute('data-font-size', 'size_0')
                                    node.style.color = 'white'
                                }
                                break
                            case C_COUNTDOWN:
                                break
                            case C_HINTERGRUND:
                                node.style.background = `${props.templateBackground}`
                                break
                            case C_BILD:
                            case C_PFEIL:
                            case C_StOERER:
                                node.style.backgroundImage = `url(${content.parts[node.dataset.partName].src})`
                                node.setAttribute('data-background_image', content.parts[node.dataset.partName].src)

                                let style_object = content.parts[node.dataset.partName]?.style_object && content.parts[node.dataset.partName]?.style_object[props.act_template_uid]
                                if (!style_object) {
                                    style_object = {bg_pos: {bg_x: '0%', bg_y: '0%'}}
                                }

                                node.style.backgroundPositionX = `${style_object.bg_pos.bg_x}`
                                node.style.backgroundPositionY = `${style_object.bg_pos.bg_y}`

                                add_image_size_to_element(node, content.parts[node.dataset.partName].src)
                                break
                            case C_VIDEO:
                                node.src = `${content.parts[node.dataset.partName].src}`
                                break
                            default:
                                break
                        }
                        my_type_id === C_TEXTSERIE && node.setAttribute('data-is-textserie', true)
                    }
                    return null
                })
                return null
            })
        }
    }

    function create_inject(transform = true) {
        const doc = document.implementation.createHTMLDocument("")
        const styleElement = document.createElement("style");

        styleElement.textContent = act_template['css_style'];
        // the style will only be parsed once it is added to a document
        doc.body.appendChild(styleElement);

        let template = document.createElement('body')
        template.innerHTML = act_template['html_template']

        let rules = Object.keys(styleElement.sheet.cssRules).map(el => {
            return styleElement.sheet.cssRules[el].cssText
        }).join('\n')

        if (transform) {
            let reg, my_set, found_els_by_class, found_els_by_id
            reg = /\.[a-zA-Z-_]+/g
            my_set = Array.from(new Set(rules.match(reg)))
            my_set.map(el => {
                const new_random = `.rnd_pmedien_${String(Math.random()).replace('.', '_')}`
                rules = rules.replaceAll(el, new_random)
                found_els_by_class = template.querySelectorAll(el)
                if (found_els_by_class.length) {
                    Object.keys(found_els_by_class).map(obj => {
                        found_els_by_class[obj].classList.remove(el.slice(1))
                        found_els_by_class[obj].classList.add(new_random.slice(1))
                        return null
                    })
                }
                return null
            })

            reg = /#[a-zA-Z-_]+/g
            my_set = Array.from(new Set(rules.match(reg)))
            my_set.map(el => {
                const new_random = `#rnd_pmedien_${String(Math.random()).replace('.', '_')}`
                rules = rules.replaceAll(el, new_random)
                found_els_by_id = template.querySelector(el)
                if (found_els_by_id) {
                    found_els_by_id.id = new_random.slice(1)
                }
                return null
            })
        }

        return ` ${template.innerHTML}<style>${rules}</style>`
    }

    useEffect(() => {
        const ct_timer = setInterval(calc_and_insert_countdown, 1000)
        return () => clearInterval(ct_timer)
    }, [])

    function calc_and_insert_countdown() {
        const tg = document.querySelector('[data-countdown]')
        if (tg) {
            const diff_time = Math.max(Math.floor((new Date(tg.dataset.countdown) - new Date()) / 1000.0), 0)
            if (isNaN(diff_time)) {
                tg.innerHTML = ''
            } else {
                let days = Math.floor(diff_time / (60 * 60 * 24))
                let dayrow = ''
                if (days > 0) {
                    days = `${days}`
                    dayrow = <div className={'dayrow'}>
                        {[...Array(days.length)].map((el, i) => <div key={i} data-card_ct_color={1}
                                                                     className={'card'}>{days.slice(i, i + 1)}</div>)}
                        <div className={"description"}>Tage</div>
                    </div>
                }

                let hours = `${Math.floor(diff_time / (60 * 60)) % 24}`
                hours = hours.length > 1 ? hours : `0${hours}`
                let minutes = `${Math.floor(diff_time / (60)) % 60}`
                minutes = minutes.length > 1 ? minutes : `0${minutes}`
                let seconds = `${diff_time % 60}`
                seconds = seconds.length > 1 ? seconds : `0${seconds}`
                let timerow = <div className={'timerow'}>
                    <div className={'hours'}>
                        {[...Array(hours.length)].map((el, i) => <div key={i} data-card_ct_color={1}
                                                                      className={'card'}>{hours.slice(i, i + 1)}</div>)}
                        <div className={"description"}>Stunden</div>
                    </div>
                    <div className={'minutes'}>
                        {[...Array(minutes.length)].map((el, i) => <div data-card_ct_color={1} className={'card '}
                                                                        key={i}>{minutes.slice(i, i + 1)}</div>)}
                        <div className={"description"}>Minuten</div>
                    </div>
                    <div className={'seconds'}>
                        {[...Array(seconds.length)].map((el, i) => <div data-card_ct_color={1} className={'card'}
                                                                        key={i}>{seconds.slice(i, i + 1)}</div>)}
                        <div className={"description"}>Sekunden</div>
                    </div>
                </div>

                tg.innerHTML = renderToStaticMarkup(<div className={'countdown_rows'}>
                    {dayrow}
                    {timerow}
                    <style dangerouslySetInnerHTML={{
                        __html: `
                    .countdown_rows .dayrow {
                      margin-bottom: calc(var(--einheit) * 1.2);
                      display: flex;
                      position: relative;
                    }
                    .countdown_rows * {
                      font-size: calc(1.5 * var(--einheit));
                    }
                    .countdown_rows {
                      margin-top: calc(var(--einheit) * .5);
                      pointer-events: none;
                      display: flex;
                      flex-direction: column;
                    }
                    
                    .countdown_rows .description {
                      position: absolute;
                      bottom: calc(100% + var(--einheit) * -2.6);
                      transform: scale(.5);
                      transform-origin: 0 0;
                      color: white;
                      font-size: calc(1.5 * var(--einheit) * .5);
                    }
                    
                    .countdown_rows .card {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      background-color: white;
                      margin-right: 2px;
                      width: calc(var(--einheit) * 1.1);
                      height: calc(var(--einheit) * 1.5);
                    }
                    
                    .countdown_rows .timerow {
                      display: flex;
                    }
                    
                    .countdown_rows .timerow .hours,
                    .countdown_rows .timerow .minutes,
                    .countdown_rows .timerow .seconds {
                      display: flex;
                      position: relative;
                      margin-right: calc(var(--einheit) * .3);
                    }`
                    }}/>
                </div>)
                // console.log(days, hours, minutes, seconds)
            }
        }
    }

    // end helper functions

    // 1 - 4 are only executed after the template changed!
    // 1) Template changed: build html, css and assign it to transformed
    useEffect(() => {
        // at the moment it is not dynamic
        setLocalContent(deep_copy(props.content))
        setTransfromed(create_inject(true))
        setParsed(null)
    }, [act_template, props.content])

    // 2) transformed changed: Add datasets and other additional structure
    useEffect(() => {
        if (transformed && parsed === null) {
            const reg_inner = /<\w.*?>[\s ]*{{[A-Za-z_\- ]*}}[\s ]*<\/\w.*>/g
            const reg_single_tag = /<[^<]*{{.*?}}[^<]*>/g

            let tmp_transformed = transformed

            let found_inner = transformed.match(reg_inner)
            let found_single = transformed.match(reg_single_tag)

            const found = [...(found_inner ? found_inner : []), ...(found_single ? found_single : []),]
            const resolve_dic = {}

            found.map((el, i) => {
                try {
                    const tag = el.match(/<[\w]+/)
                    const mustache_value = el.match(/{{.*}}/g)[0].match(/\w+/g)[0]
                    const new_tag = el.replace(/<[\w]+/, `${tag} data-part-name="${mustache_value}"`)
                    tmp_transformed = tmp_transformed.replace(el, new_tag)

                    resolve_dic[i + 1] = mustache_value
                } catch (e) {
                    console.log(e)
                }
                return null
            })

            tmp_transformed = tmp_transformed.replace('autoplay', '')
            setParsed(resolve_dic)
            setTransfromed(tmp_transformed)
        }
    }, [transformed])

    // 3) parsed changed: add all necessary events to the parts
    useEffect(() => {
        if (parsed) {
            Array.from(document.querySelectorAll('[tmp_styles]')).map(el => el.parentNode.removeChild(el))
            Object.keys(act_template.parts).map(el => {
                setup_edit_items(act_template.parts[el])
                return null
            })
            _calc_validity()
        }
    }, [parsed])

    // 4) add the appropriate events to the items AND assign items to elements with update_contents
    function setup_edit_items(obj) {
        let found_elements = Array.from(document.querySelectorAll(`[data-part-name="${obj.name}"]`))
        found_elements.map(el => {
            const is_text = [C_TEXT, C_TEXTSERIE].indexOf(obj['type']) >= 0
            const is_countdown = [C_COUNTDOWN].indexOf(obj['type']) >= 0
            el.setAttribute('contenteditable', is_text)
            el.setAttribute('data-part-type', obj['type_name'])
            el.setAttribute('data-part-type-id', obj.type)
            el.setAttribute('data-template-id', act_template.id)
            el.setAttribute('data-textfahne', obj?.['descr']?.replace(/\r?\n/g, '\\00000a'))

            is_text && (el.style.outline = 'none')
            is_text && (el.innerHTML = '')
            if (is_countdown) {
                el.innerHTML = ''
                el.setAttribute('data-countdown', document.querySelector('input[name="countdown"]').value)
            }
            if (window.getComputedStyle(el).position === 'static') {
                el.style.position = 'relative'
                // console.log(obj)
            }
            let style_description_1 = document.createElement('style')
            style_description_1.setAttribute('tmp_styles', '')
            style_description_1.innerHTML = `.${Array.from(el.classList).join('.')}::after {
                    content: '${obj['descr'].replace(/\r?\n/g, '\\00000a')}';
                }`

            if (['Hintergrundfarbe'].indexOf(obj['type_name']) < 0) {
                document.body.appendChild(style_description_1)
            }

            const pos_y = el.dataset.dy ? el.dataset.dy : 50
            const pos_x = el.dataset.dx ? el.dataset.dx : 50
            const parent_bb = el.getBoundingClientRect()
            const stage_bb = document.querySelector('.TemplateHolder > div').getBoundingClientRect()

            // add classes to pop up window (left, right, ...) and styles (size, color)
            if (is_text) {
                el.classList.add((stage_bb.left + stage_bb.width / 2) - parent_bb.left - 5 <= 0 ? 'tools_left' : 'tools_right')
                el.classList.add((stage_bb.top + stage_bb.height / 2) - parent_bb.top - 5 <= 0 ? 'tools_top' : 'tools_bottom')
                el.setAttribute('data-font-size', 'size_0')
                el.style.color = 'white'
            }

            const width_element_perc = parseInt(window.getComputedStyle(el, ':after').width) / parent_bb.width * 100
            const height_element_perc = parseInt(window.getComputedStyle(el, ':after').height) / parent_bb.height * 100

            let style_description_2 = document.createElement('style')
            style_description_2.setAttribute('tmp_styles', '')
            style_description_2.innerHTML = `.${Array.from(el.classList).join('.')}::after {
                    top: calc((100% - ${height_element_perc}%) * (${pos_y} / 100));
                    left: calc((100% - ${width_element_perc}%) * (${pos_x} / 100));
                }`

            if (['Hintergrundfarbe'].indexOf(obj['type_name']) < 0) {
                document.body.appendChild(style_description_2)
            }

            return null
        })

        // insert contents
        update_contents()

        // set the units so everything fits into the editor
        set_template_unit()
    }

    function set_template_unit() {
        // set width to css var
        const template_unit = window.getComputedStyle(document.body)
            .getPropertyValue('--template-base-unit').split(' ').join('')
        const act_template_holder = document.querySelector('[data-template="true"]')
        if (template_unit && act_template_holder) {
            if (act_template_holder.dataset.orientation === 'portrait') {
                document.documentElement.style.setProperty('--template-base-unit', `${act_template_holder.getBoundingClientRect().height}px`)
            } else {
                document.documentElement.style.setProperty('--template-base-unit', `${act_template_holder.getBoundingClientRect().width}px`)
            }
        }
    }

    function add_image_size_to_element(element, img_url) {
        const holder_coords = element.getBoundingClientRect()
        const actualImage = new Image();
        actualImage.src = img_url;
        actualImage.addEventListener('load', ev => {
            const ratio = holder_coords.width / holder_coords.height
            const ratio_image = ev.currentTarget.width / ev.currentTarget.height
            const is_portrait = ratio > ratio_image // move y direction if true

            const diff = is_portrait ? holder_coords.width * (1 / ratio_image) : holder_coords.height * ratio_image

            element.setAttribute('data-portrait', is_portrait ? '1' : '0')
            element.setAttribute('data-diff', diff)
        })
    }

    function insertTextAtCursor(text) {
        var sel, range, textNode;
        if (window.getSelection) {
            sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                range = sel.getRangeAt(0).cloneRange();
                range.deleteContents();
                textNode = document.createTextNode(text);
                range.insertNode(textNode);

                // Move caret to the end of the newly inserted text node
                range.setStart(textNode, textNode.length);
                range.setEnd(textNode, textNode.length);
                sel.removeAllRanges();
                sel.addRange(range);
            }
        } else if (document.selection && document.selection.createRange) {
            range = document.selection.createRange();
            range.pasteHTML(text);
        }
    }

    // events for inputs
    function handle_events(ev) {
        const part_template_id = ev.target.dataset.templateId
        const part_name = ev.target.dataset.partName
        const part_type = ev.target.dataset.partType
        const part_type_id = parseInt(ev.target.dataset.partTypeId, 10)

        if (!props.moveMode) {
            switch (ev.type) {
                case 'click':
                    if ([C_BILD, C_StOERER, C_PFEIL, C_VIDEO].indexOf(part_type_id) >= 0) {
                        setMediumInfo({
                            part_template_id, part_name, part_type, part_type_id
                        })
                    }
                    break
                case  'keydown':
                    const sel = window.getSelection();
                    if ((sel.focusOffset === sel.focusNode.length || sel.focusNode.length === undefined) && ev.keyCode === 13) {
                        insertTextAtCursor('\n')
                        return false;
                    }
                    break
                case 'input':
                    set_data(part_name, part_type_id, ev.target.innerText)
                    break
                case 'mousedown':
                case 'touchstart':
                    if ([C_TEXT, C_TEXTSERIE].indexOf(part_type_id) >= 0) {
                        ev.target.classList.add('texttools')
                        setTextTools({part_name, part_type_id})
                    }
                    break
                case 'dragover':
                    ev.preventDefault()
                    ev.stopPropagation()
                    break
                case 'dragend':
                    ev.preventDefault()
                    ev.stopPropagation()
                    break
                case 'drop':
                    ev.preventDefault()
                    ev.stopPropagation()
                    if ([C_BILD, C_StOERER, C_VIDEO].indexOf(part_type_id) < 0) {
                        console.log('no image field. skipping.')
                        return
                    }

                    if (ev.dataTransfer.files.length === 1) {
                        const file = ev.dataTransfer.files[0]
                        if (file.name.split('.').slice(-1)[0].toLowerCase() === 'pdf') {
                            setPdfInfo({part_template_id, part_name, part_type, part_type_id})
                            setPdf(file)
                            return;
                        }
                    }

                    const my_file = make_file_upload(ev, 'drag')
                    insert_medium(my_file, {part_template_id, part_name, part_type, part_type_id})
                    set_data(part_name, part_type_id, {file: my_file})
                    break
                default:
                    break
            }
        } else {
            ev.preventDefault()
            const type_id = ev.target.dataset?.partTypeId ? parseInt(ev.target.dataset?.partTypeId, 10) : null
            if ([C_BILD, C_StOERER].indexOf(type_id) >= 0) {
                const cs = window.getComputedStyle(ev.target)
                const bg_pos = {x: parseFloat(cs.backgroundPositionX), y: parseFloat(cs.backgroundPositionY)}
                const is_portrait = ev.target.dataset.portrait === '1'
                const orig_diff = parseFloat(ev.target.dataset.diff)

                if (ev.type === 'mousedown') {
                    ev.target.orig_click = {x: ev.clientX, y: ev.clientY}
                } else if (ev.type === 'mousemove' && ev.buttons === 1) {
                    if (ev.target.orig_click) {
                        const diff = ev[`client${is_portrait ? 'Y' : 'X'}`] - ev.target.orig_click[is_portrait ? 'y' : 'x']
                        ev.target.orig_click = {x: ev.clientX, y: ev.clientY}
                        const my_val = `${Math.max(Math.min(100, (bg_pos[is_portrait ? 'y' : 'x'] - diff * (100 / orig_diff * (ev.shiftKey ? 5 : .5)))), 0)}%`
                        ev.target.style[`backgroundPosition${is_portrait ? 'Y' : 'X'}`] = my_val

                        set_data(part_name, part_type_id, null, false, {
                            bg_pos: {
                                bg_x: !is_portrait ? my_val : '0%', bg_y: is_portrait ? my_val : '0%'
                            }
                        })

                    }
                }
            }
        }
    }

    // end setup

    function _get_content_and_initialize(part_name, part_type_id) {
        if (localContent) {
            const content = localContent
            if (!content.parts) {
                content.parts = {}
            }
            // checks:
            // available at all
            switch (part_type_id) {
                case C_TEXT:
                case C_TEXTSERIE:
                    if (!content.parts[part_name]) {
                        content.parts[part_name] = {}
                    }
                    if (!content.parts[part_name][props.act_template_uid]) {
                        content.parts[part_name][props.act_template_uid] = [{
                            size: undefined, color: undefined, text: '',
                        }]
                    }
                    if (part_type_id === C_TEXTSERIE && !content.parts[part_name][props.act_template_uid][props.numTextslide]) {
                        content.parts[part_name][props.act_template_uid][props.numTextslide] = {
                            text: '', size: undefined, color: undefined
                        }
                    }
                    break;
                case C_BILD:
                case C_PFEIL:
                case C_StOERER:
                case C_VIDEO:
                    content.parts[part_name] = null
                    break;
                default:
                    content.parts[part_name] = null
                    break
            }
            return content
        }

        return null
    }

    function set_data(part_name, part_type_id, value, is_data_url, styles) {
        // console.log(
        //     'Set temporary data - \n Field in Template:', part_name, '\n',
        //     'Page:', props.act_template_uid, '\n',
        //     'Typ Content:', part_type_id, '\n',
        //     'Value:', value, '\n',
        //     'Styles:', styles, '\n',
        //     'LocalContent:', localContent, '\n',
        // )
        // change, input, keydown, keypress, keyup, selectionchange, textinput
        const content = !styles ? _get_content_and_initialize(part_name, part_type_id) : localContent

        switch (part_type_id) {
            case C_TEXT:
                content.parts[part_name][props.act_template_uid][0].text = value
                break;
            case C_TEXTSERIE:
                content.parts[part_name][props.act_template_uid][props.numTextslide].text = value
                break;
            case C_BILD:
            case C_PFEIL:
            case C_StOERER:
            case C_VIDEO:
                if (value && value.file && !styles) {
                    if (is_data_url) {
                        content.parts[part_name] = {
                            src: value.file,
                            copyright: '',
                            name: 'pdf_png.png',
                            valid_from: '',
                            valid_to: '',
                            style_object: {
                                ...content.parts[part_name]?.style_object, ...{
                                    [props.act_template_uid]: {
                                        bg_pos: {
                                            bg_x: '0%', bg_y: '0%'
                                        }
                                    }
                                }
                            },
                        }
                        setTimeout(update_contents, 10)
                    } else {
                        const reader = new FileReader()
                        reader.readAsDataURL(value.file)
                        reader.onloadend = () => {
                            content.parts[part_name] = {
                                src: reader.result,
                                copyright: '',
                                name: value.file.name,
                                valid_from: '',
                                valid_to: '',
                                style_object: content.parts[part_name]?.style_object,
                            }
                            setTimeout(update_contents, 10)
                        }
                    }
                } else if (styles) {
                    content.parts[part_name].style_object = {
                        ...content.parts[part_name]?.style_object, ...{[props.act_template_uid]: styles}
                    }
                    break
                } else {
                    content.parts[part_name] = {
                        src: null,
                        copyright: '',
                        name: '',
                        valid_from: '',
                        valid_to: '',
                        style_object: content.parts[part_name]?.style_object,
                    }
                    setTimeout(update_contents, 10)
                }
                break;
            default:
                content.parts[part_name] = value
                break
        }
        setLocalContent(content)

        const tmp_partdata = props.partdata
        tmp_partdata[part_name] = value
        props.setPartdata(localContent)
        props.setDataChanged(true)
    }

    function set_properties(part_name, part_type_id, attribute, value) {
        // if no entry for part in template is set:
        // - create an structure where the data can be stored later on
        const content = _get_content_and_initialize(part_name, part_type_id)
        switch (part_type_id) {
            case C_TEXT:
                content.parts[part_name][props.act_template_uid][0][attribute] = value
                break;
            case C_TEXTSERIE:
                content.parts[part_name][props.act_template_uid][props.numTextslide][attribute] = value
                break;
            default:
                break
        }
        setTextToolsProps(p => ({...p, [attribute]: value}))
        setLocalContent(content)

        const tmp_partdata = props.partdata
        tmp_partdata[part_name] = value
        props.setPartdata({...props.partdata, ...tmp_partdata})
        props.setDataChanged(true)

        update_contents()
    }

    useEffect(() => {
        update_contents()
    }, [props.numTextslide, props.totalTextslide])

    useEffect(() => {
        props.setPartdata(localContent)
    }, [localContent])

    // Handle texttool window
    function handle_texttool_window(ev) {
        const tool_element = document.querySelector('.hide_description')

        if (ev.target.classList.contains('texttools')) {
            if (tool_element && ev.target !== tool_element) {
                Array.from(document.querySelectorAll('.hide_description')).forEach(el => el.classList.remove('hide_description'))
            }

            ev.target.classList.add('hide_description')

            const target_bb = ev.target.getBoundingClientRect()

            let pos_x = target_bb.left
            if (ev.target.classList.contains('tools_left')) {
                pos_x -= containerTools.current.querySelector('.text_tools').getBoundingClientRect().width
            } else {
                pos_x = target_bb.right
            }

            containerTools.current['style'].left = `${pos_x}px`
            containerTools.current['style'].top = `${target_bb.top}px`

        } else if (!ev.target.closest('.container_text_tools')) {
            Array.from(document.querySelectorAll('.hide_description')).forEach(el => el.classList.remove('hide_description'))
            setTextTools(false)
        }
    }

    useEffect(() => {
        document.body.addEventListener('mousedown', handle_texttool_window)
        document.body.addEventListener('touchstart', handle_texttool_window)

        window.addEventListener('resize', set_template_unit)
        return () => {
            document.body.removeEventListener('mousedown', handle_texttool_window)
            document.body.removeEventListener('touchstart', handle_texttool_window)
            window.removeEventListener('resize', set_template_unit)
        }

    }, [])

    useEffect(() => {
        update_contents()
    }, [props.templateBackground])

    function _return_default_for_texttool(part_name, part_type_id) {
        const num = part_type_id === C_TEXTSERIE ? props.numTextslide : 0
        let result = localContent.parts && localContent.parts[part_name] && localContent.parts[part_name][props.act_template_uid] && localContent.parts[part_name][props.act_template_uid][num]

        setTextToolsProps({
            color: result?.color ? result.color : 'white', size: result?.size ? result.size : '0'
        })
        return result
    }

    function _calc_validity() {

        let valid_range = {from: undefined, to: undefined}

        props.partsWithMedium.forEach(el => {
            const from = localContent.parts[el] ? localContent.parts[el].valid_from : null
            const to = localContent.parts[el] ? localContent.parts[el].valid_to : null
            let tmp_date
            if (from) {
                tmp_date = new Date([...from.split('-')])
                if (!valid_range.from) {
                    valid_range.from = tmp_date
                } else {
                    valid_range.from = valid_range.from > tmp_date ? tmp_date : valid_range.from
                }
            }
            if (to) {
                tmp_date = new Date([...to.split('-')])
                if (!valid_range.to) {
                    valid_range.to = tmp_date
                } else {
                    valid_range.to = valid_range.to < tmp_date ? tmp_date : valid_range.to
                }
            }
        })
        props.setValidityRange(valid_range)
    }

    useEffect(() => {
        textTools && _return_default_for_texttool(textTools.part_name, textTools.part_type_id, 'size')
    }, [textTools])

    useEffect(() => {
        if (validityChanged) {
            setValidityChanged(false)
            _calc_validity()
        }
    }, [mediumInfo])

    function select_pdf(my_file) {
        insert_medium(my_file, pdfInfo, true)
        setPdfInfo(null)
        setPdf(null)
    }

    function close_pdf() {
        setPdf(null)
        setPdfInfo(null)
    }

    return (<>
        {pdf && <PdfSelector pdf={pdf} close={close_pdf} select_pdf={select_pdf}/>}
        {textTools && createPortal(<div className={'container_text_tools'} ref={containerTools}>
            <div className={'text_tools'}>
                <div className={'title'}>Eigenschaften Text</div>
                <div className={'font_size fr'}>
                    <label className={'title'}>Schriftgröße</label>
                    <div>
                        <select
                            value={textToolsProps.size} name={'size'}
                            onChange={ev => {
                                set_properties(textTools.part_name, textTools.part_type_id, 'size', ev.currentTarget.value)
                            }}
                        >
                            <option value={'0'}>Normal</option>
                            <option value={'1'}>Größer</option>
                        </select>
                    </div>
                </div>
                <div className={'font_color fr'}>
                    <label className={'title'}>Schriftfarbe</label>
                    <div className={'fr'}>
                        <input name={'color'}
                               onChange={ev => set_properties(textTools.part_name, textTools.part_type_id, 'color', ev.currentTarget.value)}
                               value={textToolsProps.color}
                        />
                    </div>
                </div>
            </div>
        </div>, document.body)}
        {mediumInfo && <MediumInfo
            setMediumInfo={setMediumInfo} insert_medium={insert_medium} mediumInfo={mediumInfo}
            localContent={localContent} setLocalContent={setLocalContent}
            setValidityChanged={setValidityChanged} setDataChanged={props.setDataChanged}
        />}
        <div
            className={`TemplateHolder bo ${props.preview ? 'preview' : ''}`}
            dangerouslySetInnerHTML={{__html: transformed}}

            onInput={ev => handle_events(ev)}
            onMouseDown={ev => handle_events(ev)}
            onMouseMove={ev => handle_events(ev)}
            onTouchStart={ev => handle_events(ev)}
            onDragOver={ev => handle_events(ev)}
            onDragEnd={ev => handle_events(ev)}
            onDrop={ev => handle_events(ev)}
            onClick={ev => handle_events(ev)}
            onKeyDown={ev => handle_events(ev)}
            onKeyUp={ev => handle_events(ev)}
        >
        </div>
    </>)
}

function ContentEditor(props) {
    const [templates, setTemplates] = useState([])
    const [template, setTemplate] = useState(null)
    const [templateData, setTemplateData] = useState(null)
    const [selectedTemplate, setSelectedTemplate] = useState(0)

    // component will write all changes to partdata, check before template change
    const [partdata, setPartdata] = useState({})
    const [dataChanged, setDataChanged] = useState(false)

    const [preview, setPreview] = useState(false)

    const [numTextslide, setNumTextslide] = useState(0)
    const [totalTextslide, setTotalTextslide] = useState(1)

    const [sliceToRemove, setSliceToRemove] = useState(null)

    const [templateProperties, setTemplateProperties] = useState({})

    const [confirmChange, setConfirmChange] = useState(false)

    const [partsWithMedium, setPartsWithMedium] = useState([])

    const [validityRange, setValidityRange] = useState({from: undefined, to: undefined})

    const [features, setFeatures] = useState([])

    const [contentUid, setContentUid] = useState(null)

    const [saveLocked, setSavedLocked] = useState(false)

    const [moveMode, setMoveMode] = useState(false)

    const [closeAfterSave, setCloseAfterSave] = useState(false)

    let templateClone = null;

    useEffect(() => {
        props.g_state.connector.run_action({
            action: 'list_templates', class: '', data: ''
        }, res => {
            if (props.addContent === true) {
                setTemplates(res.data)
            } else {
                setTemplate(props.addContent.templateset_id)
            }
        })
    }, [])

    useEffect(() => {
        if (template !== null) {
            props.g_state.connector.run_action({
                action: 'show_template', class: '', data: JSON.stringify({id: template})
            }, res => {
                load_content_for_template(res.data)
            })
        }
    }, [template])

    useEffect(() => {
        setNumTextslide(0)
        if (templateData) {
            const medium_fields = []
            templateData['templates'].forEach(t => Object.keys(t.parts).forEach(p => {
                if ([C_BILD, C_PFEIL, C_StOERER, C_VIDEO].indexOf(t.parts[p]['type']) >= 0) {
                    medium_fields.indexOf(t.parts[p].name) < 0 && medium_fields.push(t.parts[p].name)
                }
            }))
            setPartsWithMedium(medium_fields)

            // extract background if available
            const my_parts = (templateData['templates'][selectedTemplate].parts)
            const template = templateData.content ? templateData.content : null

            const text_slider_key = Object.keys(my_parts)
                .filter(el => my_parts[el]['type'] === C_TEXTSERIE)
                .find(el => el)
            if (text_slider_key) {
                const template_id = templateData['templates'][selectedTemplate].id

                const slices = template.parts.hasOwnProperty(text_slider_key) ? (template.parts[text_slider_key].hasOwnProperty(template_id) ? template.parts[text_slider_key][template_id] : []) : []

                setTotalTextslide(Math.max(slices.length, 1))
            } else {
                setTotalTextslide(1)
            }

            // background color
            Object.keys(my_parts)
                .filter(el => my_parts[el]['type_name'] === 'Hintergrundfarbe')
                .map(el => {
                    setTemplateProperties(() => ({
                        ...templateProperties, ...{
                            background: {
                                key: my_parts[el].name, value: template.parts[my_parts[el].name]
                            }
                        }
                    }))
                    return null
                })
            setTemplateProperties(prev => ({
                ...prev,
                video_to_end: template?.video_to_end,
                name_template: template?.name_template,
                status: template?.status || 0, // defaults to "in Arbeit"
                way_to: template?.way_to,
                way_active: template?.way_active,
                tags: template?.tags,
                category: template?.category || 1, // defaults to Sparkasse
                valid_from: template?.valid_from,
                valid_to: template?.valid_to,
                countdown: template?.countdown || '',
            }))
        }
    }, [templateData, selectedTemplate])


    useEffect(() => {
        document.querySelectorAll('video').forEach(el => preview ? el.play() : (el => {
            el.currentTime = 0
            el.pause()
        })(el))
    }, [preview])

    useEffect(() => {
        if ((dataChanged && confirmChange) || closeAfterSave) {
            save_data()
        }
    }, [dataChanged, confirmChange, closeAfterSave])

    function check_before_close() {
        if (dataChanged) {
            setCloseAfterSave(true)
        } else {
            props.setAddContent(null)
        }
    }

    function load_content_for_template(data) {
        // data holds the structure of the templateset
        // console.log(data)

        // find all features
        const features = new Set()
        let key_background = null
        data.templates?.forEach(template => {
            const parts = template.parts
            if (parts) {
                Object.keys(parts).forEach(key => {
                    if (parts[key].type === 60 && !key_background) {
                        key_background = key
                    }
                    features.add(parts[key].type)
                })
            }
        })
        setFeatures(features)

        // now load the data
        return_template_content(props.g_state.connector, props.addContent.content_id ? props.addContent.content_id : null)
            .then(my_content => {
                if (my_content.data?.content) {
                    setTemplateData({
                        ...data, content: my_content.data.content
                    })
                } else {
                    const empty_content = {
                        parts: {}, way_active: false, way_to: my_content.data?.way_to, video_to_end: false, // done
                        name_template: '', // done
                        status: 0, tags: [], category: 0, countdown: '',
                    }

                    if (key_background) {
                        empty_content.parts[key_background] = ''
                    }

                    setTemplateData({...data, content: empty_content})
                }
            })


        data = {
            ...data, // content: {},
            // content: {
            //     parts: {
            //         b1: {
            //             src: 'http://pmedien.com/uploads/pics/Sofa.jpg',
            //             copyright: 'Impressum',
            //             name: 'Spa',
            //             valid_from: '2021-02-01',
            //             valid_to: '2022-02-19',
            //         },
            //         b2: {
            //             src: 'http://pmedien.com/uploads/pics/01_inhalt.jpg',
            //             copyright: 'Impressum',
            //             name: 'Spa2',
            //         },
            //         b3: {
            //             src: 'http://pmedien.com/uploads/pics/02_drama.jpg',
            //             copyright: 'Impressum',
            //             name: 'Spa',
            //             valid_from: '2021-02-12',
            //             valid_to: '2021-02-19',
            //         },
            //         t: {
            //             3: [
            //                 {size: undefined, color: 'red', text: 'Text kein slider'}
            //             ],
            //         },
            //         th: {
            //             3: [
            //                 {size: undefined, color: 'red', text: 'Text kein slider'}
            //             ],
            //         },
            //         video: {
            //             // src: 'http://localhost:8080/media/feuer.mp4',
            //             copyright: 'Impressum',
            //             name: 'Spa',
            //         },
            //         ts: {
            //             3: [
            //                 {text: 'schöner Text', size: '1', color: 'green'},
            //                 {text: 'besserer Text', size: '1', color: 'green'},
            //                 {text: 'chaotischer Text', size: '1', color: 'green'},
            //                 {text: 'dummer Text', size: '1', color: 'green'},
            //                 {text: 'elementarer Text', size: '1', color: 'green'},
            //             ]
            //         },
            //         bg: 'yellow',
            //     },
            //     way_active: true,
            //     way_to: [
            //         {id: 23, name: 'schmuck1', selected: false},
            //         {id: 34, name: 'schmuck2', selected: false},
            //         {id: 662, name: 'sele', selected: true},
            //         {id: 24, name: 'schmuck3', selected: false},
            //     ],
            //     video_to_end: false, // done
            //     name_template: '', // done
            //     status: 0,
            //     tags: [],
            //     category: 0,
            // }
        }

        // add ncesary data

        if (!data.content) {
            data = {...data, content: {parts: {}}}
        }
        if (!data.content.parts) {
            data = {...data, content: {...data.content, parts: {}}}
        }

    }

    function set_selected_template(uid) {
        if (dataChanged) {
            setConfirmChange(uid)
        } else {
            setPartdata({})
            setDataChanged(false)
            setSelectedTemplate(uid)
        }
    }

    function handle_template_action(source_id, target_id) {
        // copy content to other template or empty template

        // deep copy...
        const tmp_content_parts = deep_copy(templateData.content.parts)

        if (target_id === undefined) {
            Object.keys(tmp_content_parts).forEach(el => {
                if (partsWithMedium.indexOf(el) < 0) {
                    if (typeof tmp_content_parts[el] === typeof {} && tmp_content_parts[el].hasOwnProperty(source_id)) {
                        delete tmp_content_parts[el][source_id]
                    }
                } else {
                    tmp_content_parts[el] = {file: null}
                }
            })
        } else {
            Object.keys(tmp_content_parts).forEach(el => {
                if (partsWithMedium.indexOf(el) < 0) {
                    if (typeof tmp_content_parts[el] === typeof {} && tmp_content_parts[el].hasOwnProperty(source_id)) {
                        tmp_content_parts[el][target_id] = tmp_content_parts[el][source_id]
                    }
                }
            })
        }

        // NOTE has no effect!?
        const new_template_data = {...templateData, content: {...templateData.content, parts: tmp_content_parts}}
        setNumTextslide(0)
        setTemplateData(new_template_data)
    }

    function change_template(ev, current_template) {
        switch (ev.type) {
            case 'touchstart':
            case 'mousedown':
                if (ev.type === 'mousedown') {
                    ev.currentTarget.parentElement.addEventListener('mouseleave', change_template)
                }
                ev.currentTarget.parentElement.addEventListener(ev.type === 'touchstart' ? 'touchmove' : 'mousemove', change_template)
                ev.currentTarget.parentElement.addEventListener(ev.type === 'touchstart' ? 'touchend' : 'mouseup', change_template)

                const clone = ev.currentTarget.cloneNode(true)
                clone.style.position = 'fixed'
                clone.style.pointerEvents = 'none'
                clone.classList.add('template_clone')
                clone.classList.remove('active')
                clone.style.zIndex = 10
                ev.currentTarget.parentElement.appendChild(clone)
                clone.style.left = `${ev.type === 'mousedown' ? ev.clientX : ev.touches[0].clientX}px`
                clone.style.top = `${ev.type === 'mousedown' ? ev.clientY : ev.touches[0].clientY}px`
                templateClone = clone
                break
            case 'mousemove':
            case 'touchmove':
                if (templateClone) {
                    templateClone.style.left = `${ev.type === 'mousemove' ? ev.clientX : ev.touches[0].clientX}px`
                    templateClone.style.top = `${ev.type === 'mousemove' ? ev.clientY : ev.touches[0].clientY}px`
                }
                break
            case 'touchend':
            case 'mouseup':
            case 'mouseleave':
                const drop_target = ev.type === 'touchend' ? document.elementFromPoint(ev.changedTouches[0].clientX, ev.changedTouches[0].clientY) : ev.target
                if (ev.type !== 'mouseleave' && drop_target.classList.contains('template_selection')) {
                    const source_id = templateClone.dataset.uid
                    const target_id = drop_target.dataset.uid
                    if (source_id !== target_id) {
                        if (templateClone.dataset.selectedtemplate !== `${selectedTemplate}`) {
                            handle_template_action(source_id, target_id)
                        }
                    }
                }

                ev.currentTarget.removeEventListener('mousemove', change_template)
                ev.currentTarget.removeEventListener('touchmove', change_template)
                ev.currentTarget.removeEventListener('touchend', change_template)
                ev.currentTarget.removeEventListener('mouseup', change_template)
                ev.currentTarget.removeEventListener('mouseleave', change_template)

                templateClone && ev.currentTarget.removeChild(templateClone)
                templateClone = null
                break
            default:
                break
        }
    }

    function change_text_slides(add = true) {
        const act_text_slider = document.querySelector(`[data-part-type-id="${C_TEXTSERIE}"]`)
        if (act_text_slider) {
            const part_name = act_text_slider.getAttribute('data-part-name')
            if (add) {
                setTotalTextslide(prev => prev + 1)
                setTimeout(() => {
                    act_text_slider.focus()
                    setNumTextslide(() => Math.min(totalTextslide))
                }, 10)
            } else {
                setSliceToRemove({num: numTextslide, element: act_text_slider, name: part_name})
            }
        }
    }

    function set_background_color(ev) {
        const old_background = templateProperties?.background

        if (old_background) {
            old_background.value = ev.currentTarget.value
            setTemplateProperties({...templateProperties, ...old_background})
            setDataChanged(true)
        }
    }

    function save_data() {
        setSavedLocked(true)
        // console.log('---------------------------------------')
        // console.log('SAVE')
        const template_properties = deep_copy(templateProperties)
        const act_partdata = deep_copy(partdata.parts)

        if (templateProperties?.background?.key) {
            try {
                act_partdata[templateProperties?.background?.key] = templateProperties?.background?.value
                delete template_properties?.background
                delete template_properties?.key
                delete template_properties?.value
            } catch (e) {
                console.log('error with setting bg color')
            }
        }

        const main_content = {
            ...template_properties, status: props.miniPool ? 1 : template_properties.status, ...{parts: act_partdata}
        }

        // console.log('Mediums:')
        // console.log(partsWithMedium)

        // console.log('Content:')
        // console.log(content_to_upload)

        // Find files to upload
        const files_to_upload = {}
        partsWithMedium.forEach(el => files_to_upload[el] = false)
        main_content.parts && Object.keys(main_content.parts).forEach(el => {
            if (partsWithMedium.indexOf(el) >= 0) {
                if (main_content.parts[el].src && main_content.parts[el].src.match(/data:\w*\/\w*[;]base64,/g)) {
                    files_to_upload[el] = true
                }
            }
        })

        // console.log('Files to upload:')
        // console.log(files_to_upload)

        // console.log('Structure to upload:')
        // console.log(content_to_upload)

        _upload_data_to_server({
            files_to_upload,
            content_to_upload: main_content,
            template_set: templateData.id_template,
            contentUid: contentUid || props.addContent.content_id,
        })
            .then((value) => {
                // console.log('Upload done.', value)
                if (value.data) {
                    main_content.parts = value.data.parts
                    setContentUid(value.data.content_uid)
                    setTemplateData(p => ({...p, content: main_content}))
                }
                setDataChanged(false)

                if (confirmChange !== false) {
                    setSelectedTemplate(confirmChange)
                }

                setConfirmChange(false)

                if ((!contentUid && !props.miniPool?.station) || contentUid) {
                    props.refresh_data(['schedule', 'dayprogram'], 'ContentEditor')
                }

                if (props.miniPool?.date && props.miniPool?.station) {
                    props.setMiniPool(p => ({...p, date: null, station: null}))
                    add_content_to_day(props.g_state.connector, props.miniPool.date, props.miniPool.station, value.data.content_uid)
                        .then(() => {
                            if (!contentUid) {
                                props.refresh_data(['schedule', 'dayprogram'], 'ContentEditor')
                            }
                        })
                }

                setSavedLocked(false)
                if (closeAfterSave) {
                    props.setAddContent(null)
                }
            })
    }

    function _upload_data_to_server(upload_package) {
        // UPLOAD data to server
        // console.log('Package to upload:', upload_package)

        return insert_content(props.g_state.connector, JSON.stringify(upload_package))
            .then(res => res)
    }

    function _format_date(date) {
        return `${new Intl.DateTimeFormat('de', {day: '2-digit'}).format(date)}.${new Intl.DateTimeFormat('de', {month: '2-digit'}).format(date)}.${new Intl.DateTimeFormat('de', {year: 'numeric'}).format(date)}`
    }

    function set_template_properties(key, value) {
        setTemplateProperties(prev => ({...prev, ...{[key]: value}}))
        setDataChanged(true)
    }

    function tag_callback(data) {
        set_template_properties('tags', data)
    }

    function make_screenshot() {
        html2canvas(document.querySelector('.TemplateHolder > *'))
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const uid = props.addContent?.content_id || contentUid

                props.g_state.connector.run_action({
                    action: 'add_content_image', class: '', data: JSON.stringify({
                        uid: uid, image: imgData
                    })
                }, res => {
                    console.log(res)
                })

                // var link = document.createElement('a');
                //
                //
                // link.setAttribute('download', 'MintyPaper.png');
                // link.setAttribute('href', imgData);
                // link.click();
            })
    }

    function check_if_templated_filled(current) {
        if (!partdata) {
            return false
        }
        const important_parts = Object.keys(current.parts)
            .map(el => current.parts[el])
            .filter(el => [C_BILD, C_TEXT, C_TEXTSERIE, C_VIDEO, C_StOERER].indexOf(el.type) >= 0)
            .map(el => ({name: el.name, typ: el.type}))
        const my_parts = partdata.parts

        const found = important_parts.filter(el => {
            if ([C_BILD, C_VIDEO, C_StOERER].indexOf(el.typ) >= 0) {// image
                return my_parts?.[el.name]?.src?.length >= 5// has a valid source (image, video)
            } else if ([C_TEXT, C_TEXTSERIE].indexOf(el.typ) >= 0) {// text to check with id
                const text_arrs = my_parts?.[el.name]?.[current.id]
                if (text_arrs?.length > 0) {// überprüfen ob texte vieleicht nur leer sind
                    return text_arrs.map(el => el.text).join('').length > 0
                } else {// gar kein text da
                    return false
                }
            } else {// Other types...
                return false
            }
        })
        return found.length > 0
    }

    return (
        <div className={`ContentEditor fc ${(props.addContent.content_id || contentUid) ? '' : 'inactive'}`}>
            {
                (confirmChange === true || closeAfterSave === true || saveLocked === true) &&
                <div className={'confirm_change'}>
                    <div className={'is_saving'}>Speichern...</div>
                </div>
            }
            <div className={'head fr'}>
                <div className={'title'}>
                    Content Editor
                    {templateData ? '' : ' - Template Auswahl'}
                    {(templateData && templateData.name) ? ` - ${templateData.name}` : ''}
                </div>
                <div style={{display: 'flex'}}>
                    <Help/>
                    <div className={'closer'} onClick={() => check_before_close()}/>
                </div>
            </div>
            {!templateData && <div className={'templates fr'}>
                {templates && templates.map((el, i) => <div className={'template fc'} data-uid-template={el.id} key={i}
                                                            onClick={() => setTemplate(el.id)}>
                    <div className={'title'}>{el.name}</div>
                    <div
                        className={'image'}
                        style={{backgroundImage: `url(${props.g_state.origin}${el.image})`}}
                    />
                </div>)}
            </div>}
            {templateData && <div className={'template fr'}>
                <div className={'template_view fc'}>
                    <div className={'template_top_bar fr'}>
                        <div className={'head fr'}>
                            {templateData['templates'].map((el, i) => <div
                                className={`fr bo button template_selection ${i === selectedTemplate ? 'active' : ''}`}
                                data-templateFilled={check_if_templated_filled(el)}
                                draggable={false}
                                onTouchStart={ev => change_template(ev, i === selectedTemplate)}
                                onMouseDown={ev => change_template(ev, i === selectedTemplate)}
                                data-uid={el.id}
                                key={i}
                                data-selectedtemplate={i}
                                onClick={() => set_selected_template(i)}
                            >
                                <div
                                    className={'image'}
                                    style={{backgroundImage: `url(${props.g_state.origin}${el.resolution.image})`}}
                                />
                            </div>)}
                            <div
                                className={`fr bo button trash_button template_selection`}
                            >
                                <div className={'image trash'}/>
                            </div>
                            {(props.addContent?.content_id || contentUid) && <div
                                onClick={ev => {
                                    ev.currentTarget.classList.add('animation_rotate')
                                    make_screenshot()
                                }}
                                onAnimationEnd={ev => {
                                    ev.currentTarget.classList.remove('animation_rotate')
                                }}
                                className={'make_content_pic fr bo button'}
                            />}
                            <div
                                className={'move_image fr bo button'}
                                data-templatefilled={moveMode}
                                onClick={() => setMoveMode(p => !p)}
                            />
                        </div>
                        <div className={'preview'}>
                            <div
                                className={`title`}
                                onClick={() => setPreview(prev => !prev)}
                            >
                                Vorschau
                                <div className={`toggle_button ${preview ? 'active' : ''}`}>
                                    <div className={'knob'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TemplateHolder
                        {...props} act_template={templateData['templates'][selectedTemplate]} preview={preview}
                        numTextslide={numTextslide}
                        act_template_uid={templateData['templates'][selectedTemplate].id}
                        setTotalTextslide={setTotalTextslide} totalTextslide={totalTextslide}
                        content={templateData.content}
                        setNumTextslide={setNumTextslide}
                        sliceToRemove={sliceToRemove} setSliceToRemove={setSliceToRemove}
                        partdata={partdata} setPartdata={setPartdata}
                        setDataChanged={setDataChanged}
                        templateBackground={templateProperties?.background?.value}
                        partsWithMedium={partsWithMedium} setValidityRange={setValidityRange}
                        addContent={props.addContent} moveMode={moveMode}
                    />
                    <div className={'template_controls fr'}>
                        <div
                            className={'bo plus'}
                            onClick={() => (totalTextslide < 3 && change_text_slides(true))}
                            style={{opacity: totalTextslide < 3 ? 1 : .5}}
                        />
                        <div
                            className={'bo trash'} onClick={() => change_text_slides(false)}
                        />
                        <div
                            className={'bo prev'} onClick={() => setNumTextslide(prev => Math.max(0, prev - 1))}/>
                        <div className={'counter fr'}>{numTextslide + 1} | {totalTextslide}</div>
                        <div
                            className={'bo next'}
                            onClick={() => setNumTextslide(prev => Math.min(totalTextslide - 1, prev + 1))}/>
                    </div>
                </div>
                {Object.keys(templateProperties).length > 0 && <div className={'template_settings'}>
                    <div className={'settings_1'}>
                        <div className={'title'}>Layout Eigenschaften</div>
                        {features.has(C_HINTERGRUND) && <div className={'background-color'}>
                            <label>Hintergrundfarbe</label>
                            <input
                                className={'text_color'} name={'text_color'}
                                value={templateProperties?.background?.value}
                                onChange={ev => set_background_color(ev)}
                            />
                        </div>}
                        {features.has(C_PFEIL) && <div className={'way_to'}>
                            <input
                                type={'checkbox'} checked={templateProperties?.way_active}
                                name={'way_to_check'}
                                onChange={() => set_template_properties('way_active', !templateProperties.way_active)}
                            />
                            <label>Wegeleitung zu</label>
                            <select name={'way_to_select'}
                                    className={`${!templateProperties?.way_active ? 'disabled' : ''}`}
                                    value={templateProperties?.way_to?.find(el => el.selected)?.id}
                                    onChange={ev => {
                                        const new_val = ev.currentTarget.value
                                        const act_vals = templateProperties?.way_to
                                        Object.keys(act_vals).map(el => {
                                            act_vals[el].selected = parseInt(new_val, 10) === act_vals[el].id
                                            return null
                                        })
                                        set_template_properties('way_to', act_vals)
                                    }}
                            >
                                <option>Bitte wählen</option>
                                {templateProperties?.way_to?.map((el, i) => <option value={el.id} key={i}>
                                    {el.name}
                                </option>)}
                            </select>
                        </div>}
                        {features.has(C_VIDEO) && <div className={'video_to_end'}>
                            <input type={'checkbox'} checked={templateProperties?.video_to_end}
                                   onChange={() => set_template_properties('video_to_end', !templateProperties.video_to_end)}
                                   name={'video_to_end_check'}
                            />
                            <label>Video bis zum Ende spielen</label>
                        </div>}
                        {features.has(C_COUNTDOWN) && <div className={'countdown'}>
                            <label>Countdown zum</label>
                            <input
                                type={'datetime-local'} value={templateProperties?.countdown}
                                onChange={(ev) => set_template_properties('countdown', ev.currentTarget.value)}
                                name={'countdown'}
                            />
                        </div>}
                    </div>
                    <div className={'settings_2'}>
                        <div className={'title'}>Content Eigenschaften</div>
                        <div className={'campaign'}>
                            <label className={'title'}>Titel</label>
                            <input
                                className={'name_template'}
                                name={'name_template'}
                                value={templateProperties.name_template}
                                placeholder={'Name des Contents'}
                                onChange={ev => set_template_properties('name_template', ev.currentTarget.value)}
                            />
                        </div>
                        <div className={'holder_category'}>
                            <label className={'title'}>Kategorie</label>
                            <select name={'category'}
                                    value={// TODO set initial value if not templateProperties?.category is set (seems to be 0 >> must be the first in list)
                                        templateProperties?.category}
                                    onChange={ev => {
                                        const new_val = ev.currentTarget.value
                                        set_template_properties('category', new_val)
                                    }}
                            >
                                {props.categories.map((el, i) => <option key={i} value={el.uid}>{el.name}</option>)}
                            </select>
                        </div>
                        <div className={'is_valid'}>
                            <label className={'title'}>Gültigkeit von</label>
                            <input
                                name={'valid_from'} type={'date'} className={'bo'}
                                data-key="valid_from"
                                value={templateProperties.valid_from}
                                onChange={() => {
                                    const from_field = document.querySelector('input[name="valid_from"]')
                                    const from_date = new Date(from_field.value)
                                    const to_field = document.querySelector('input[name="valid_to"]')
                                    let to_date = new Date(to_field.value)
                                    if (!(isNaN(from_date.valueOf()) || isNaN(to_date.valueOf()))) {
                                        if (from_date > to_date) {
                                            to_field.value = from_field.value
                                        }
                                    }
                                    set_template_properties('valid_from', from_field.value)
                                    set_template_properties('valid_to', to_field.value)
                                }}
                                // onChange={ev => {props.setValidityChanged(true)}}
                            />
                            <div>bis</div>
                            <input
                                name={'valid_to'} type={'date'} className={'bo'}
                                data-key="valid_to"
                                value={templateProperties.valid_to}
                                onChange={() => {
                                    const from_field = document.querySelector('input[name="valid_from"]')
                                    const from_date = new Date(from_field.value)
                                    const to_field = document.querySelector('input[name="valid_to"]')
                                    let to_date = new Date(to_field.value)
                                    if (!(isNaN(from_date.valueOf()) || isNaN(to_date.valueOf()))) {
                                        if (to_date < from_date) {
                                            from_field.value = to_field.value
                                        }
                                    }
                                    set_template_properties('valid_from', from_field.value)
                                    set_template_properties('valid_to', to_field.value)
                                }}
                                // onChange={ev => {props.setValidityChanged(true)}}
                            />
                            {// (validityRange.from || validityRange.to)
                                //     ? <div className={'validity'}>
                                //         {validityRange.from ? _format_date(validityRange.from) : 'n.a.'}
                                //         <div className={'to'}>&nbsp;-&nbsp;</div>
                                //         {validityRange.to ? _format_date(validityRange.to) : 'n.a.'}
                                //     </div>
                                //     : <div>keine Angaben</div>
                            }
                        </div>
                        <div className={'status'}>
                            <label className={'title'}>Status</label>
                            <select name={'status'} value={props.miniPool ? 1 : templateProperties?.status}
                                    disabled={props.miniPool}
                                    onChange={ev => {
                                        const new_val = ev.currentTarget.value
                                        set_template_properties('status', new_val)
                                    }}
                            >
                                <option value={0}>in Arbeit</option>
                                <option value={1}>fertig</option>
                            </select>
                        </div>
                        <div className={'tags_holder'}>
                            <label className={'title'}>Tags</label>
                            <Tags {...props} tags={templateProperties?.tags} callback={tag_callback}/>
                        </div>
                        <div className={'save_holder'}>
                            {
                                !(props.addContent.content_id || contentUid) &&
                                <div
                                    className={'save_template bo'} onClick={() => save_data()}
                                    style={{opacity: saveLocked ? .5 : '', pointerEvents: saveLocked ? 'none' : ''}}
                                >
                                    Speichern
                                </div>
                            }
                        </div>
                    </div>
                </div>}
            </div>}
        </div>
    )
}

function SpecialEditor(props) {
    const max_title_len = 15
    const g_state = useContext(AppContext)
    const special_url = `${window.settings.server_protocol}://${window.settings.server_hostname}:${window.settings.server_port}/special-content/`
    const appRef = useRef()
    const imageRef = useRef()

    const [app, setApp] = useState(null)
    const [pre, setPre] = useState(null)
    const [title, setTitle] = useState('')

    const [uidEntry, setUidEntry] = useState(props.uid)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (props.uid === true) {
            setLoaded(true)
            setUidEntry(-1)
        } else {
            setUidEntry(props.uid)
            const xml = new XMLHttpRequest()
            xml.addEventListener('load', ev => {
                const my_json = JSON.parse(ev.target.response)
                setTitle(my_json.title)
                setApp(my_json.appfile.split('/').slice(-1))
                if (my_json.preview) {
                    setPre(my_json.preview.split('/').slice(-1))
                }
                setLoaded(true)
            })

            const fd = new FormData()
            fd.append('user', g_state.connector.username)
            fd.append('passwd', g_state.connector.password)
            fd.append('uid', uidEntry)

            xml.open('POST', `${special_url}1/`)
            xml.send(fd)
        }

    }, [])

    function save_data() {
        setLoaded(false)

        const xml = new XMLHttpRequest()

        xml.addEventListener('load', ev => {
            const my_json = JSON.parse(ev.target.response)
            setUidEntry(p => my_json.uid)
            props.refresh_data(['schedule', 'dayprogram'], 'ContentEditor')
            setLoaded(true)
        })

        const fd = new FormData()
        fd.append('appfile', appRef.current.files[0])
        if (pre) {
            fd.append('preview', imageRef.current.files[0])
        }
        fd.append('user', g_state.connector.username)
        fd.append('passwd', g_state.connector.password)
        fd.append('title', title)
        fd.append('uid', uidEntry)

        const my_url = `${special_url}${uidEntry === -1 ? '' : '2/'}`
        xml.open('POST', my_url)
        xml.send(fd)
    }

    return (<div className={'SpecialEditor'}>
        <div className={'head fr'}>
            <div className={'title'}>
                Spezialinhalt
            </div>
            <div style={{display: 'flex'}}>
                <Help/>
                <div className={'closer'} onClick={() => props.setAddSpecialContent(null)}/>
            </div>
        </div>
        <div className={'sp_form'}>
            {loaded ? <div className={'upload_form'}>
                <div className={'title_head big'}>
                    {uidEntry ? 'Spezialinhalt ändern' : 'Spezialinhalt hochladen'}
                </div>
                <div className={'title'}>Titel</div>
                <div>
                    <input
                        id={'name'} type={'text'} onChange={ev => setTitle(ev.currentTarget.value)}
                        placeholder={'Titel Spezialinhalt'} defaultValue={title}
                    />
                </div>
                <div className={'file-actions part_1 fr'}>
                    <div className={'file-left fc'}>
                        <input ref={appRef} type="file" id="uploadFile" style={{display: 'none'}}
                               onChange={ev => {
                                   // localContent "undefined" means listcontrol
                                   if (ev.target.files.length === 1) {
                                       const file = ev.target.files[0]
                                       setApp(file.name)
                                   }
                               }}
                        />
                        <div onClick={() => appRef.current.click()} className={'file-button bo fc'}>
                            Applikation auswählen
                        </div>
                    </div>
                    <div className={'file-middle title'}>oder</div>
                    <div
                        onDragOver={ev => ev.preventDefault()}
                        onDragEnd={ev => ev.preventDefault()}
                        onDrop={ev => {
                            ev.preventDefault()

                            if (ev.dataTransfer.files.length === 1) {
                                const file = ev.dataTransfer.files[0]
                                const dT = new DataTransfer()
                                dT.items.add(file)
                                setApp(file.name)
                                appRef.current.files = dT.files
                            }
                        }}
                        className={'file-right app bo fc'}
                    >
                        Applikation hier hinziehen
                    </div>
                    <div className={'app_ref'}>
                        {app && app.length > max_title_len ? `${app.slice(0, max_title_len - 4)}(...).${app.split('.').slice(-1)}` : app}
                        {app && <div className={'delete'} onClick={() => {
                            appRef.current.files = new DataTransfer().files
                            setApp(null)
                        }}/>}
                    </div>
                </div>
                <div className={'file-actions part_2 fr'}>
                    <div className={'file-left fc'}>
                        <input ref={imageRef} type="file" id="uploadFile2" style={{display: 'none'}}
                               onChange={ev => {
                                   // localContent "undefined" means listcontrol
                                   if (ev.target.files.length === 1) {
                                       const file = ev.target.files[0]
                                       setPre(file.name)
                                   }
                               }}
                        />
                        <div onClick={() => imageRef.current.click()} className={'file-button bo fc'}>
                            Vorschaubild auswählen
                        </div>
                    </div>
                    <div className={'file-middle title'}>oder</div>
                    <div
                        onDragOver={ev => ev.preventDefault()}
                        onDragEnd={ev => ev.preventDefault()}
                        onDrop={ev => {
                            ev.preventDefault()

                            if (ev.dataTransfer.files.length === 1) {
                                const file = ev.dataTransfer.files[0]
                                const dT = new DataTransfer()
                                dT.items.add(file)
                                setPre(file.name)
                                imageRef.current.files = dT.files
                            }
                        }}
                        className={'file-right pre bo fc'}
                    >
                        Vorschaubild hier hinziehen
                    </div>
                    <div className={'image_ref'}>
                        {pre && <>
                            {pre.length > max_title_len ? `${pre.slice(0, max_title_len - 4)}(...).${pre.split('.').slice(-1)}` : pre}
                            <div className={'delete'} onClick={() => {
                                imageRef.current.files = new DataTransfer().files
                                setPre(null)
                            }}/>
                        </>}
                    </div>
                </div>

                <div
                    className={'save_change'} style={{
                    pointerEvents: (title && title.length > 5 && app) ? '' : 'none',
                    opacity: (title && title.length > 5 && app) ? 1 : .5,
                }} onClick={() => save_data()}
                >
                    {uidEntry === -1 ? 'Anlegen' : 'Ändern'}
                </div>
            </div> : <div className={'upload_form loading'}>
                Loading Content
            </div>

            }
        </div>
    </div>)
}

export default ContentEditor
export {PdfSelector, SpecialEditor}

// TODO write documentation on building templates
// TODO packed content element

// every template field must have an parts entry
// every template section must have a class
// the class should not contain numbers!!
