function editItemFromListcontrol(connector, data = '""') {
    return new Promise(
        (resolve) => {
            connector.run_action(
                {
                    action: 'editItemFromListcontrol',
                    class: '',
                    data: data,
                },
                res => resolve(res)
            )
        }
    )
}

export {editItemFromListcontrol}