import './list_control.scss'
import {useRef, useState} from "react";


function add_selector(props, level, title) {
    return (props.filters.length > 1 && level === 0)
        ? <select
            onChange={ev => props.handle_filter(ev.currentTarget.value)}
            defaultValue={props.selected_filter}
        >
            {
                props.filters.map(
                    (el, i) =>
                        <option
                            key={i}
                            value={el[0]}
                        >
                            {el[0]}
                        </option>
                )
            }
        </select>
        : title
}

function ListControl(props) {
    const [drag, setDrag] = useState(null)
    const listControl = useRef(null)

    function handle_drag(ev) {
        const is_mouse = ev.type.toLowerCase().indexOf('mouse') >= 0;
        const my_event = {
            type: ev.type,
            target: ev.target,
            x: is_mouse ? ev.clientX : (ev.touches[0] ? ev.touches[0].clientX : 0),
            y: is_mouse ? ev.clientY : (ev.touches[0] ? ev.touches[0].clientY : 0),
        }
        switch (my_event.type) {
            case 'touchstart':
            case 'mousedown':
                if (my_event.target.dataset.db_id !== undefined) {
                    const act_drag = my_event.target.cloneNode(true)
                    setDrag({
                            t: my_event.target,
                            x: listControl.current.getBoundingClientRect().left,
                            y: listControl.current.getBoundingClientRect().top,
                            parent: parseInt(my_event.target.closest('[data-level]').dataset.level, 10),
                            drag: act_drag,
                        }
                    )
                    act_drag.style = `opacity:0; position:absolute; z-index: 10;pointer-events:none;`
                    listControl && listControl.current.appendChild(act_drag)
                }

                break
            case 'mousemove':
            case 'touchmove':
                if (drag) {
                    drag.drag.style.opacity = .7
                    drag.drag.style.left = `${my_event.x - drag.x}px`
                    drag.drag.style.top = `${my_event.y - drag.y}px`
                }
                break
            case 'touchend':
            case 'mouseup':
            case 'mouseleave':
                if (drag) {
                    const my_target = is_mouse
                        ? ev.target
                        : document.elementFromPoint(ev.changedTouches[0].clientX, ev.changedTouches[0].clientY)

                    const parent_node = my_target.closest('[data-db_id]')

                    if (parent_node) {
                        if (drag.drag.dataset.level - parent_node.dataset.level === 1) {
                            props.handle_item(drag.drag.dataset.node, 'move', {new_node: parent_node.dataset.node})
                        }
                    }
                    listControl.current.removeChild(drag.drag)
                    setDrag(false)
                }
                break
            default:
                break
        }
    }

    const tree = Object.keys(props.tree).map(el => props.tree[el])

    return (
        <div
            className={`ListControl col-${Object.keys(props.panes).length}`} ref={listControl}
            onLoad={handle_drag}
            onMouseMove={handle_drag} onMouseUp={handle_drag} onMouseDown={handle_drag} onMouseLeave={handle_drag}
            onTouchStart={handle_drag} onTouchMove={handle_drag} onTouchEnd={handle_drag}
        >

            {
                props.panes.map(
                    (pane, level) => {
                        return (
                            <div className={'node'} key={level} data-level={level}>
                                <div className={'head_pane'}>
                                    <div className={'title'}>
                                        {
                                            add_selector(props, level, pane.title)
                                        }
                                    </div>
                                    {
                                        pane.mode.add &&
                                        <div
                                            className={`add_entry${
                                                level <= props.rootline.length ? '' : ' disabled'
                                            }`}
                                            onClick={
                                                () => {
                                                    props.handle_item(level, 'add')
                                                }
                                            }
                                        />
                                    }
                                </div>
                                {
                                    <div className={'body_pane'}>
                                        {
                                            tree
                                                .filter(el => el.level === level)
                                                .map(
                                                    el => {
                                                        const uid = `lv_${el.level}_${el.db_id}`
                                                        const is_active = props.rootline.indexOf(uid) >= 0
                                                        const is_selected = props.rootline.slice(-1).indexOf(uid) >= 0
                                                        return (
                                                            <div
                                                                className={
                                                                    `item${
                                                                        is_active ? ' active' : ''
                                                                    }${
                                                                        is_selected ? ' selected' : ''
                                                                    }`
                                                                }
                                                                data-db_id={el.db_id} key={el.id}
                                                                data-level={el.level} data-node={uid}
                                                                onClick={(ev) => {
                                                                    props.handle_item(el.id, 'select')
                                                                }}
                                                                dangerouslySetInnerHTML={{__html: el.name}}
                                                            />
                                                        )
                                                    }
                                                )
                                        }
                                    </div>
                                }
                            </div>
                        )
                    }
                )
            }
        </div>
    )
}

export default ListControl;

// NOTE ListControl does not support PDF !!